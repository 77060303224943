import { useUsersMeQuery } from '@/gql/systemApi'
import { useChatClient, ChannelOptions } from '@/context/ChatClientContext'
import {
  Channel,
  FormatMessageResponse,
  DefaultGenerics,
  ChannelFilters,
} from 'stream-chat'
import { useAttorneyNameAndOrgLazyQuery } from '@/gql/appApi'
import { AvatarProps } from '@/components/GetStreamChat/AvatarDisplay'
import { hashStringSHA256 } from '@/utils'
import { getImageUrlWithParams } from '@/utils/helpers'
import { useCallback, useMemo } from 'react'
import { useChatChannels } from './useChatChannels'

export type TChannelListItemData = {
  images: AvatarProps[]
  title: string
  unreadMessageCount: number
  lastMessage?: {
    text: string
    date: string
    seen: boolean
  }
  onClick: () => void
} | null

export const useStreamChat = (skipQuery = false) => {
  const { data: userMe } = useUsersMeQuery({
    skip: skipQuery,
  })
  const {
    client,
    messageDrawerOpen,
    setMessageDrawerOpen,
    selectedTab,
    setSelectedTab,
    activeChannelId,
    setActiveChannelId,
    totalUnreadMessages,
    connectUser,
    disconnectUser,
    expandedCases,
    setExpandedCases,
    currentCaseChatId,
    setCurrentCaseChatId,
    closeMessageDrawer,
    setUnreadDirectMessages,
    setUnreadCaseMessages,
    setTotalUnreadMessages,
  } = useChatClient()

  const [getAttorneyById] = useAttorneyNameAndOrgLazyQuery()

  const userId = userMe?.users_me?.id ?? ''

  /**
   * Create a new channel with the provided data
   * @param channelName - The name of the channel to create
   * @param channelType - The type of channel to create
   * @param members - The members to add to the channel
   * @param metadata - Additional metadata to add to the channel
   * @returns - The created channel
   */
  const createChannel = async ({
    channelName,
    channelType,
    members,
    metadata,
  }: {
    channelName: string
    channelType: string
    members: string[]
    metadata?: { [key: string]: any }
  }) => {
    try {
      if (!client) return
      const deduplicatedMemberList = [...new Set([userId, ...members])]

      const channel = client.channel('messaging', channelName, {
        members: deduplicatedMemberList,
        channel_type: channelType,
        ...metadata,
      })
      await channel.create()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating channel:', {
        channelName,
        channelType,
        members,
        metadata,
        error,
      })
    }
  }

  /**
   * Create a new direct channel with the provided members
   * @param members - The members to add to the channel
   * @returns - The created channel name if successful
   */
  const createDirectChannel = async (members: string[]) => {
    if (!client) return null
    const channelName = [userId, ...members].sort().join('_')
    const hashedChannelName = String(await hashStringSHA256(channelName))
    const channelType = 'direct'
    await createChannel({
      channelName: hashedChannelName,
      channelType,
      members,
    })
    return hashedChannelName
  }

  /**
   * Create a new case channel with the provided case ID and members
   * @param caseId - The ID of the case to create the channel for
   * @param members - The members to add to the channel
   * @returns - The created channel
   */
  const createCaseChannel = async ({
    caseId,
    members,
    caseTitle = '',
  }: {
    caseId: string
    members: string[]
    caseTitle?: string
  }) => {
    if (!client) return null
    const channelName = `case_${caseId}_${[[userId, ...members]].join('_')}`
    const hashedChannelName = String(await hashStringSHA256(channelName))
    const channelType = 'case'
    await createChannel({
      channelName: hashedChannelName,
      channelType,
      members,
      metadata: {
        case_id: caseId,
        caseTitle,
        // case_owner: caseOwnerUserId,
        // TODO: add more custom data for caseTitle, caseStatus, etc.
      },
    })
    return hashedChannelName
  }

  /**
   * Get the channel for the provided case ID if it exists
   * @param caseId - The ID of the case to get the channel for
   * @returns - The channel for the provided case ID
   */
  const getCaseChannelByCaseId = async (caseId: string) => {
    if (!client) return null
    const channels = await client.queryChannels({
      case_id: caseId,
      channel_type: ChannelOptions.CaseMessages,
      members: { $in: [userId] },
      case_purchase_id: { $exists: true },
    })
    return channels?.[0] ?? null
  }

  const directChannelFilters: ChannelFilters = useMemo(
    () => ({
      type: 'messaging',
      channel_type: ChannelOptions.DirectMessages,
    }),
    []
  )

  const caseChannelFilters: ChannelFilters = useMemo(
    () => ({
      type: 'messaging',
      channel_type: ChannelOptions.CaseMessages,
    }),
    []
  )

  const {
    getChannels: { refetch: getDirectChannels },
  } = useChatChannels({
    client,
    userId,
    channelFilters: directChannelFilters,
  })

  const {
    getChannels: { refetch: getCaseChannels },
  } = useChatChannels({
    client,
    userId,
    channelFilters: caseChannelFilters,
  })

  /**
   * Get all direct channels for the current user
   * @returns - List of direct channels
   */
  const getMyDirectChannels = useCallback(async () => {
    if (!client) return []

    const { data: { channels } = { channels: [] } } = await getDirectChannels()

    return channels
  }, [client, getDirectChannels])

  /**
   * Get all case channels for the current user
   * @returns - List of case channels
   */
  const getMyCaseChannels = useCallback(async () => {
    if (!client) return []

    const { data: { channels } = { channels: [] } } = await getCaseChannels()

    return channels
  }, [client, getCaseChannels])

  /**
   * Get the title for a direct channel
   * @param channel - The direct channel to get the title for
   * @returns - The title for the direct channel
   */
  const getDirectChannelTitle = (channel: Channel) => {
    const { members } = channel.state
    const memberIds = Object.keys(members)
    const otherUser: string = memberIds.find(id => id !== userId) ?? ''
    return members[otherUser]?.user?.name ?? 'Unknown'
  }

  /**
   * Get the title for a case channel
   * @param channel - The case channel to get the title for
   * @returns - The title for the case channel
   */
  const getCaseChannelTitle = (channel: Channel) => {
    const { members } = channel.state
    const otherMembers = Object.keys(members).filter(id => id !== userId)
    const otherMember = members[otherMembers[0]]?.user
    return (
      `${otherMember?.name ?? 'Unknown User'} - ${
        channel.data?.caseTitle ?? 'Unknown Case'
      }` ?? 'Unknown'
    )
  }

  /**
   * Get the title for a channel
   * @param channel - The channel to get the title for
   * @returns - The title for the channel
   */
  const getChannelTitle = (channel: Channel) => {
    const channelType = channel.data?.channel_type
    switch (channelType) {
      case ChannelOptions.DirectMessages:
        return getDirectChannelTitle(channel)
      case ChannelOptions.CaseMessages:
        return getCaseChannelTitle(channel)
      default:
        return 'Unknown'
    }
  }

  const getLastMessageText = (
    lastMessage: FormatMessageResponse<DefaultGenerics>
  ) => {
    let lastMessageText = ''

    const hasAttachments =
      lastMessage.attachments && lastMessage.attachments.length > 0
    const isSentByCurrentUser = lastMessage?.user?.id === userId

    if (hasAttachments) {
      const lastAttachment =
        lastMessage.attachments?.[lastMessage.attachments.length - 1]
      lastMessageText = lastAttachment?.fallback ?? lastAttachment?.title ?? ''
    } else {
      lastMessageText = lastMessage?.text ?? ''
    }

    if (isSentByCurrentUser) {
      lastMessageText = `You: ${lastMessageText}`
    }

    return lastMessageText
  }

  /**
   * Get the other members of a channel
   * @param channel - The channel to get the other members for
   * @returns - The other members of the channel
   */
  const getOtherMembers = (channel: Channel) => {
    const { members } = channel.state
    return Object.keys(members).filter(id => members[id]?.user_id !== userId)
  }

  /**
   * Get the avatar images for a channel and channel type
   * @param channel - The channel to get the avatar images for
   * @returns - The avatar images for the channel
   */
  const getChannelAvatar = (channel: Channel): AvatarProps[] => {
    const { members } = channel.state
    const otherMembers = getOtherMembers(channel)

    const imageParams = { width: 56, height: 56, fit: 'cover' }

    switch (channel.data?.channel_type) {
      case ChannelOptions.DirectMessages:
        return otherMembers.map(id => ({
          src: members[id]?.user?.image
            ? getImageUrlWithParams({
                url: String(members[id]?.user?.image),
                params: imageParams,
              })
            : '',
          alt: members[id]?.user?.name ?? '',
        }))
      case ChannelOptions.CaseMessages: {
        const currentMember = members[userId]
        const images: AvatarProps[] = otherMembers.map(id => ({
          src: members[id]?.user?.image
            ? getImageUrlWithParams({
                url: String(members[id]?.user?.image),
                params: imageParams,
              })
            : '',
          alt: members[id]?.user?.name ?? '',
        }))
        if (currentMember?.user?.image)
          images.push({
            src: getImageUrlWithParams({
              url: String(currentMember?.user?.image),
              params: imageParams,
            }),
            alt: currentMember?.user?.name ?? '',
          })
        return images
      }
      default:
        return []
    }
  }

  /**
   * Get the list item data for a direct channel
   * @param channel - The direct channel to get the list item data for
   * @returns - The list item data for the direct channel
   */
  const getDirectChannelListItemData: (
    channel: Channel
  ) => TChannelListItemData = (channel: Channel) => {
    const title: string = getChannelTitle(channel)
    const images = getChannelAvatar(channel)
    const unreadMessageCount: number = channel.countUnread()

    const messages = channel.state.messageSets?.[0]?.messages ?? []
    if (!messages.length) return null
    const lastMessage = messages[messages.length - 1]
    const lastMessageText = getLastMessageText(lastMessage)
    const lastMessageId = lastMessage?.id

    const otherMembers = getOtherMembers(channel)
    const otherMemberLatestReadMessageId =
      channel.state.read[otherMembers[0]]?.last_read_message_id
    const lastMessageSeen = lastMessageId === otherMemberLatestReadMessageId

    const lastMessageData = {
      text: lastMessageText ?? '',
      date: String(new Date(lastMessage?.updated_at ?? '')),
      seen: lastMessageSeen,
    }

    return {
      images,
      title,
      unreadMessageCount,
      ...(lastMessage ? { lastMessage: lastMessageData } : {}),
      onClick: () => {
        if (unreadMessageCount > 0) {
          setUnreadDirectMessages(prev => prev - unreadMessageCount)
          setTotalUnreadMessages(prev => prev - unreadMessageCount)
        }
        setActiveChannelId(channel?.id ?? null)
      },
    }
  }

  /**
   *
   * Check if a channel is archived
   * @param channel
   * @returns - Whether the channel is archived
   */
  const isChannelArchived = (channel: Channel) => {
    const channelArchiveDate = channel?.data?.archiveDate
      ? new Date(String(channel.data.archiveDate))
      : null
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const isChannelArchived =
      channelArchiveDate && channelArchiveDate < new Date()
    return !!isChannelArchived
  }

  /**
   * Get the list item data for a case channel
   * @param channel - The case channel to get the list item data for
   * @returns - The list item data for the case channel
   */
  const getCaseChannelListItemData: (
    channel: Channel
  ) => TChannelListItemData = (channel: Channel) => {
    const title: string = getChannelTitle(channel)

    const images = getChannelAvatar(channel)

    const unreadMessageCount: number = channel.state?.unreadCount ?? 0
    const messages = channel.state.messageSets?.[0]?.messages
    if (!messages?.length) return null
    const lastMessage = messages[messages.length - 1]
    const lastMessageText = getLastMessageText(lastMessage)
    const lastMessageId = lastMessage?.id

    const otherMembers = getOtherMembers(channel)
    const otherMemberLatestReadMessageId =
      channel.state.read[otherMembers[0]]?.last_read_message_id
    const lastMessageSeen = lastMessageId === otherMemberLatestReadMessageId

    const lastMessageData = {
      text: lastMessageText ?? '',
      date: String(new Date(lastMessage?.updated_at ?? '')),
      seen: lastMessageSeen,
    }

    return {
      images,
      title,
      unreadMessageCount,
      ...(lastMessage ? { lastMessage: lastMessageData } : {}),
      onClick: () => {
        if (unreadMessageCount > 0 && !isChannelArchived(channel)) {
          setUnreadCaseMessages(prev => prev - unreadMessageCount)
          setTotalUnreadMessages(prev => prev - unreadMessageCount)
        }
        setActiveChannelId(channel?.id ?? null)
      },
    }
  }

  /**
   * Get the list item data for a channel
   * @param channel - The channel to get the list item data for
   * @returns - The list item data for the channel
   */
  const getChannelListItemData: (channel: Channel) => TChannelListItemData = (
    channel: Channel
  ) => {
    const channelType = channel.data?.channel_type

    switch (channelType) {
      case ChannelOptions.DirectMessages:
        return getDirectChannelListItemData(channel)
      case ChannelOptions.CaseMessages:
        return getCaseChannelListItemData(channel)
      default:
        return null
    }
  }

  /**
   * Get a channel by its ID
   * @param channelId - The ID of the channel to get
   * @returns - The channel with the provided ID
   */
  const getChannelById = useCallback(
    async (channelId: string) => {
      if (!client) return null
      const channels = await client.queryChannels({
        id: channelId,
        members: { $in: [userId] },
      })
      return channels?.[0] ?? null
    },
    [client, userId]
  )

  const getAttorneyIdFromUserId = async ({ id }: { id: string }) => {
    if (!userId) return null
    const response = await getAttorneyById({
      variables: {
        filter: {
          _and: [
            {
              attorney_id: {
                user_id: {
                  id: {
                    _eq: id,
                  },
                },
              },
            },
          ],
        },
      },
    })
    const attorneyId = response?.data?.attorney_profile?.[0]?.attorney_id?.id
    return attorneyId
  }

  const getHasMessagesByCaseId = useCallback(
    async (caseId: string) => {
      if (!client) return false
      try {
        const channels = await client.queryChannels({
          case_id: caseId,
          members: { $in: [userId] },
        })
        return channels
          .filter(channel => !channel.data?.archived)
          .some(channel => channel.state?.messages?.length)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching messages:', error)
        return false
      }
    },
    [client, userId]
  )

  const getUnreadMessagesByCaseId = useCallback(
    async (caseId: string) => {
      if (!client) return 0
      try {
        const channels = await client.queryChannels({
          case_id: caseId,
          members: { $in: [userId] },
        })

        return channels
          .filter(channel => !isChannelArchived(channel))
          .reduce((acc, channel) => acc + channel.state.unreadCount, 0)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching unread messages:', error)
        return 0
      }
    },
    [client, userId]
  )

  const getChannelsByCaseId = useCallback(
    async (caseId: string) => {
      if (!client) return []

      const channels = await client.queryChannels({
        case_id: caseId,
        channel_type: ChannelOptions.CaseMessages,
        members: { $in: [userId] },
      })

      return channels
    },
    [client, userId]
  )

  return {
    client,
    userId,
    totalUnreadMessages,
    messageDrawerOpen,
    setMessageDrawerOpen,
    createChannel,
    createDirectChannel,
    createCaseChannel,
    getCaseChannelByCaseId,
    getMyDirectChannels,
    getMyCaseChannels,
    getChannelListItemData,
    selectedTab,
    setSelectedTab,
    activeChannelId,
    setActiveChannelId,
    getChannelById,
    getChannelTitle,
    getOtherMembers,
    getChannelAvatar,
    getAttorneyIdFromUserId,
    connectUser,
    disconnectUser,
    expandedCases,
    setExpandedCases,
    getHasMessagesByCaseId,
    getUnreadMessagesByCaseId,
    currentCaseChatId,
    setCurrentCaseChatId,
    closeMessageDrawer,
    isChannelArchived,
    getChannelsByCaseId,
  }
}
