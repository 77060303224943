export enum FeatureFlag {
  MARKETPLACE_LAWMATICS = 'marketplace_lawmatics_integration_enabled',
  MARKETPLACE_LITIFY = 'marketplace_litify_integration_enabled',
  NOTIFICATION_BANNER = 'marketplace_notification_banner_enabled',
  MARKETPLACE_CASE_PAGE_V2 = 'marketplace_case_page_v2_enabled',
  MY_CASE_INTEGRATION = 'marketplace_mycase_integration_enabled',
  MARKETPLACE_DASHBOARD_V2 = 'marketplace_dashboard_v2_enabled',
  MARKETPLACE_ORG_PAYMENTS = 'marketplace_org_payments_enabled',
  MARKETPLACE_DASHBOARD_V2_ATTORNEY_PERFORMANCE = 'marketplace_dashboard_v2_attorney_performance_enabled',
}
