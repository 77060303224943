import { JSnackBarProps } from '@/components/atoms/JSnackBar/types'
import { SnackbarContext } from '@/context/SnackbarContext'
import { useContext, useMemo } from 'react'

export type TAlertProps = Omit<JSnackBarProps, 'handleClose'>

export const useSnackBar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  const { showSnackbar } = context

  const showAlert = useMemo(
    () => (data: Omit<TAlertProps, 'open'>) => {
      showSnackbar(data.message, data.severity)
    },
    [showSnackbar]
  )

  return {
    showAlert,
  }
}
