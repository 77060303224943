import { ErrorWithFriendlyMessage } from '@/pages/Error/errors'
import { MemoryStorage } from './MemoryStorage'

// NOTE: sessionStorageService (instantiated at the bottom of this file) is the
// ONLY way that localStorage should be accessed. This will prevent user
// session contamination across tabs.

export enum SessionKey {
  ATTORNEY_EMAIL_KEY = 'email',
  ATTORNEY_ID_KEY = 'attorney-id',
  ATTORNEY_PROFILE_ID_KEY = 'profile-id',
  CHAT_KEY = 'chat-key',
  IS_QUALIFIED_ATTORNEY_KEY = 'is_qualified_attorney',
  IS_VETTED_ATTORNEY_KEY = 'is_vetted_attorney',
  PROFILE_CONSISTENCY_TOKEN = '__consistencyToken',
  REFRESH_TOKEN = 'refresh_token',
  TOKEN = 'token',
  /**
   * Refers to the "src" query parameter value that the current session booted
   * with. This is typically provided from an LCMS integration.
   */
  SESSION_SRC = 'session_src',
  LCMS_CASE_ID = 'lcms_case_id',
}

export class SessionStorageService {
  private storage: Storage

  private verifySessionConsistency = () => {
    const storedEmail = this.storage.getItem(SessionKey.ATTORNEY_EMAIL_KEY)
    const { loggedInEmail } = this

    if (loggedInEmail === null || storedEmail === null) {
      return
    }

    if (storedEmail !== loggedInEmail) {
      throw new ErrorWithFriendlyMessage(
        `Logged in email mismatch: ${JSON.stringify({
          storedEmail,
          loggedInEmail,
        })}`,
        'It looks like you are logged in as another user. Please reload the page.'
      )
    }
  }

  private loggedInEmail: string | null

  constructor() {
    try {
      // NOTE: This will expectedly throw an error in environments where
      // localStorage is not available.
      const testKey = `__storage-test`

      // eslint-disable-next-line storage/no-localstorage
      window.localStorage.setItem(testKey, '')
      // eslint-disable-next-line storage/no-localstorage
      window.localStorage.removeItem(testKey)

      // eslint-disable-next-line storage/no-localstorage
      this.storage = window.localStorage
    } catch (e) {
      this.storage = new MemoryStorage()
    }

    this.loggedInEmail = this.storage.getItem(SessionKey.ATTORNEY_EMAIL_KEY)
  }

  getItem = (key: SessionKey) => {
    this.verifySessionConsistency()

    return this.storage.getItem(key)
  }

  setItem = (key: SessionKey, value: string) => {
    if (key === SessionKey.ATTORNEY_EMAIL_KEY) {
      this.loggedInEmail = value
      this.storage.setItem(key, value)

      return
    }

    this.verifySessionConsistency()

    this.storage.setItem(key, value)
  }

  removeItem = (key: SessionKey) => {
    this.verifySessionConsistency()

    this.storage.removeItem(key)
  }

  clear = () => {
    this.verifySessionConsistency()

    this.storage.clear()
  }
}

export const sessionStorageService = new SessionStorageService()
