import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react'
import { AlertColor } from '@mui/material'
import JSnackBar from '../components/atoms/JSnackBar'

interface SnackbarContextType {
  showSnackbar: (message: ReactNode, severity?: AlertColor) => void
}

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => {
    throw new Error('showSnackbar called outside of SnackbarProvider')
  },
})

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState<ReactNode>('')
  const [severity, setSeverity] = useState<AlertColor>('success')

  const showSnackbar = useCallback<SnackbarContextType['showSnackbar']>(
    (newMessage, newSeverity = 'success') => {
      setMessage(newMessage)
      setSeverity(newSeverity)
      setOpen(true)
    },
    []
  )

  const hideSnackbar = useCallback(() => {
    setOpen(false)
  }, [])

  const value = useMemo(
    () => ({
      showSnackbar,
    }),
    [showSnackbar]
  )

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <JSnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={hideSnackbar}
      />
    </SnackbarContext.Provider>
  )
}
