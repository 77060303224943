import {
  Channel,
  ChannelFilters,
  DefaultGenerics,
  StreamChat,
} from 'stream-chat'
import { useRef, useState } from 'react'
import { useQuery } from 'react-query'

export const useChatChannels = ({
  client,
  userId,
  channelFilters,
  limit = 30,
}: {
  client: StreamChat<DefaultGenerics> | null
  userId: string
  channelFilters: ChannelFilters
  limit?: number
}) => {
  // NOTE: These need to be refs because updating them must not cause a rerender.
  const offset = useRef(0)
  const channels = useRef<Channel[]>([])

  const [hasMoreChannels, setHasMoreChannels] = useState(true)

  /**
   * Get active channels based on the provided filters for the current user
   * @param channelFilters - Filters to apply to the query
   * @returns - List of channels
   */
  const getChannels = useQuery(
    ['chat-channels', channelFilters, userId],
    async () => {
      if (client && hasMoreChannels) {
        try {
          const newChannels = await client.queryChannels(
            {
              ...channelFilters,
              members: { $in: [userId] },
              last_message_at: { $exists: true },
            },
            {
              last_message_at: -1,
            },
            {
              limit,
              offset: offset.current,
            }
          )

          if (newChannels.length !== limit) {
            setHasMoreChannels(false)
          }

          offset.current += newChannels.length
          channels.current = [...channels.current, ...newChannels]
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching channels:', error)
          throw error
        }
      }

      return { channels: channels.current, hasMoreChannels }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  return { getChannels }
}
