import { createTheme } from '@mui/material'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import { hexColorWithTransparency } from './utils'
import BrownRegular from '../fonts/Brown/Brown-Regular.ttf'
import BrownBold from '../fonts/Brown/Brown-Bold.ttf'

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true
  }
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    main?: React.CSSProperties['color']
    light?: React.CSSProperties['color']
    page?: React.CSSProperties['color']
    badge?: React.CSSProperties['color']
  }
  interface CustomPaletteColorOptions {
    main?: React.CSSProperties['color']
    light?: React.CSSProperties['color']
    lighter?: React.CSSProperties['color']
    dark?: React.CSSProperties['color']
    darkest?: React.CSSProperties['color']
    hover?: React.CSSProperties['color']
    pressed?: React.CSSProperties['color']
    selected?: React.CSSProperties['color']
    contrastText?: React.CSSProperties['color']
  }

  interface PaletteColor {
    lighter?: string
    lightest?: string
    darkest?: string
    hover?: string
    pressed?: string
    selected?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    lightest?: string
  }

  interface Palette {
    border: PaletteColor
  }

  interface PaletteOptions {
    border?: CustomPaletteColorOptions
  }

  interface TypographyVariants {
    captionBold: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    captionBold?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionBold: true
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    square: true
    rounded: true
    text: true
    dot: false
    standard: false
  }
}

export type FontTypes =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'overline'
  | 'button'

const jTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#002E69',
      light: '#bad0ed',
      lightest: '#E9EEF5',
      dark: '#002452',
    },
    secondary: {
      main: '#05DF9D',
      light: '#cff3e9',
      lightest: '#F4FBF9',
      lighter: '#DEF3ED',
      dark: '#04d47e',
      contrastText: '#002E69',
    },
    grey: {
      50: '#FAFAFA',
      100: '#E6E7EA',
      200: '#8E8F91',
    },
    error: {
      main: '#fd5050',
      light: '#FFE3E7',
      dark: '#620000',
    },
    warning: {
      main: '#ffcb11',
      light: '#fff2d0',
      dark: '#D78304',
      '600': '#5A3601',
    },
    info: {
      main: '#00b5ee',
      light: '#f9fdff',
      lightest: '#D0FCFF',
      dark: '#007a95',
    },
    success: {
      main: '#04b983',
      light: '#0DE1A2',
      lightest: '#CFFED7',
      dark: '#035D42',
    },
    background: {
      page: '#FAFCFF',
      badge: '#7FCECF',
    },
    border: {
      main: '#D9D9D9',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '3rem',
      fontStyle: 'normal',
      lineHeight: '3.5rem',
    },
    h2: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '2.5rem',
      fontStyle: 'normal',
      lineHeight: '3rem',
    },
    h3: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '2rem',
      fontStyle: 'normal',
      lineHeight: '2.25rem',
    },
    h4: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      lineHeight: '2rem',
    },
    h5: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '1.5rem',
      fontStyle: 'normal',
      lineHeight: '1.75rem',
    },
    h6: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '1.25rem',
      fontStyle: 'normal',
      lineHeight: '1.5rem',
    },
    subtitle1: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '1rem',
      fontStyle: 'normal',
      lineHeight: '1.5rem',
    },
    subtitle2: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      lineHeight: '1.25rem',
    },
    body1: {
      fontFamily: 'Brown-Regular, sans-serif',
      fontSize: '1rem',
      fontStyle: 'normal',
      lineHeight: '1.5rem',
    },
    body2: {
      fontFamily: 'Brown-Regular, sans-serif',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      lineHeight: '1.25rem',
    },
    caption: {
      fontFamily: 'Brown-Regular, sans-serif',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      lineHeight: '1rem',
    },
    captionBold: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      lineHeight: '1rem',
    },
    overline: {
      fontFamily: 'Brown-Regular, sans-serif',
      fontSize: '0.625rem',
      fontStyle: 'normal',
      lineHeight: 'normal',
      textTransform: 'uppercase',
    },
    button: {
      fontFamily: 'Brown-Bold, sans-serif',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      lineHeight: '1rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-display: swap;
          font-family: 'Brown-Bold';
          font-style: normal;
          font-weight: 700;
          src: local('Brown-Bold'), url(${BrownBold}) format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-display: swap;
          font-family: 'Brown-Regular';
          font-style: normal;
          font-weight: 400;
          src: local('Brown-Regular'), url(${BrownRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme, ownerState }) => {
          const badgeCommonStyles = {
            borderRadius: '0.25rem',
            fontSize: '0.75rem',
            fontFamily: 'Brown',
            height: '1.25rem',
            padding: '0 0.25rem',
          }
          const badgeOverrideStyles = {
            square: {
              background: theme.palette.secondary.main,
            },
            rounded: {
              borderRadius: '3.75rem',
              background: theme.palette.secondary.main,
            },
            text: {
              color: theme.palette.text.disabled,
            },
          }

          return {
            ...badgeCommonStyles,
            ...badgeOverrideStyles[ownerState.variant ?? 'square'],
          }
        },
      },
      variants: [
        {
          props: { variant: 'square' },
          style: {
            borderRadius: '0.25rem',
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          marginRight: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          ...(ownerState.size === 'small' && {
            padding:
              ownerState.variant === 'outlined'
                ? theme.spacing(0.625, 1.5)
                : theme.spacing(0.75, 1.5),
          }),
          ...(ownerState.size === 'medium' && {
            padding:
              ownerState.variant === 'outlined'
                ? theme.spacing(1.125, 1.5)
                : theme.spacing(1.25, 2),
          }),
          ...(ownerState.size === 'large' && {
            padding:
              ownerState.variant === 'outlined'
                ? theme.spacing(1.875, 2)
                : theme.spacing(2),
          }),
          ...(ownerState.size === 'xlarge' && {
            padding:
              ownerState.variant === 'outlined'
                ? theme.spacing(2.375, 1.5)
                : theme.spacing(2.5, 3.5),
          }),
          ...theme.typography.button,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          color: theme.palette[ownerState.color || 'primary'].main,
          letterSpacing: 0,
          borderRadius: '0.25rem',
          textTransform: 'none',
          ':disabled': {
            backgroundColor: hexColorWithTransparency(
              theme.palette.common.black,
              0.1
            ),
            border: 0,
            color: hexColorWithTransparency(theme.palette.common.black, 0.2),
          },
        }),
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.secondary.main,
            ':hover': {
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.6
              ),
            },
            ':focus': {
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.9
              ),
            },
          }),
        },
        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            backgroundColor: hexColorWithTransparency(
              theme.palette.secondary.main,
              0
            ),
            ':hover': {
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.35
              ),
            },
            ':focus': {
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.6
              ),
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            borderColor: theme.palette.secondary.main,
            boxSizing: 'border-box',
            ':hover': {
              borderColor: theme.palette.secondary.main,
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.35
              ),
            },
            ':focus': {
              borderColor: theme.palette.secondary.main,
              backgroundColor: hexColorWithTransparency(
                theme.palette.secondary.main,
                0.6
              ),
            },
          }),
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...theme.typography.body2,
          ...(ownerState.size === 'small' && {
            height: '1.75rem',
          }),
          ...(ownerState.size === 'medium' && {
            height: '2.25rem',
          }),
        }),
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: ({ theme }) => ({
            textTransform: 'none',
            ':hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: () => ({
            textTransform: 'none',
          }),
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: hexColorWithTransparency(
            theme.palette.common.black,
            0.15
          ),
        }),
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: hexColorWithTransparency(
              theme.palette.common.black,
              0.3
            ),
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.primary.main,
          },
          [`&.Mui-focused.Mui-error .${outlinedInputClasses.notchedOutline},
          &:hover.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.error.main,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: hexColorWithTransparency(theme.palette.common.black, 0.38),
          [`&.Mui-focused`]: {
            color: theme.palette.primary.main,
          },
          [`&.Mui-focused.Mui-error`]: {
            color: theme.palette.error.main,
          },
        }),
        shrink: ({ theme }) => ({
          color: hexColorWithTransparency(theme.palette.common.black, 0.6),
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          display: 'flex',
          alignItems: 'center',
          // success
          ...(ownerState.severity === 'success' &&
            ownerState.variant === 'filled' && {
              color: `${theme.palette.info.light}`,
            }),
          ...(ownerState.severity === 'success' &&
            ownerState.variant === 'outlined' && {
              border: `1px solid ${theme.palette.success.light}`,
            }),
          ...(ownerState.severity === 'success' &&
            ownerState.variant === 'standard' && {
              backgroundColor: theme.palette.success.lightest,
              color: theme.palette.success.dark,
            }),
          // error
          ...(ownerState.severity === 'error' &&
            ownerState.variant === 'standard' && {
              backgroundColor: `${theme.palette.error.light}`,
            }),
          ...(ownerState.severity === 'error' &&
            ownerState.variant === 'outlined' && {
              border: `1px solid ${theme.palette.error.main}`,
              color: `${theme.palette.error.main}`,
            }),
          // warning
          ...(ownerState.severity === 'warning' &&
            ownerState.variant === 'standard' && {
              backgroundColor: theme.palette.warning.light,
              color: theme.palette.warning.dark,
            }),
          ...(ownerState.severity === 'warning' &&
            ownerState.variant === 'outlined' && {
              border: `1px solid ${theme.palette.warning.main}`,
              color: `${theme.palette.warning.dark}`,
            }),
          // info
          ...(ownerState.severity === 'info' &&
            ownerState.variant === 'standard' && {
              backgroundColor: `${theme.palette.info.lightest}`,
            }),
          ...(ownerState.severity === 'info' &&
            ownerState.variant === 'outlined' && {
              border: `1px solid ${theme.palette.info.main}`,
              color: `${theme.palette.info.main}`,
            }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2.5),
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: ({ theme }) => ({
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
          '&.Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: () => ({
          marginLeft: 0,
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
        }),
      },
    },
  },
})

export { jTheme as theme }
