import { forwardRef } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import Check from '@mui/icons-material/Check'
import { AvatarDisplay, AvatarProps } from '../AvatarDisplay'

type ChannelListItemProps = {
  images: AvatarProps[]
  title: string
  unreadMessageCount: number
  lastMessage?: {
    text: string
    date: string
    seen: boolean
  }
  isArchived?: boolean
  onClick: () => void
}

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    lineHeight: 'normal',
    textAlign: 'center',
  },
}))

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}))

const ChannelListItem = forwardRef<HTMLLIElement | null, ChannelListItemProps>(
  (
    { images, title, unreadMessageCount, lastMessage, isArchived, onClick },
    ref
  ) => (
    <>
      <StyledListItem onClick={onClick} ref={ref}>
        <ListItemAvatar>
          <AvatarDisplay images={images} />
        </ListItemAvatar>
        <ListItemText
          sx={{ flex: 1, minWidth: 0 }}
          primary={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="subtitle1"
                noWrap
                color="text.primary"
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  minWidth: '0px',
                  minHeight: 24,
                }}
              >
                {title}
              </Typography>

              {unreadMessageCount > 0 && !isArchived && (
                <StyledBadge
                  badgeContent={unreadMessageCount}
                  color="error"
                  max={9}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  sx={{
                    marginLeft: 3,
                    transform: 'translateX(-0.5rem)',
                  }}
                />
              )}
            </Box>
          }
          secondary={
            <Box
              component="span"
              data-chromatic="ignore"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                component="span"
                variant="body2"
                color="text.muted"
                sx={{
                  flexGrow: 1,
                  mr: 1,
                  minWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {lastMessage?.text}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{
                  flexShrink: 0,
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: 20,
                }}
              >
                {lastMessage?.seen && (
                  <Check
                    sx={{
                      fontSize: 'inherit',
                      verticalAlign: 'middle',
                      marginRight: '2px',
                      color: 'success.main',
                    }}
                  />
                )}
                {lastMessage?.date &&
                  new Date(lastMessage.date).toLocaleTimeString([], {
                    timeStyle: 'short',
                  })}
              </Typography>
            </Box>
          }
        />
      </StyledListItem>
      <Divider />
    </>
  )
)

ChannelListItem.displayName = 'ChannelListItem'

export default ChannelListItem
