import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import GetStreamChatDrawer from './GetStreamChat/GetStreamChatDrawer'

const RootLayout = () => (
  <Box className="root_layout">
    <GetStreamChatDrawer />
    <Outlet />
  </Box>
)
export default RootLayout
