import { useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import CircularProgress from '@mui/material/CircularProgress'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Channel, DefaultGenerics } from 'stream-chat'
import { TChannelListItemData, useStreamChat } from '@/hooks/useStreamChat'
import ChannelListItem from '../ChannelListItem'

const ARCHIVED_GROUP_ID = 'Archived'

const groupChannelsByCaseId = (
  channels: Channel<DefaultGenerics>[],
  isChannelArchived: (channel: Channel<DefaultGenerics>) => boolean
) => {
  const groupedChannels = channels.reduce<
    Record<string, Channel<DefaultGenerics>[]>
  >((acc, channel) => {
    const caseId =
      typeof channel?.data?.case_id === 'string'
        ? channel.data.case_id
        : undefined
    const groupKey = isChannelArchived(channel) ? ARCHIVED_GROUP_ID : caseId

    if (groupKey) {
      if (!acc[groupKey]) {
        acc[groupKey] = []
      }
      acc[groupKey].push(channel)
    }
    return acc
  }, {})

  const archivedChannels = groupedChannels[ARCHIVED_GROUP_ID]
  delete groupedChannels[ARCHIVED_GROUP_ID]
  if (archivedChannels) groupedChannels[ARCHIVED_GROUP_ID] = archivedChannels

  return groupedChannels
}

interface CaseChannelListProps {
  channels: Channel<DefaultGenerics>[]
  loading: boolean
  getChannelListItemData: (
    channel: Channel<DefaultGenerics>
  ) => TChannelListItemData
  onExpandedChange?: (expanded: Set<string>) => void
  lastChannelElementRef: (node: HTMLDivElement | null) => void
}

const CaseChannelList: React.FC<CaseChannelListProps> = ({
  channels,
  loading,
  getChannelListItemData,
  lastChannelElementRef,
}: CaseChannelListProps) => {
  const {
    expandedCases,
    setExpandedCases,
    currentCaseChatId,
    isChannelArchived,
  } = useStreamChat()
  const groupedChannels = groupChannelsByCaseId(channels, isChannelArchived)

  useEffect(() => {
    // Only set expanded state for new channels that aren't already in expandedCases
    const newExpandedChannels = Object.keys(groupedChannels).reduce(
      (acc: Record<string, boolean>, groupedChannelKey) => {
        if (expandedCases[groupedChannelKey] === undefined) {
          acc[groupedChannelKey] = true
        }
        return acc
      },
      {}
    )

    if (Object.keys(newExpandedChannels).length > 0) {
      setExpandedCases(prev => ({ ...prev, ...newExpandedChannels }))
    }
  }, [groupedChannels, setExpandedCases, expandedCases])

  const handleChange = useCallback(
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      if (currentCaseChatId === panel) return

      setExpandedCases(prev => ({ ...prev, [panel]: isExpanded }))
    },
    [setExpandedCases, currentCaseChatId]
  )

  const renderedGroupedChannels = currentCaseChatId
    ? { [currentCaseChatId]: groupedChannels[currentCaseChatId] }
    : groupedChannels

  const renderedGroupedChannelKeys = Object.keys(renderedGroupedChannels)

  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, #f5f5f5, #ffffff)',
      }}
    >
      {renderedGroupedChannelKeys.map((caseId, idx) => {
        const isArchived = caseId === ARCHIVED_GROUP_ID
        const groupTitle = isArchived
          ? ARCHIVED_GROUP_ID
          : String(
              renderedGroupedChannels?.[caseId]?.[0]?.data?.caseTitle ?? caseId
            )
        return (
          <Accordion
            key={caseId}
            expanded={expandedCases[caseId] || currentCaseChatId === caseId}
            onChange={handleChange(caseId)}
            ref={
              idx === renderedGroupedChannelKeys.length - 1
                ? lastChannelElementRef
                : undefined
            }
            sx={{
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: '0',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                background: 'linear-gradient(to bottom, #fafafa, #ffffff)',
                '&.Mui-expanded': {
                  minHeight: '48px',
                },
                '.MuiAccordionSummary-content.Mui-expanded': {
                  margin: '18px 0',
                },
              }}
            >
              <Typography
                variant="subtitle2"
                color={theme => theme.palette.text.secondary}
              >
                {groupTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {renderedGroupedChannels[caseId]?.map(channel => {
                const channelItemData = getChannelListItemData(channel)
                if (!channelItemData) return null
                return (
                  <ChannelListItem
                    key={channel.id}
                    isArchived={isArchived}
                    {...channelItemData}
                  />
                )
              })}
            </AccordionDetails>
          </Accordion>
        )
      })}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            my: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default CaseChannelList
