import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Menu, { MenuProps } from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import JAvatar from '@/components/atoms/JAvatar'
import { Link } from 'react-router-dom'
import { useState, MouseEvent, useEffect } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded'
import Divider from '@mui/material/Divider'

import { ZENDESK_HELP_URL } from '@/constants'
import useProfile from '@/hooks/useProfile'
import { useProfilePicture } from '@/hooks/profile/useProfilePicture'
import { RoutesData } from '@/router/RoutesData'
import useAttorneyData from '@/hooks/useIsAttorneyVetted'

import { getEditPublicProfileFirstLink } from '@/pages/Profile/utils/constanst'
import {
  getAdminPanelFirstLink,
  getMenuItems,
} from '@/pages/AdminPanel/utils/constants'
import { getAccountSettingsFirstLink } from '@/pages/AccountSettings/utils/constants'
import { isQualifiedMembership } from '@/utils/membership'
import Box from '@mui/material/Box'
import useGetMyRoleAndOrg from '@/hooks/useGetMyRoleAndOrg'
import { FeatureFlag } from '@/optimizely/types'
import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import MessagesButton from './MessagesButton'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}))

const UserMenu = () => {
  const { isAdmin, isCaseManager } = useGetMyRoleAndOrg()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navMenuOpen = Boolean(anchorEl)
  const { attorneyProfile } = useProfile()
  const { profilePhoto } = useProfilePicture()
  const userInitials = `${attorneyProfile?.attorney?.[0]?.profiles?.[0]?.first_name?.[0]}${attorneyProfile?.attorney?.[0]?.profiles?.[0]?.last_name?.[0]}`
  const { getIsAttorneyVetted, setIsAttorneyVetted, setIsQualifiedAttorney } =
    useAttorneyData()
  const localIsAttorneyVetted = getIsAttorneyVetted()
  const isAttorneyVetted = !!attorneyProfile?.attorney?.[0]?.is_vetted
  const isAttorneyQualified = isQualifiedMembership(
    attorneyProfile?.attorney?.[0]?.membership_type?.id
  )

  useEffect(() => {
    if (isAttorneyVetted && !localIsAttorneyVetted) {
      setIsAttorneyVetted(isAttorneyVetted)
      setIsQualifiedAttorney(isAttorneyQualified)
    }
  }, [
    isAttorneyQualified,
    isAttorneyVetted,
    localIsAttorneyVetted,
    setIsAttorneyVetted,
    setIsQualifiedAttorney,
  ])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { [FeatureFlag.MARKETPLACE_ORG_PAYMENTS]: showOrgPayments } =
    useFeatureFlags()

  const menuItems = getMenuItems({
    showOrgWaterfalls: true,
    showOrgPayments,
  })

  const showFirmSettings = isAdmin || isCaseManager

  return (
    <>
      <Box display="flex" gap={2}>
        <MessagesButton />
        <IconButton
          id="customized-avatar-button"
          aria-controls={navMenuOpen ? 'customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={navMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          <JAvatar
            src={profilePhoto || ''}
            alt={userInitials}
            isVetted={isAttorneyVetted}
            isQualified={isAttorneyQualified}
            sx={{
              badge: {
                mr: 0,
              },
            }}
          >
            {userInitials}
          </JAvatar>
        </IconButton>
      </Box>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-avatar-button',
        }}
        anchorEl={anchorEl}
        open={navMenuOpen}
        onClose={handleClose}
      >
        {showFirmSettings && (
          <Link to={getAdminPanelFirstLink(menuItems)}>
            <MenuItem onClick={handleClose} disableRipple>
              <CorporateFareRoundedIcon sx={{ color: 'common.white' }} />
              Firm settings
            </MenuItem>
          </Link>
        )}
        <Link to={getEditPublicProfileFirstLink()}>
          <MenuItem onClick={handleClose} disableRipple>
            <PersonOutlineIcon />
            User profile
          </MenuItem>
        </Link>
        <Link to={getAccountSettingsFirstLink()}>
          <MenuItem onClick={handleClose} disableRipple>
            <SettingsOutlinedIcon />
            My account
          </MenuItem>
        </Link>
        <Divider sx={{ my: 0.5 }} />
        <Link to={ZENDESK_HELP_URL} target="_blank">
          <MenuItem onClick={handleClose} disableRipple>
            <HelpOutlineOutlinedIcon />
            Help center
          </MenuItem>
        </Link>
        <Link to={RoutesData.RSignOut.path}>
          <MenuItem onClick={handleClose} disableRipple>
            <ExitToAppOutlinedIcon />
            Log out
          </MenuItem>
        </Link>
      </StyledMenu>
    </>
  )
}

export default UserMenu
