import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { JSnackBarProps } from './types'

const JSnackBar: React.FC<JSnackBarProps> = ({
  open,
  severity,
  message,
  vertical = 'bottom',
  horizontal = 'center',
  handleClose,
  autoHideDuration = 2500,
}: JSnackBarProps) => (
  <Snackbar
    anchorOrigin={{
      vertical,
      horizontal,
    }}
    autoHideDuration={autoHideDuration}
    open={open}
    onClose={handleClose}
  >
    <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
      {message}
    </Alert>
  </Snackbar>
)

export default JSnackBar
