import { MenuItemType } from '@/components/Menu/types'
import { PATHS_WITH_SECTIONS, getSubsectionLink } from '@/router/RoutesUtils'

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'

export const ADMIN_PANEL_PAGES = {
  MEMBERS: 'Members',
  LAW_FIRM_INFO: 'Law Firm Info',
  INTEGRATION: 'Integrations',
  ORGANIZATION_WATERFALL: 'Firm Waterfall Referrals™',
  BLOCKLIST: 'Block List',
  PAYMENTS: 'Payments',
}

export const getMenuItems = ({
  showOrgWaterfalls,
  showOrgPayments,
}: {
  showOrgWaterfalls?: boolean
  showOrgPayments?: boolean
}): MenuItemType[] => [
  {
    sectionTitle: '',
    items: [
      {
        label: ADMIN_PANEL_PAGES.MEMBERS,
        icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
      },
      {
        label: ADMIN_PANEL_PAGES.LAW_FIRM_INFO,
        icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
      },
      ...(showOrgWaterfalls
        ? [
            {
              label: ADMIN_PANEL_PAGES.ORGANIZATION_WATERFALL,
              icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
            },
          ]
        : []),
      {
        label: ADMIN_PANEL_PAGES.INTEGRATION,
        icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
      },
      {
        label: ADMIN_PANEL_PAGES.BLOCKLIST,
        icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
      },
      ...(showOrgPayments
        ? [
            {
              label: ADMIN_PANEL_PAGES.PAYMENTS,
              icon: <GridViewOutlinedIcon sx={{ fontSize: '1rem' }} />,
            },
          ]
        : []),
    ],
  },
]

export const getAdminPanelFirstLabel = (menuItems: MenuItemType[]) =>
  menuItems?.[0]?.items?.[0]?.label
export const getAdminPanelFirstLink = (menuItems?: MenuItemType[]) =>
  getSubsectionLink(
    PATHS_WITH_SECTIONS.ADMIN_PANEL,
    getAdminPanelFirstLabel(
      menuItems || getMenuItems({ showOrgWaterfalls: false })
    )
  )
