import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"clientName":"auth"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  GraphQLBigInt: { input: any; output: any; }
  GraphQLStringOrFloat: { input: any; output: any; }
  Hash: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export enum EventEnum {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type Mutation = {
  __typename?: 'Mutation';
  auth_login?: Maybe<Auth_Tokens>;
  auth_logout?: Maybe<Scalars['Boolean']['output']>;
  auth_password_request?: Maybe<Scalars['Boolean']['output']>;
  auth_password_reset?: Maybe<Scalars['Boolean']['output']>;
  auth_refresh?: Maybe<Auth_Tokens>;
  create_dashboards_item?: Maybe<Directus_Dashboards>;
  create_dashboards_items: Array<Directus_Dashboards>;
  create_files_item?: Maybe<Directus_Files>;
  create_files_items: Array<Directus_Files>;
  create_folders_item?: Maybe<Directus_Folders>;
  create_folders_items: Array<Directus_Folders>;
  create_panels_item?: Maybe<Directus_Panels>;
  create_panels_items: Array<Directus_Panels>;
  create_shares_item?: Maybe<Directus_Shares>;
  create_shares_items: Array<Directus_Shares>;
  create_users_item?: Maybe<Directus_Users>;
  create_users_items: Array<Directus_Users>;
  delete_dashboards_item?: Maybe<Delete_One>;
  delete_dashboards_items?: Maybe<Delete_Many>;
  delete_files_item?: Maybe<Delete_One>;
  delete_files_items?: Maybe<Delete_Many>;
  delete_folders_item?: Maybe<Delete_One>;
  delete_folders_items?: Maybe<Delete_Many>;
  delete_panels_item?: Maybe<Delete_One>;
  delete_panels_items?: Maybe<Delete_Many>;
  delete_shares_item?: Maybe<Delete_One>;
  delete_shares_items?: Maybe<Delete_Many>;
  import_file?: Maybe<Directus_Files>;
  update_dashboards_batch: Array<Directus_Dashboards>;
  update_dashboards_item?: Maybe<Directus_Dashboards>;
  update_dashboards_items: Array<Directus_Dashboards>;
  update_files_batch: Array<Directus_Files>;
  update_files_item?: Maybe<Directus_Files>;
  update_files_items: Array<Directus_Files>;
  update_folders_batch: Array<Directus_Folders>;
  update_folders_item?: Maybe<Directus_Folders>;
  update_folders_items: Array<Directus_Folders>;
  update_panels_batch: Array<Directus_Panels>;
  update_panels_item?: Maybe<Directus_Panels>;
  update_panels_items: Array<Directus_Panels>;
  update_shares_batch: Array<Directus_Shares>;
  update_shares_item?: Maybe<Directus_Shares>;
  update_shares_items: Array<Directus_Shares>;
  update_users_batch: Array<Directus_Users>;
  update_users_item?: Maybe<Directus_Users>;
  update_users_items: Array<Directus_Users>;
  update_users_me?: Maybe<Directus_Users>;
  users_invite?: Maybe<Scalars['Boolean']['output']>;
  users_invite_accept?: Maybe<Scalars['Boolean']['output']>;
  users_me_tfa_disable?: Maybe<Scalars['Boolean']['output']>;
  users_me_tfa_enable?: Maybe<Scalars['Boolean']['output']>;
  users_me_tfa_generate?: Maybe<Users_Me_Tfa_Generate_Data>;
  users_register?: Maybe<Scalars['Boolean']['output']>;
  users_register_verify?: Maybe<Scalars['Boolean']['output']>;
  utils_cache_clear?: Maybe<Scalars['Void']['output']>;
  utils_hash_generate?: Maybe<Scalars['String']['output']>;
  utils_hash_verify?: Maybe<Scalars['Boolean']['output']>;
  utils_random_string?: Maybe<Scalars['String']['output']>;
  utils_revert?: Maybe<Scalars['Boolean']['output']>;
  utils_sort?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAuth_LoginArgs = {
  email: Scalars['String']['input'];
  mode?: InputMaybe<Auth_Mode>;
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};


export type MutationAuth_LogoutArgs = {
  mode?: InputMaybe<Auth_Mode>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAuth_Password_RequestArgs = {
  email: Scalars['String']['input'];
  reset_url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAuth_Password_ResetArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationAuth_RefreshArgs = {
  mode?: InputMaybe<Auth_Mode>;
  refresh_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreate_Dashboards_ItemArgs = {
  data: Create_Directus_Dashboards_Input;
};


export type MutationCreate_Dashboards_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Dashboards_Input>>;
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Files_ItemArgs = {
  data: Create_Directus_Files_Input;
};


export type MutationCreate_Files_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Files_Input>>;
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Folders_ItemArgs = {
  data: Create_Directus_Folders_Input;
};


export type MutationCreate_Folders_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Folders_Input>>;
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Panels_ItemArgs = {
  data: Create_Directus_Panels_Input;
};


export type MutationCreate_Panels_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Panels_Input>>;
  filter?: InputMaybe<Directus_Panels_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Shares_ItemArgs = {
  data: Create_Directus_Shares_Input;
};


export type MutationCreate_Shares_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Shares_Input>>;
  filter?: InputMaybe<Directus_Shares_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreate_Users_ItemArgs = {
  data: Create_Directus_Users_Input;
};


export type MutationCreate_Users_ItemsArgs = {
  data?: InputMaybe<Array<Create_Directus_Users_Input>>;
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationDelete_Dashboards_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Dashboards_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Files_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Files_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Folders_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Folders_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Panels_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Panels_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDelete_Shares_ItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDelete_Shares_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationImport_FileArgs = {
  data?: InputMaybe<Create_Directus_Files_Input>;
  url: Scalars['String']['input'];
};


export type MutationUpdate_Dashboards_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Dashboards_Input>>;
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Dashboards_ItemArgs = {
  data: Update_Directus_Dashboards_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Dashboards_ItemsArgs = {
  data: Update_Directus_Dashboards_Input;
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Files_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Files_Input>>;
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Files_ItemArgs = {
  data: Update_Directus_Files_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Files_ItemsArgs = {
  data: Update_Directus_Files_Input;
  filter?: InputMaybe<Directus_Files_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Folders_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Folders_Input>>;
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Folders_ItemArgs = {
  data: Update_Directus_Folders_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Folders_ItemsArgs = {
  data: Update_Directus_Folders_Input;
  filter?: InputMaybe<Directus_Folders_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Panels_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Panels_Input>>;
  filter?: InputMaybe<Directus_Panels_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Panels_ItemArgs = {
  data: Update_Directus_Panels_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Panels_ItemsArgs = {
  data: Update_Directus_Panels_Input;
  filter?: InputMaybe<Directus_Panels_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Shares_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Shares_Input>>;
  filter?: InputMaybe<Directus_Shares_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Shares_ItemArgs = {
  data: Update_Directus_Shares_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Shares_ItemsArgs = {
  data: Update_Directus_Shares_Input;
  filter?: InputMaybe<Directus_Shares_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Users_BatchArgs = {
  data?: InputMaybe<Array<Update_Directus_Users_Input>>;
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Users_ItemArgs = {
  data: Update_Directus_Users_Input;
  id: Scalars['ID']['input'];
};


export type MutationUpdate_Users_ItemsArgs = {
  data: Update_Directus_Users_Input;
  filter?: InputMaybe<Directus_Users_Filter>;
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdate_Users_MeArgs = {
  data?: InputMaybe<Update_Directus_Users_Input>;
};


export type MutationUsers_InviteArgs = {
  email: Scalars['String']['input'];
  invite_url?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};


export type MutationUsers_Invite_AcceptArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationUsers_Me_Tfa_DisableArgs = {
  otp: Scalars['String']['input'];
};


export type MutationUsers_Me_Tfa_EnableArgs = {
  otp: Scalars['String']['input'];
  secret: Scalars['String']['input'];
};


export type MutationUsers_Me_Tfa_GenerateArgs = {
  password: Scalars['String']['input'];
};


export type MutationUsers_RegisterArgs = {
  email: Scalars['String']['input'];
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  verification_url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUsers_Register_VerifyArgs = {
  token: Scalars['String']['input'];
};


export type MutationUtils_Hash_GenerateArgs = {
  string: Scalars['String']['input'];
};


export type MutationUtils_Hash_VerifyArgs = {
  hash: Scalars['String']['input'];
  string: Scalars['String']['input'];
};


export type MutationUtils_Random_StringArgs = {
  length?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUtils_RevertArgs = {
  revision: Scalars['ID']['input'];
};


export type MutationUtils_SortArgs = {
  collection: Scalars['String']['input'];
  item: Scalars['ID']['input'];
  to: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  dashboards: Array<Directus_Dashboards>;
  dashboards_aggregated: Array<Directus_Dashboards_Aggregated>;
  dashboards_by_id?: Maybe<Directus_Dashboards>;
  files: Array<Directus_Files>;
  files_aggregated: Array<Directus_Files_Aggregated>;
  files_by_id?: Maybe<Directus_Files>;
  flows: Array<Directus_Flows>;
  flows_aggregated: Array<Directus_Flows_Aggregated>;
  flows_by_id?: Maybe<Directus_Flows>;
  folders: Array<Directus_Folders>;
  folders_aggregated: Array<Directus_Folders_Aggregated>;
  folders_by_id?: Maybe<Directus_Folders>;
  panels: Array<Directus_Panels>;
  panels_aggregated: Array<Directus_Panels_Aggregated>;
  panels_by_id?: Maybe<Directus_Panels>;
  roles: Array<Directus_Roles>;
  roles_aggregated: Array<Directus_Roles_Aggregated>;
  roles_by_id?: Maybe<Directus_Roles>;
  server_health?: Maybe<Scalars['JSON']['output']>;
  server_info?: Maybe<Server_Info>;
  server_ping?: Maybe<Scalars['String']['output']>;
  server_specs_graphql?: Maybe<Scalars['String']['output']>;
  server_specs_oas?: Maybe<Scalars['JSON']['output']>;
  shares: Array<Directus_Shares>;
  shares_aggregated: Array<Directus_Shares_Aggregated>;
  shares_by_id?: Maybe<Directus_Shares>;
  users: Array<Directus_Users>;
  users_aggregated: Array<Directus_Users_Aggregated>;
  users_by_id?: Maybe<Directus_Users>;
  users_me?: Maybe<Directus_Users>;
};


export type QueryDashboardsArgs = {
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryDashboards_AggregatedArgs = {
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryDashboards_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFilesArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFiles_AggregatedArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFiles_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlowsArgs = {
  filter?: InputMaybe<Directus_Flows_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFlows_AggregatedArgs = {
  filter?: InputMaybe<Directus_Flows_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFlows_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFoldersArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFolders_AggregatedArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFolders_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPanelsArgs = {
  filter?: InputMaybe<Directus_Panels_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPanels_AggregatedArgs = {
  filter?: InputMaybe<Directus_Panels_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPanels_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRolesArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRoles_AggregatedArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRoles_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryServer_Specs_GraphqlArgs = {
  scope?: InputMaybe<Graphql_Sdl_Scope>;
};


export type QuerySharesArgs = {
  filter?: InputMaybe<Directus_Shares_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryShares_AggregatedArgs = {
  filter?: InputMaybe<Directus_Shares_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryShares_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsers_AggregatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsers_By_IdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  attorney_case_mp_preference_mutated?: Maybe<Attorney_Case_Mp_Preference_Mutated>;
  attorney_chat_key_mutated?: Maybe<Attorney_Chat_Key_Mutated>;
  attorney_favorite_mutated?: Maybe<Attorney_Favorite_Mutated>;
  attorney_invite_mutated?: Maybe<Attorney_Invite_Mutated>;
  attorney_mutated?: Maybe<Attorney_Mutated>;
  attorney_oauth_integration_mutated?: Maybe<Attorney_Oauth_Integration_Mutated>;
  attorney_payment_membership_mutated?: Maybe<Attorney_Payment_Membership_Mutated>;
  attorney_profile_award_mutated?: Maybe<Attorney_Profile_Award_Mutated>;
  attorney_profile_case_management_software_mutated?: Maybe<Attorney_Profile_Case_Management_Software_Mutated>;
  attorney_profile_communication_preference_mutated?: Maybe<Attorney_Profile_Communication_Preference_Mutated>;
  attorney_profile_contact_mutated?: Maybe<Attorney_Profile_Contact_Mutated>;
  attorney_profile_education_mutated?: Maybe<Attorney_Profile_Education_Mutated>;
  attorney_profile_firm_mutated?: Maybe<Attorney_Profile_Firm_Mutated>;
  attorney_profile_language_mutated?: Maybe<Attorney_Profile_Language_Mutated>;
  attorney_profile_license_insurance_mutated?: Maybe<Attorney_Profile_License_Insurance_Mutated>;
  attorney_profile_license_mutated?: Maybe<Attorney_Profile_License_Mutated>;
  attorney_profile_mutated?: Maybe<Attorney_Profile_Mutated>;
  attorney_profile_practice_area_mutated?: Maybe<Attorney_Profile_Practice_Area_Mutated>;
  attorney_stripe_mutated?: Maybe<Attorney_Stripe_Mutated>;
  attorney_waterfall_list_item_mutated?: Maybe<Attorney_Waterfall_List_Item_Mutated>;
  attorney_waterfall_list_jdx_mutated?: Maybe<Attorney_Waterfall_List_Jdx_Mutated>;
  attorney_waterfall_list_mutated?: Maybe<Attorney_Waterfall_List_Mutated>;
  attorney_waterfall_list_pa_mutated?: Maybe<Attorney_Waterfall_List_Pa_Mutated>;
  attorney_waterfall_mutated?: Maybe<Attorney_Waterfall_Mutated>;
  case_file_mutated?: Maybe<Case_File_Mutated>;
  case_lead_contact_mutated?: Maybe<Case_Lead_Contact_Mutated>;
  case_lead_mutated?: Maybe<Case_Lead_Mutated>;
  case_management_software_mutated?: Maybe<Case_Management_Software_Mutated>;
  case_mutated?: Maybe<Case_Mutated>;
  case_ownership_mutated?: Maybe<Case_Ownership_Mutated>;
  case_purchase_activity_mutated?: Maybe<Case_Purchase_Activity_Mutated>;
  case_purchase_mutated?: Maybe<Case_Purchase_Mutated>;
  case_purchase_ownership_mutated?: Maybe<Case_Purchase_Ownership_Mutated>;
  case_purchase_payment_mutated?: Maybe<Case_Purchase_Payment_Mutated>;
  case_purchase_sign_request_mutated?: Maybe<Case_Purchase_Sign_Request_Mutated>;
  catalog_access_request_status_mutated?: Maybe<Catalog_Access_Request_Status_Mutated>;
  catalog_account_type_mutated?: Maybe<Catalog_Account_Type_Mutated>;
  catalog_award_mutated?: Maybe<Catalog_Award_Mutated>;
  catalog_case_alert_mutated?: Maybe<Catalog_Case_Alert_Mutated>;
  catalog_case_file_type_mutated?: Maybe<Catalog_Case_File_Type_Mutated>;
  catalog_case_mp_preference_mutated?: Maybe<Catalog_Case_Mp_Preference_Mutated>;
  catalog_case_mp_visibility_mutated?: Maybe<Catalog_Case_Mp_Visibility_Mutated>;
  catalog_case_purchase_decline_reason_mutated?: Maybe<Catalog_Case_Purchase_Decline_Reason_Mutated>;
  catalog_case_purchase_status_mutated?: Maybe<Catalog_Case_Purchase_Status_Mutated>;
  catalog_communication_preference_mutated?: Maybe<Catalog_Communication_Preference_Mutated>;
  catalog_company_size_mutated?: Maybe<Catalog_Company_Size_Mutated>;
  catalog_contact_type_mutated?: Maybe<Catalog_Contact_Type_Mutated>;
  catalog_credit_price_type_mutated?: Maybe<Catalog_Credit_Price_Type_Mutated>;
  catalog_credit_status_mutated?: Maybe<Catalog_Credit_Status_Mutated>;
  catalog_education_grade_mutated?: Maybe<Catalog_Education_Grade_Mutated>;
  catalog_exempt_email_domain_mutated?: Maybe<Catalog_Exempt_Email_Domain_Mutated>;
  catalog_fee_agreement_status_mutated?: Maybe<Catalog_Fee_Agreement_Status_Mutated>;
  catalog_fee_mutated?: Maybe<Catalog_Fee_Mutated>;
  catalog_financing_source_mutated?: Maybe<Catalog_Financing_Source_Mutated>;
  catalog_firm_relationship_mutated?: Maybe<Catalog_Firm_Relationship_Mutated>;
  catalog_invite_status_mutated?: Maybe<Catalog_Invite_Status_Mutated>;
  catalog_language_mutated?: Maybe<Catalog_Language_Mutated>;
  catalog_license_status_mutated?: Maybe<Catalog_License_Status_Mutated>;
  catalog_malpractice_insurance_mutated?: Maybe<Catalog_Malpractice_Insurance_Mutated>;
  catalog_membership_type_mutated?: Maybe<Catalog_Membership_Type_Mutated>;
  catalog_oauth_integration_mutated?: Maybe<Catalog_Oauth_Integration_Mutated>;
  catalog_payment_method_mutated?: Maybe<Catalog_Payment_Method_Mutated>;
  catalog_payment_status_mutated?: Maybe<Catalog_Payment_Status_Mutated>;
  catalog_practice_area_mutated?: Maybe<Catalog_Practice_Area_Mutated>;
  catalog_process_stage_mutated?: Maybe<Catalog_Process_Stage_Mutated>;
  catalog_role_invite_mutated?: Maybe<Catalog_Role_Invite_Mutated>;
  catalog_sign_request_status_mutated?: Maybe<Catalog_Sign_Request_Status_Mutated>;
  catalog_time_unit_mutated?: Maybe<Catalog_Time_Unit_Mutated>;
  catalog_us_state_mutated?: Maybe<Catalog_Us_State_Mutated>;
  catalog_waterfall_duration_mutated?: Maybe<Catalog_Waterfall_Duration_Mutated>;
  catalog_waterfall_status_mutated?: Maybe<Catalog_Waterfall_Status_Mutated>;
  catalog_years_practice_mutated?: Maybe<Catalog_Years_Practice_Mutated>;
  config_lcms_leaddocket_mutated?: Maybe<Config_Lcms_Leaddocket_Mutated>;
  directus_dashboards_mutated?: Maybe<Directus_Dashboards_Mutated>;
  directus_files_mutated?: Maybe<Directus_Files_Mutated>;
  directus_flows_mutated?: Maybe<Directus_Flows_Mutated>;
  directus_folders_mutated?: Maybe<Directus_Folders_Mutated>;
  directus_panels_mutated?: Maybe<Directus_Panels_Mutated>;
  directus_roles_mutated?: Maybe<Directus_Roles_Mutated>;
  directus_shares_mutated?: Maybe<Directus_Shares_Mutated>;
  directus_users_mutated?: Maybe<Directus_Users_Mutated>;
  fct_affiliation_members_mutated?: Maybe<Fct_Affiliation_Members_Mutated>;
  lcms_case_mapping_mutated?: Maybe<Lcms_Case_Mapping_Mutated>;
  master_case_mutated?: Maybe<Master_Case_Mutated>;
  master_referral_mutated?: Maybe<Master_Referral_Mutated>;
  network_user_invite_mutated?: Maybe<Network_User_Invite_Mutated>;
  organization_block_mutated?: Maybe<Organization_Block_Mutated>;
  organization_connected_lcms_mutated?: Maybe<Organization_Connected_Lcms_Mutated>;
  organization_mutated?: Maybe<Organization_Mutated>;
  organization_profile_mutated?: Maybe<Organization_Profile_Mutated>;
  organization_user_mutated?: Maybe<Organization_User_Mutated>;
  organization_waterfall_list_item_mutated?: Maybe<Organization_Waterfall_List_Item_Mutated>;
  organization_waterfall_list_mutated?: Maybe<Organization_Waterfall_List_Mutated>;
  organization_waterfall_list_pa_mutated?: Maybe<Organization_Waterfall_List_Pa_Mutated>;
  practice_attorney_mapping_mutated?: Maybe<Practice_Attorney_Mapping_Mutated>;
  school_mutated?: Maybe<School_Mutated>;
  sum_analytics_attorney_metrics_mutated?: Maybe<Sum_Analytics_Attorney_Metrics_Mutated>;
  sum_analytics_org_metrics_mutated?: Maybe<Sum_Analytics_Org_Metrics_Mutated>;
  sum_analytics_org_practice_areas_buyer_mutated?: Maybe<Sum_Analytics_Org_Practice_Areas_Buyer_Mutated>;
  sum_analytics_org_practice_areas_seller_mutated?: Maybe<Sum_Analytics_Org_Practice_Areas_Seller_Mutated>;
  sum_analytics_top_attorneys_mutated?: Maybe<Sum_Analytics_Top_Attorneys_Mutated>;
  sum_analytics_top_practice_areas_mutated?: Maybe<Sum_Analytics_Top_Practice_Areas_Mutated>;
  user_account_type_mutated?: Maybe<User_Account_Type_Mutated>;
  user_allowed_file_mutated?: Maybe<User_Allowed_File_Mutated>;
  user_email_validation_mutated?: Maybe<User_Email_Validation_Mutated>;
  user_file_access_request_mutated?: Maybe<User_File_Access_Request_Mutated>;
};


export type SubscriptionAttorney_Case_Mp_Preference_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Chat_Key_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Favorite_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Invite_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Oauth_Integration_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Payment_Membership_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Award_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Case_Management_Software_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Communication_Preference_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Contact_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Education_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Firm_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Language_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_License_Insurance_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_License_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Profile_Practice_Area_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Stripe_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Waterfall_List_Item_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Waterfall_List_Jdx_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Waterfall_List_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Waterfall_List_Pa_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionAttorney_Waterfall_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_File_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Lead_Contact_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Lead_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Management_Software_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Ownership_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Purchase_Activity_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Purchase_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Purchase_Ownership_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Purchase_Payment_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCase_Purchase_Sign_Request_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Access_Request_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Account_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Award_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_Alert_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_File_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_Mp_Preference_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_Mp_Visibility_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_Purchase_Decline_Reason_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Case_Purchase_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Communication_Preference_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Company_Size_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Contact_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Credit_Price_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Credit_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Education_Grade_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Exempt_Email_Domain_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Fee_Agreement_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Fee_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Financing_Source_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Firm_Relationship_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Invite_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Language_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_License_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Malpractice_Insurance_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Membership_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Oauth_Integration_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Payment_Method_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Payment_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Practice_Area_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Process_Stage_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Role_Invite_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Sign_Request_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Time_Unit_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Us_State_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Waterfall_Duration_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Waterfall_Status_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionCatalog_Years_Practice_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionConfig_Lcms_Leaddocket_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Dashboards_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Files_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Flows_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Folders_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Panels_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Roles_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Shares_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionDirectus_Users_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionFct_Affiliation_Members_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionLcms_Case_Mapping_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionMaster_Case_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionMaster_Referral_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionNetwork_User_Invite_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Block_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Connected_Lcms_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Profile_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_User_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Waterfall_List_Item_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Waterfall_List_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionOrganization_Waterfall_List_Pa_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionPractice_Attorney_Mapping_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSchool_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Attorney_Metrics_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Org_Metrics_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Org_Practice_Areas_Buyer_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Org_Practice_Areas_Seller_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Top_Attorneys_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionSum_Analytics_Top_Practice_Areas_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionUser_Account_Type_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionUser_Allowed_File_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionUser_Email_Validation_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};


export type SubscriptionUser_File_Access_Request_MutatedArgs = {
  event?: InputMaybe<EventEnum>;
};

export type Attorney = {
  __typename?: 'attorney';
  cases?: Maybe<Array<Maybe<Case>>>;
  cases_func?: Maybe<Count_Functions>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  favorites?: Maybe<Array<Maybe<Attorney_Favorite>>>;
  favorites_func?: Maybe<Count_Functions>;
  id: Scalars['ID']['output'];
  is_vetted?: Maybe<Scalars['Boolean']['output']>;
  membership_type?: Maybe<Catalog_Membership_Type>;
  mp_preferences?: Maybe<Array<Maybe<Attorney_Case_Mp_Preference>>>;
  mp_preferences_func?: Maybe<Count_Functions>;
  oauth_integrations?: Maybe<Array<Maybe<Attorney_Oauth_Integration>>>;
  oauth_integrations_func?: Maybe<Count_Functions>;
  profiles?: Maybe<Array<Maybe<Attorney_Profile>>>;
  profiles_func?: Maybe<Count_Functions>;
  stream_chat_keys?: Maybe<Array<Maybe<Attorney_Chat_Key>>>;
  stream_chat_keys_func?: Maybe<Count_Functions>;
  stripe_data?: Maybe<Array<Maybe<Attorney_Stripe>>>;
  stripe_data_func?: Maybe<Count_Functions>;
  user_id?: Maybe<Directus_Users>;
  waterfall_lists?: Maybe<Array<Maybe<Attorney_Waterfall_List>>>;
  waterfall_lists_func?: Maybe<Count_Functions>;
  waterfalls?: Maybe<Array<Maybe<Attorney_Waterfall>>>;
  waterfalls_func?: Maybe<Count_Functions>;
};


export type AttorneyCasesArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyFavoritesArgs = {
  filter?: InputMaybe<Attorney_Favorite_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyMembership_TypeArgs = {
  filter?: InputMaybe<Catalog_Membership_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyMp_PreferencesArgs = {
  filter?: InputMaybe<Attorney_Case_Mp_Preference_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyOauth_IntegrationsArgs = {
  filter?: InputMaybe<Attorney_Oauth_Integration_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyProfilesArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyStream_Chat_KeysArgs = {
  filter?: InputMaybe<Attorney_Chat_Key_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyStripe_DataArgs = {
  filter?: InputMaybe<Attorney_Stripe_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyWaterfall_ListsArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type AttorneyWaterfallsArgs = {
  filter?: InputMaybe<Attorney_Waterfall_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Case_Mp_Preference = {
  __typename?: 'attorney_case_mp_preference';
  attorney_id?: Maybe<Attorney>;
  case_id?: Maybe<Case>;
  case_mp_preference_id?: Maybe<Catalog_Case_Mp_Preference>;
  id: Scalars['ID']['output'];
};


export type Attorney_Case_Mp_PreferenceAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Case_Mp_PreferenceCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Case_Mp_PreferenceCase_Mp_Preference_IdArgs = {
  filter?: InputMaybe<Catalog_Case_Mp_Preference_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Case_Mp_Preference_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Case_Mp_Preference_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Case_Mp_Preference_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  case_id?: InputMaybe<Case_Filter>;
  case_mp_preference_id?: InputMaybe<Catalog_Case_Mp_Preference_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Case_Mp_Preference_Mutated = {
  __typename?: 'attorney_case_mp_preference_mutated';
  data?: Maybe<Attorney_Case_Mp_Preference>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Chat_Key = {
  __typename?: 'attorney_chat_key';
  attorney_id?: Maybe<Attorney>;
  chat_token: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};


export type Attorney_Chat_KeyAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Chat_Key_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Chat_Key_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Chat_Key_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  chat_token?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Chat_Key_Mutated = {
  __typename?: 'attorney_chat_key_mutated';
  data?: Maybe<Attorney_Chat_Key>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Favorite = {
  __typename?: 'attorney_favorite';
  attorney_id?: Maybe<Attorney>;
  favorite_attorney_id?: Maybe<Attorney>;
  id: Scalars['ID']['output'];
};


export type Attorney_FavoriteAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_FavoriteFavorite_Attorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Favorite_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Favorite_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Favorite_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  favorite_attorney_id?: InputMaybe<Attorney_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Favorite_Mutated = {
  __typename?: 'attorney_favorite_mutated';
  data?: Maybe<Attorney_Favorite>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Filter>>>;
  cases?: InputMaybe<Case_Filter>;
  cases_func?: InputMaybe<Count_Function_Filter_Operators>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  favorites?: InputMaybe<Attorney_Favorite_Filter>;
  favorites_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  is_vetted?: InputMaybe<Boolean_Filter_Operators>;
  membership_type?: InputMaybe<Catalog_Membership_Type_Filter>;
  mp_preferences?: InputMaybe<Attorney_Case_Mp_Preference_Filter>;
  mp_preferences_func?: InputMaybe<Count_Function_Filter_Operators>;
  oauth_integrations?: InputMaybe<Attorney_Oauth_Integration_Filter>;
  oauth_integrations_func?: InputMaybe<Count_Function_Filter_Operators>;
  profiles?: InputMaybe<Attorney_Profile_Filter>;
  profiles_func?: InputMaybe<Count_Function_Filter_Operators>;
  stream_chat_keys?: InputMaybe<Attorney_Chat_Key_Filter>;
  stream_chat_keys_func?: InputMaybe<Count_Function_Filter_Operators>;
  stripe_data?: InputMaybe<Attorney_Stripe_Filter>;
  stripe_data_func?: InputMaybe<Count_Function_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
  waterfall_lists?: InputMaybe<Attorney_Waterfall_List_Filter>;
  waterfall_lists_func?: InputMaybe<Count_Function_Filter_Operators>;
  waterfalls?: InputMaybe<Attorney_Waterfall_Filter>;
  waterfalls_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Attorney_Invite = {
  __typename?: 'attorney_invite';
  completed_ts?: Maybe<Scalars['Date']['output']>;
  completed_ts_func?: Maybe<Datetime_Functions>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invite_status_id?: Maybe<Catalog_Invite_Status>;
  inviting_attorney?: Maybe<Attorney>;
  sent_ts?: Maybe<Scalars['Date']['output']>;
  sent_ts_func?: Maybe<Datetime_Functions>;
  target_attorney_id?: Maybe<Attorney>;
  target_organization_id?: Maybe<Organization>;
  target_role_id?: Maybe<Directus_Roles>;
  token?: Maybe<Scalars['String']['output']>;
};


export type Attorney_InviteInvite_Status_IdArgs = {
  filter?: InputMaybe<Catalog_Invite_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_InviteInviting_AttorneyArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_InviteTarget_Attorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_InviteTarget_Organization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_InviteTarget_Role_IdArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Invite_Mutated = {
  __typename?: 'attorney_invite_mutated';
  data?: Maybe<Attorney_Invite>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Mutated = {
  __typename?: 'attorney_mutated';
  data?: Maybe<Attorney>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Oauth_Integration = {
  __typename?: 'attorney_oauth_integration';
  attorney_id?: Maybe<Attorney>;
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integration_id?: Maybe<Catalog_Oauth_Integration>;
};


export type Attorney_Oauth_IntegrationAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Oauth_IntegrationIntegration_IdArgs = {
  filter?: InputMaybe<Catalog_Oauth_Integration_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Oauth_Integration_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Oauth_Integration_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Oauth_Integration_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  code?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  integration_id?: InputMaybe<Catalog_Oauth_Integration_Filter>;
};

export type Attorney_Oauth_Integration_Mutated = {
  __typename?: 'attorney_oauth_integration_mutated';
  data?: Maybe<Attorney_Oauth_Integration>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Payment_Membership = {
  __typename?: 'attorney_payment_membership';
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  payment_status_id?: Maybe<Catalog_Payment_Status>;
  subscription_id: Scalars['String']['output'];
};


export type Attorney_Payment_MembershipAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Payment_MembershipPayment_Status_IdArgs = {
  filter?: InputMaybe<Catalog_Payment_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Payment_Membership_Mutated = {
  __typename?: 'attorney_payment_membership_mutated';
  data?: Maybe<Attorney_Payment_Membership>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile = {
  __typename?: 'attorney_profile';
  attorney_id?: Maybe<Attorney>;
  awards?: Maybe<Array<Maybe<Attorney_Profile_Award>>>;
  awards_func?: Maybe<Count_Functions>;
  bio?: Maybe<Scalars['String']['output']>;
  case_management_sofwares?: Maybe<Array<Maybe<Attorney_Profile_Case_Management_Software>>>;
  case_management_sofwares_func?: Maybe<Count_Functions>;
  communication_preference?: Maybe<Array<Maybe<Attorney_Profile_Communication_Preference>>>;
  communication_preference_func?: Maybe<Count_Functions>;
  contacts?: Maybe<Array<Maybe<Attorney_Profile_Contact>>>;
  contacts_func?: Maybe<Count_Functions>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  education?: Maybe<Array<Maybe<Attorney_Profile_Education>>>;
  education_func?: Maybe<Count_Functions>;
  firms?: Maybe<Array<Maybe<Attorney_Profile_Firm>>>;
  firms_func?: Maybe<Count_Functions>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languages?: Maybe<Array<Maybe<Attorney_Profile_Language>>>;
  languages_func?: Maybe<Count_Functions>;
  last_name?: Maybe<Scalars['String']['output']>;
  licenses?: Maybe<Array<Maybe<Attorney_Profile_License>>>;
  licenses_func?: Maybe<Count_Functions>;
  practice_areas?: Maybe<Array<Maybe<Attorney_Profile_Practice_Area>>>;
  practice_areas_func?: Maybe<Count_Functions>;
  referral_source?: Maybe<Scalars['String']['output']>;
  years_practice?: Maybe<Catalog_Years_Practice>;
};


export type Attorney_ProfileAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileAwardsArgs = {
  filter?: InputMaybe<Attorney_Profile_Award_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileCase_Management_SofwaresArgs = {
  filter?: InputMaybe<Attorney_Profile_Case_Management_Software_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileCommunication_PreferenceArgs = {
  filter?: InputMaybe<Attorney_Profile_Communication_Preference_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileContactsArgs = {
  filter?: InputMaybe<Attorney_Profile_Contact_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileEducationArgs = {
  filter?: InputMaybe<Attorney_Profile_Education_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileFirmsArgs = {
  filter?: InputMaybe<Attorney_Profile_Firm_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileLanguagesArgs = {
  filter?: InputMaybe<Attorney_Profile_Language_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileLicensesArgs = {
  filter?: InputMaybe<Attorney_Profile_License_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfilePractice_AreasArgs = {
  filter?: InputMaybe<Attorney_Profile_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_ProfileYears_PracticeArgs = {
  filter?: InputMaybe<Catalog_Years_Practice_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Award = {
  __typename?: 'attorney_profile_award';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  award_id?: Maybe<Catalog_Award>;
  id: Scalars['ID']['output'];
};


export type Attorney_Profile_AwardAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_AwardAward_IdArgs = {
  filter?: InputMaybe<Catalog_Award_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Award_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Award_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Award_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  award_id?: InputMaybe<Catalog_Award_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Profile_Award_Mutated = {
  __typename?: 'attorney_profile_award_mutated';
  data?: Maybe<Attorney_Profile_Award>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Case_Management_Software = {
  __typename?: 'attorney_profile_case_management_software';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  case_management_software_id?: Maybe<Case_Management_Software>;
  id: Scalars['ID']['output'];
};


export type Attorney_Profile_Case_Management_SoftwareAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_Case_Management_SoftwareCase_Management_Software_IdArgs = {
  filter?: InputMaybe<Case_Management_Software_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Case_Management_Software_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Case_Management_Software_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Case_Management_Software_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  case_management_software_id?: InputMaybe<Case_Management_Software_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Profile_Case_Management_Software_Mutated = {
  __typename?: 'attorney_profile_case_management_software_mutated';
  data?: Maybe<Attorney_Profile_Case_Management_Software>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Communication_Preference = {
  __typename?: 'attorney_profile_communication_preference';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  is_email_enabled?: Maybe<Scalars['Boolean']['output']>;
  is_notification_enabled: Scalars['Boolean']['output'];
  is_sms_enabled?: Maybe<Scalars['Boolean']['output']>;
  notification_level?: Maybe<Catalog_Communication_Preference>;
};


export type Attorney_Profile_Communication_PreferenceAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_Communication_PreferenceNotification_LevelArgs = {
  filter?: InputMaybe<Catalog_Communication_Preference_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Communication_Preference_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Communication_Preference_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Communication_Preference_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  is_email_enabled?: InputMaybe<Boolean_Filter_Operators>;
  is_notification_enabled?: InputMaybe<Boolean_Filter_Operators>;
  is_sms_enabled?: InputMaybe<Boolean_Filter_Operators>;
  notification_level?: InputMaybe<Catalog_Communication_Preference_Filter>;
};

export type Attorney_Profile_Communication_Preference_Mutated = {
  __typename?: 'attorney_profile_communication_preference_mutated';
  data?: Maybe<Attorney_Profile_Communication_Preference>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Contact = {
  __typename?: 'attorney_profile_contact';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  id: Scalars['ID']['output'];
  type?: Maybe<Catalog_Contact_Type>;
  value: Scalars['String']['output'];
};


export type Attorney_Profile_ContactAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_ContactTypeArgs = {
  filter?: InputMaybe<Catalog_Contact_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Contact_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Contact_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Contact_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<Catalog_Contact_Type_Filter>;
  value?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Profile_Contact_Mutated = {
  __typename?: 'attorney_profile_contact_mutated';
  data?: Maybe<Attorney_Profile_Contact>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Education = {
  __typename?: 'attorney_profile_education';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  grade?: Maybe<Catalog_Education_Grade>;
  id: Scalars['ID']['output'];
  school_id?: Maybe<School>;
  year_end?: Maybe<Scalars['Int']['output']>;
  year_start?: Maybe<Scalars['Int']['output']>;
};


export type Attorney_Profile_EducationAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_EducationGradeArgs = {
  filter?: InputMaybe<Catalog_Education_Grade_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_EducationSchool_IdArgs = {
  filter?: InputMaybe<School_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Education_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Education_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Education_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  grade?: InputMaybe<Catalog_Education_Grade_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  school_id?: InputMaybe<School_Filter>;
  year_end?: InputMaybe<Number_Filter_Operators>;
  year_start?: InputMaybe<Number_Filter_Operators>;
};

export type Attorney_Profile_Education_Mutated = {
  __typename?: 'attorney_profile_education_mutated';
  data?: Maybe<Attorney_Profile_Education>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  awards?: InputMaybe<Attorney_Profile_Award_Filter>;
  awards_func?: InputMaybe<Count_Function_Filter_Operators>;
  bio?: InputMaybe<String_Filter_Operators>;
  case_management_sofwares?: InputMaybe<Attorney_Profile_Case_Management_Software_Filter>;
  case_management_sofwares_func?: InputMaybe<Count_Function_Filter_Operators>;
  communication_preference?: InputMaybe<Attorney_Profile_Communication_Preference_Filter>;
  communication_preference_func?: InputMaybe<Count_Function_Filter_Operators>;
  contacts?: InputMaybe<Attorney_Profile_Contact_Filter>;
  contacts_func?: InputMaybe<Count_Function_Filter_Operators>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  education?: InputMaybe<Attorney_Profile_Education_Filter>;
  education_func?: InputMaybe<Count_Function_Filter_Operators>;
  firms?: InputMaybe<Attorney_Profile_Firm_Filter>;
  firms_func?: InputMaybe<Count_Function_Filter_Operators>;
  first_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  languages?: InputMaybe<Attorney_Profile_Language_Filter>;
  languages_func?: InputMaybe<Count_Function_Filter_Operators>;
  last_name?: InputMaybe<String_Filter_Operators>;
  licenses?: InputMaybe<Attorney_Profile_License_Filter>;
  licenses_func?: InputMaybe<Count_Function_Filter_Operators>;
  practice_areas?: InputMaybe<Attorney_Profile_Practice_Area_Filter>;
  practice_areas_func?: InputMaybe<Count_Function_Filter_Operators>;
  referral_source?: InputMaybe<String_Filter_Operators>;
  years_practice?: InputMaybe<Catalog_Years_Practice_Filter>;
};

export type Attorney_Profile_Firm = {
  __typename?: 'attorney_profile_firm';
  address: Scalars['String']['output'];
  attorney_profile_id?: Maybe<Attorney_Profile>;
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  relationship?: Maybe<Catalog_Firm_Relationship>;
  size?: Maybe<Catalog_Company_Size>;
  state?: Maybe<Catalog_Us_State>;
  zip?: Maybe<Scalars['Int']['output']>;
};


export type Attorney_Profile_FirmAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_FirmRelationshipArgs = {
  filter?: InputMaybe<Catalog_Firm_Relationship_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_FirmSizeArgs = {
  filter?: InputMaybe<Catalog_Company_Size_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_FirmStateArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Firm_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Firm_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Firm_Filter>>>;
  address?: InputMaybe<String_Filter_Operators>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  city?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  relationship?: InputMaybe<Catalog_Firm_Relationship_Filter>;
  size?: InputMaybe<Catalog_Company_Size_Filter>;
  state?: InputMaybe<Catalog_Us_State_Filter>;
  zip?: InputMaybe<Number_Filter_Operators>;
};

export type Attorney_Profile_Firm_Mutated = {
  __typename?: 'attorney_profile_firm_mutated';
  data?: Maybe<Attorney_Profile_Firm>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Language = {
  __typename?: 'attorney_profile_language';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  id: Scalars['ID']['output'];
  language_id?: Maybe<Catalog_Language>;
};


export type Attorney_Profile_LanguageAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_LanguageLanguage_IdArgs = {
  filter?: InputMaybe<Catalog_Language_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Language_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Language_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Language_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  language_id?: InputMaybe<Catalog_Language_Filter>;
};

export type Attorney_Profile_Language_Mutated = {
  __typename?: 'attorney_profile_language_mutated';
  data?: Maybe<Attorney_Profile_Language>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_License = {
  __typename?: 'attorney_profile_license';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  bar_number: Scalars['String']['output'];
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  has_been_disciplined_by_bar: Scalars['Boolean']['output'];
  has_been_sued_by_malpractice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insurances?: Maybe<Array<Maybe<Attorney_Profile_License_Insurance>>>;
  insurances_func?: Maybe<Count_Functions>;
  is_license_suspended: Scalars['Boolean']['output'];
  license_state?: Maybe<Catalog_Us_State>;
  status?: Maybe<Catalog_License_Status>;
  validated_ts?: Maybe<Scalars['Date']['output']>;
  validated_ts_func?: Maybe<Datetime_Functions>;
};


export type Attorney_Profile_LicenseAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_LicenseInsurancesArgs = {
  filter?: InputMaybe<Attorney_Profile_License_Insurance_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_LicenseLicense_StateArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_LicenseStatusArgs = {
  filter?: InputMaybe<Catalog_License_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_License_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_License_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_License_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  bar_number?: InputMaybe<String_Filter_Operators>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  has_been_disciplined_by_bar?: InputMaybe<Boolean_Filter_Operators>;
  has_been_sued_by_malpractice?: InputMaybe<Boolean_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  insurances?: InputMaybe<Attorney_Profile_License_Insurance_Filter>;
  insurances_func?: InputMaybe<Count_Function_Filter_Operators>;
  is_license_suspended?: InputMaybe<Boolean_Filter_Operators>;
  license_state?: InputMaybe<Catalog_Us_State_Filter>;
  status?: InputMaybe<Catalog_License_Status_Filter>;
  validated_ts?: InputMaybe<Date_Filter_Operators>;
  validated_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
};

export type Attorney_Profile_License_Insurance = {
  __typename?: 'attorney_profile_license_insurance';
  attorney_profile_license_id?: Maybe<Attorney_Profile_License>;
  expiration_date?: Maybe<Scalars['Date']['output']>;
  expiration_date_func?: Maybe<Date_Functions>;
  id: Scalars['ID']['output'];
  insurance_coverage_id?: Maybe<Catalog_Malpractice_Insurance>;
};


export type Attorney_Profile_License_InsuranceAttorney_Profile_License_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_License_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_License_InsuranceInsurance_Coverage_IdArgs = {
  filter?: InputMaybe<Catalog_Malpractice_Insurance_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_License_Insurance_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_License_Insurance_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_License_Insurance_Filter>>>;
  attorney_profile_license_id?: InputMaybe<Attorney_Profile_License_Filter>;
  expiration_date?: InputMaybe<Date_Filter_Operators>;
  expiration_date_func?: InputMaybe<Date_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  insurance_coverage_id?: InputMaybe<Catalog_Malpractice_Insurance_Filter>;
};

export type Attorney_Profile_License_Insurance_Mutated = {
  __typename?: 'attorney_profile_license_insurance_mutated';
  data?: Maybe<Attorney_Profile_License_Insurance>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_License_Mutated = {
  __typename?: 'attorney_profile_license_mutated';
  data?: Maybe<Attorney_Profile_License>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Mutated = {
  __typename?: 'attorney_profile_mutated';
  data?: Maybe<Attorney_Profile>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Profile_Practice_Area = {
  __typename?: 'attorney_profile_practice_area';
  attorney_profile_id?: Maybe<Attorney_Profile>;
  id: Scalars['ID']['output'];
  practice_area_id?: Maybe<Catalog_Practice_Area>;
};


export type Attorney_Profile_Practice_AreaAttorney_Profile_IdArgs = {
  filter?: InputMaybe<Attorney_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Profile_Practice_AreaPractice_Area_IdArgs = {
  filter?: InputMaybe<Catalog_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Profile_Practice_Area_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Profile_Practice_Area_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Profile_Practice_Area_Filter>>>;
  attorney_profile_id?: InputMaybe<Attorney_Profile_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  practice_area_id?: InputMaybe<Catalog_Practice_Area_Filter>;
};

export type Attorney_Profile_Practice_Area_Mutated = {
  __typename?: 'attorney_profile_practice_area_mutated';
  data?: Maybe<Attorney_Profile_Practice_Area>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Stripe = {
  __typename?: 'attorney_stripe';
  attorney_id?: Maybe<Attorney>;
  id: Scalars['ID']['output'];
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
};


export type Attorney_StripeAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Stripe_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Stripe_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Stripe_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  stripe_customer_id?: InputMaybe<String_Filter_Operators>;
};

export type Attorney_Stripe_Mutated = {
  __typename?: 'attorney_stripe_mutated';
  data?: Maybe<Attorney_Stripe>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Waterfall = {
  __typename?: 'attorney_waterfall';
  alias?: Maybe<Scalars['String']['output']>;
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  current_item_id?: Maybe<Case_Purchase>;
  current_item_order?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  item_count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<Case_Purchase>>>;
  items_func?: Maybe<Count_Functions>;
  status?: Maybe<Catalog_Waterfall_Status>;
};


export type Attorney_WaterfallAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_WaterfallCurrent_Item_IdArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_WaterfallItemsArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_WaterfallStatusArgs = {
  filter?: InputMaybe<Catalog_Waterfall_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Waterfall_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_Filter>>>;
  alias?: InputMaybe<String_Filter_Operators>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  current_item_id?: InputMaybe<Case_Purchase_Filter>;
  current_item_order?: InputMaybe<Number_Filter_Operators>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  item_count?: InputMaybe<Number_Filter_Operators>;
  items?: InputMaybe<Case_Purchase_Filter>;
  items_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<Catalog_Waterfall_Status_Filter>;
};

export type Attorney_Waterfall_List = {
  __typename?: 'attorney_waterfall_list';
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<Attorney_Waterfall_List_Item>>>;
  items_func?: Maybe<Count_Functions>;
  jurisdictions?: Maybe<Array<Maybe<Attorney_Waterfall_List_Jdx>>>;
  jurisdictions_func?: Maybe<Count_Functions>;
  name?: Maybe<Scalars['String']['output']>;
  practice_areas?: Maybe<Array<Maybe<Attorney_Waterfall_List_Pa>>>;
  practice_areas_func?: Maybe<Count_Functions>;
};


export type Attorney_Waterfall_ListAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_ListItemsArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Item_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_ListJurisdictionsArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Jdx_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_ListPractice_AreasArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Pa_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Waterfall_List_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  items?: InputMaybe<Attorney_Waterfall_List_Item_Filter>;
  items_func?: InputMaybe<Count_Function_Filter_Operators>;
  jurisdictions?: InputMaybe<Attorney_Waterfall_List_Jdx_Filter>;
  jurisdictions_func?: InputMaybe<Count_Function_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  practice_areas?: InputMaybe<Attorney_Waterfall_List_Pa_Filter>;
  practice_areas_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Attorney_Waterfall_List_Item = {
  __typename?: 'attorney_waterfall_list_item';
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  list_order?: Maybe<Scalars['Int']['output']>;
  waterfall_list_id?: Maybe<Attorney_Waterfall_List>;
};


export type Attorney_Waterfall_List_ItemAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_List_ItemWaterfall_List_IdArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Waterfall_List_Item_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Item_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Item_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  list_order?: InputMaybe<Number_Filter_Operators>;
  waterfall_list_id?: InputMaybe<Attorney_Waterfall_List_Filter>;
};

export type Attorney_Waterfall_List_Item_Mutated = {
  __typename?: 'attorney_waterfall_list_item_mutated';
  data?: Maybe<Attorney_Waterfall_List_Item>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Waterfall_List_Jdx = {
  __typename?: 'attorney_waterfall_list_jdx';
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  jdx_id?: Maybe<Catalog_Us_State>;
  waterfall_list_id?: Maybe<Attorney_Waterfall_List>;
};


export type Attorney_Waterfall_List_JdxJdx_IdArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_List_JdxWaterfall_List_IdArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Waterfall_List_Jdx_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Jdx_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Jdx_Filter>>>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  jdx_id?: InputMaybe<Catalog_Us_State_Filter>;
  waterfall_list_id?: InputMaybe<Attorney_Waterfall_List_Filter>;
};

export type Attorney_Waterfall_List_Jdx_Mutated = {
  __typename?: 'attorney_waterfall_list_jdx_mutated';
  data?: Maybe<Attorney_Waterfall_List_Jdx>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Waterfall_List_Mutated = {
  __typename?: 'attorney_waterfall_list_mutated';
  data?: Maybe<Attorney_Waterfall_List>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Waterfall_List_Pa = {
  __typename?: 'attorney_waterfall_list_pa';
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  practice_area_id?: Maybe<Catalog_Practice_Area>;
  waterfall_list_id?: Maybe<Attorney_Waterfall_List>;
};


export type Attorney_Waterfall_List_PaPractice_Area_IdArgs = {
  filter?: InputMaybe<Catalog_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Attorney_Waterfall_List_PaWaterfall_List_IdArgs = {
  filter?: InputMaybe<Attorney_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Attorney_Waterfall_List_Pa_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Pa_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Attorney_Waterfall_List_Pa_Filter>>>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  practice_area_id?: InputMaybe<Catalog_Practice_Area_Filter>;
  waterfall_list_id?: InputMaybe<Attorney_Waterfall_List_Filter>;
};

export type Attorney_Waterfall_List_Pa_Mutated = {
  __typename?: 'attorney_waterfall_list_pa_mutated';
  data?: Maybe<Attorney_Waterfall_List_Pa>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Attorney_Waterfall_Mutated = {
  __typename?: 'attorney_waterfall_mutated';
  data?: Maybe<Attorney_Waterfall>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export enum Auth_Mode {
  Cookie = 'cookie',
  Json = 'json',
  Session = 'session'
}

export type Auth_Tokens = {
  __typename?: 'auth_tokens';
  access_token?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['GraphQLBigInt']['output']>;
  refresh_token?: Maybe<Scalars['String']['output']>;
};

export type Big_Int_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']['input']>>>;
  _eq?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _gt?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _gte?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']['input']>>>;
  _lt?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _lte?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']['input']>>>;
  _neq?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLBigInt']['input']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Boolean_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Case = {
  __typename?: 'case';
  attorney_id?: Maybe<Attorney>;
  case_alias?: Maybe<Scalars['String']['output']>;
  case_number?: Maybe<Scalars['String']['output']>;
  created_by_user_id?: Maybe<Scalars['String']['output']>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  data_room_filename?: Maybe<Scalars['String']['output']>;
  data_room_filesize?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  description_formatted?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  files?: Maybe<Array<Maybe<Case_File>>>;
  files_func?: Maybe<Count_Functions>;
  financing_source?: Maybe<Catalog_Financing_Source>;
  following_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  incident_date?: Maybe<Scalars['Date']['output']>;
  incident_date_func?: Maybe<Date_Functions>;
  internal_case_number?: Maybe<Scalars['String']['output']>;
  jurisdiction?: Maybe<Catalog_Us_State>;
  leads?: Maybe<Array<Maybe<Case_Lead>>>;
  leads_func?: Maybe<Count_Functions>;
  mp_visibility?: Maybe<Catalog_Case_Mp_Visibility>;
  name?: Maybe<Scalars['String']['output']>;
  ownerships?: Maybe<Array<Maybe<Case_Ownership>>>;
  ownerships_func?: Maybe<Count_Functions>;
  preferred_language?: Maybe<Catalog_Language>;
  process_stage?: Maybe<Catalog_Process_Stage>;
  proposal_due_date?: Maybe<Scalars['Date']['output']>;
  proposal_due_date_func?: Maybe<Date_Functions>;
  status?: Maybe<Scalars['String']['output']>;
  status_ts?: Maybe<Scalars['Date']['output']>;
  status_ts_func?: Maybe<Datetime_Functions>;
  type?: Maybe<Catalog_Practice_Area>;
  user_mp_preferences?: Maybe<Array<Maybe<Attorney_Case_Mp_Preference>>>;
  user_mp_preferences_func?: Maybe<Count_Functions>;
};


export type CaseAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseFilesArgs = {
  filter?: InputMaybe<Case_File_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseFinancing_SourceArgs = {
  filter?: InputMaybe<Catalog_Financing_Source_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseJurisdictionArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseLeadsArgs = {
  filter?: InputMaybe<Case_Lead_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseMp_VisibilityArgs = {
  filter?: InputMaybe<Catalog_Case_Mp_Visibility_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseOwnershipsArgs = {
  filter?: InputMaybe<Case_Ownership_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CasePreferred_LanguageArgs = {
  filter?: InputMaybe<Catalog_Language_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseProcess_StageArgs = {
  filter?: InputMaybe<Catalog_Process_Stage_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseTypeArgs = {
  filter?: InputMaybe<Catalog_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseUser_Mp_PreferencesArgs = {
  filter?: InputMaybe<Attorney_Case_Mp_Preference_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_File = {
  __typename?: 'case_file';
  alias?: Maybe<Scalars['String']['output']>;
  case_file_type?: Maybe<Catalog_Case_File_Type>;
  case_id?: Maybe<Case>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  file_id?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
};


export type Case_FileCase_File_TypeArgs = {
  filter?: InputMaybe<Catalog_Case_File_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_FileCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_FileFile_IdArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_File_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_File_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_File_Filter>>>;
  alias?: InputMaybe<String_Filter_Operators>;
  case_file_type?: InputMaybe<Catalog_Case_File_Type_Filter>;
  case_id?: InputMaybe<Case_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  file_id?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
};

export type Case_File_Mutated = {
  __typename?: 'case_file_mutated';
  data?: Maybe<Case_File>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  case_alias?: InputMaybe<String_Filter_Operators>;
  case_number?: InputMaybe<String_Filter_Operators>;
  created_by_user_id?: InputMaybe<String_Filter_Operators>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  data_room_filename?: InputMaybe<String_Filter_Operators>;
  data_room_filesize?: InputMaybe<Number_Filter_Operators>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  description_formatted?: InputMaybe<String_Filter_Operators>;
  fee?: InputMaybe<Number_Filter_Operators>;
  files?: InputMaybe<Case_File_Filter>;
  files_func?: InputMaybe<Count_Function_Filter_Operators>;
  financing_source?: InputMaybe<Catalog_Financing_Source_Filter>;
  following_count?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  incident_date?: InputMaybe<Date_Filter_Operators>;
  incident_date_func?: InputMaybe<Date_Function_Filter_Operators>;
  internal_case_number?: InputMaybe<String_Filter_Operators>;
  jurisdiction?: InputMaybe<Catalog_Us_State_Filter>;
  leads?: InputMaybe<Case_Lead_Filter>;
  leads_func?: InputMaybe<Count_Function_Filter_Operators>;
  mp_visibility?: InputMaybe<Catalog_Case_Mp_Visibility_Filter>;
  name?: InputMaybe<String_Filter_Operators>;
  ownerships?: InputMaybe<Case_Ownership_Filter>;
  ownerships_func?: InputMaybe<Count_Function_Filter_Operators>;
  preferred_language?: InputMaybe<Catalog_Language_Filter>;
  process_stage?: InputMaybe<Catalog_Process_Stage_Filter>;
  proposal_due_date?: InputMaybe<Date_Filter_Operators>;
  proposal_due_date_func?: InputMaybe<Date_Function_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  status_ts?: InputMaybe<Date_Filter_Operators>;
  status_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  type?: InputMaybe<Catalog_Practice_Area_Filter>;
  user_mp_preferences?: InputMaybe<Attorney_Case_Mp_Preference_Filter>;
  user_mp_preferences_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Case_Lead = {
  __typename?: 'case_lead';
  case_id?: Maybe<Case>;
  contacts?: Maybe<Array<Maybe<Case_Lead_Contact>>>;
  contacts_func?: Maybe<Count_Functions>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
};


export type Case_LeadCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_LeadContactsArgs = {
  filter?: InputMaybe<Case_Lead_Contact_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Lead_Contact = {
  __typename?: 'case_lead_contact';
  case_lead_id?: Maybe<Case_Lead>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Catalog_Contact_Type>;
  value: Scalars['String']['output'];
};


export type Case_Lead_ContactCase_Lead_IdArgs = {
  filter?: InputMaybe<Case_Lead_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Lead_ContactTypeArgs = {
  filter?: InputMaybe<Catalog_Contact_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Lead_Contact_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Lead_Contact_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Lead_Contact_Filter>>>;
  case_lead_id?: InputMaybe<Case_Lead_Filter>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<Catalog_Contact_Type_Filter>;
  value?: InputMaybe<String_Filter_Operators>;
};

export type Case_Lead_Contact_Mutated = {
  __typename?: 'case_lead_contact_mutated';
  data?: Maybe<Case_Lead_Contact>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Lead_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Lead_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Lead_Filter>>>;
  case_id?: InputMaybe<Case_Filter>;
  contacts?: InputMaybe<Case_Lead_Contact_Filter>;
  contacts_func?: InputMaybe<Count_Function_Filter_Operators>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  first_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  last_name?: InputMaybe<String_Filter_Operators>;
};

export type Case_Lead_Mutated = {
  __typename?: 'case_lead_mutated';
  data?: Maybe<Case_Lead>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Management_Software = {
  __typename?: 'case_management_software';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Case_Management_Software_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Management_Software_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Management_Software_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Case_Management_Software_Mutated = {
  __typename?: 'case_management_software_mutated';
  data?: Maybe<Case_Management_Software>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Mutated = {
  __typename?: 'case_mutated';
  data?: Maybe<Case>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Ownership = {
  __typename?: 'case_ownership';
  case_id?: Maybe<Case>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  user_id?: Maybe<Directus_Users>;
};


export type Case_OwnershipCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_OwnershipUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Ownership_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Ownership_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Ownership_Filter>>>;
  case_id?: InputMaybe<Case_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
};

export type Case_Ownership_Mutated = {
  __typename?: 'case_ownership_mutated';
  data?: Maybe<Case_Ownership>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Purchase = {
  __typename?: 'case_purchase';
  active_ts?: Maybe<Scalars['Date']['output']>;
  active_ts_func?: Maybe<Datetime_Functions>;
  activity?: Maybe<Array<Maybe<Case_Purchase_Activity>>>;
  activity_func?: Maybe<Count_Functions>;
  case_id?: Maybe<Case>;
  created_by?: Maybe<Attorney>;
  created_by_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fee_agreement_status?: Maybe<Catalog_Fee_Agreement_Status>;
  fee_agreement_status_ts?: Maybe<Scalars['Date']['output']>;
  fee_agreement_status_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  ownerships?: Maybe<Array<Maybe<Case_Purchase_Ownership>>>;
  ownerships_func?: Maybe<Count_Functions>;
  payments?: Maybe<Array<Maybe<Case_Purchase_Payment>>>;
  payments_func?: Maybe<Count_Functions>;
  prospect_attorney_id?: Maybe<Attorney>;
  sign_request?: Maybe<Array<Maybe<Case_Purchase_Sign_Request>>>;
  sign_request_func?: Maybe<Count_Functions>;
  status?: Maybe<Catalog_Case_Purchase_Status>;
  status_ts?: Maybe<Scalars['Date']['output']>;
  status_ts_func?: Maybe<Datetime_Functions>;
  time_unit?: Maybe<Catalog_Time_Unit>;
  timed_access_limit?: Maybe<Scalars['Int']['output']>;
  waterfall_id?: Maybe<Attorney_Waterfall>;
  waterfall_order?: Maybe<Scalars['Int']['output']>;
};


export type Case_PurchaseActivityArgs = {
  filter?: InputMaybe<Case_Purchase_Activity_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseCreated_ByArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseCreated_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseFee_Agreement_StatusArgs = {
  filter?: InputMaybe<Catalog_Fee_Agreement_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseOwnershipsArgs = {
  filter?: InputMaybe<Case_Purchase_Ownership_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchasePaymentsArgs = {
  filter?: InputMaybe<Case_Purchase_Payment_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseProspect_Attorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseSign_RequestArgs = {
  filter?: InputMaybe<Case_Purchase_Sign_Request_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseStatusArgs = {
  filter?: InputMaybe<Catalog_Case_Purchase_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseTime_UnitArgs = {
  filter?: InputMaybe<Catalog_Time_Unit_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_PurchaseWaterfall_IdArgs = {
  filter?: InputMaybe<Attorney_Waterfall_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Purchase_Activity = {
  __typename?: 'case_purchase_activity';
  activity_entry_status?: Maybe<Catalog_Case_Purchase_Status>;
  case_purchase_id?: Maybe<Case_Purchase>;
  created_by?: Maybe<Attorney>;
  created_by_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  decline_reason_id?: Maybe<Catalog_Case_Purchase_Decline_Reason>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  fee: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
};


export type Case_Purchase_ActivityActivity_Entry_StatusArgs = {
  filter?: InputMaybe<Catalog_Case_Purchase_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_ActivityCase_Purchase_IdArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_ActivityCreated_ByArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_ActivityCreated_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_ActivityDecline_Reason_IdArgs = {
  filter?: InputMaybe<Catalog_Case_Purchase_Decline_Reason_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Purchase_Activity_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Purchase_Activity_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Purchase_Activity_Filter>>>;
  activity_entry_status?: InputMaybe<Catalog_Case_Purchase_Status_Filter>;
  case_purchase_id?: InputMaybe<Case_Purchase_Filter>;
  created_by?: InputMaybe<Attorney_Filter>;
  created_by_user_id?: InputMaybe<Directus_Users_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  decline_reason_id?: InputMaybe<Catalog_Case_Purchase_Decline_Reason_Filter>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  fee?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  message?: InputMaybe<String_Filter_Operators>;
};

export type Case_Purchase_Activity_Mutated = {
  __typename?: 'case_purchase_activity_mutated';
  data?: Maybe<Case_Purchase_Activity>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Purchase_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Purchase_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Purchase_Filter>>>;
  active_ts?: InputMaybe<Date_Filter_Operators>;
  active_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  activity?: InputMaybe<Case_Purchase_Activity_Filter>;
  activity_func?: InputMaybe<Count_Function_Filter_Operators>;
  case_id?: InputMaybe<Case_Filter>;
  created_by?: InputMaybe<Attorney_Filter>;
  created_by_user_id?: InputMaybe<Directus_Users_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  deleted?: InputMaybe<Boolean_Filter_Operators>;
  fee_agreement_status?: InputMaybe<Catalog_Fee_Agreement_Status_Filter>;
  fee_agreement_status_ts?: InputMaybe<Date_Filter_Operators>;
  fee_agreement_status_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  ownerships?: InputMaybe<Case_Purchase_Ownership_Filter>;
  ownerships_func?: InputMaybe<Count_Function_Filter_Operators>;
  payments?: InputMaybe<Case_Purchase_Payment_Filter>;
  payments_func?: InputMaybe<Count_Function_Filter_Operators>;
  prospect_attorney_id?: InputMaybe<Attorney_Filter>;
  sign_request?: InputMaybe<Case_Purchase_Sign_Request_Filter>;
  sign_request_func?: InputMaybe<Count_Function_Filter_Operators>;
  status?: InputMaybe<Catalog_Case_Purchase_Status_Filter>;
  status_ts?: InputMaybe<Date_Filter_Operators>;
  status_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  time_unit?: InputMaybe<Catalog_Time_Unit_Filter>;
  timed_access_limit?: InputMaybe<Number_Filter_Operators>;
  waterfall_id?: InputMaybe<Attorney_Waterfall_Filter>;
  waterfall_order?: InputMaybe<Number_Filter_Operators>;
};

export type Case_Purchase_Mutated = {
  __typename?: 'case_purchase_mutated';
  data?: Maybe<Case_Purchase>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Purchase_Ownership = {
  __typename?: 'case_purchase_ownership';
  case_purchase_id?: Maybe<Case_Purchase>;
  id: Scalars['ID']['output'];
  user_id?: Maybe<Directus_Users>;
};


export type Case_Purchase_OwnershipCase_Purchase_IdArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_OwnershipUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Purchase_Ownership_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Purchase_Ownership_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Purchase_Ownership_Filter>>>;
  case_purchase_id?: InputMaybe<Case_Purchase_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
};

export type Case_Purchase_Ownership_Mutated = {
  __typename?: 'case_purchase_ownership_mutated';
  data?: Maybe<Case_Purchase_Ownership>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Purchase_Payment = {
  __typename?: 'case_purchase_payment';
  amount: Scalars['Float']['output'];
  amount_audit?: Maybe<Scalars['JSON']['output']>;
  amount_audit_func?: Maybe<Count_Functions>;
  attorney_id?: Maybe<Attorney>;
  case_purchase_id?: Maybe<Case_Purchase>;
  created_by_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  payment_intent?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_status_id?: Maybe<Catalog_Payment_Status>;
};


export type Case_Purchase_PaymentAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_PaymentCase_Purchase_IdArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_PaymentCreated_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_PaymentPayment_Status_IdArgs = {
  filter?: InputMaybe<Catalog_Payment_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Purchase_Payment_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Purchase_Payment_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Purchase_Payment_Filter>>>;
  amount?: InputMaybe<Number_Filter_Operators>;
  amount_audit?: InputMaybe<String_Filter_Operators>;
  amount_audit_func?: InputMaybe<Count_Function_Filter_Operators>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  case_purchase_id?: InputMaybe<Case_Purchase_Filter>;
  created_by_user_id?: InputMaybe<Directus_Users_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  payment_intent?: InputMaybe<String_Filter_Operators>;
  payment_method?: InputMaybe<String_Filter_Operators>;
  payment_status_id?: InputMaybe<Catalog_Payment_Status_Filter>;
};

export type Case_Purchase_Payment_Mutated = {
  __typename?: 'case_purchase_payment_mutated';
  data?: Maybe<Case_Purchase_Payment>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Case_Purchase_Sign_Request = {
  __typename?: 'case_purchase_sign_request';
  case_purchase_id?: Maybe<Case_Purchase>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  sign_request_id: Scalars['String']['output'];
  sign_request_status_id?: Maybe<Catalog_Sign_Request_Status>;
  url_file?: Maybe<Scalars['String']['output']>;
  url_final_copy?: Maybe<Scalars['String']['output']>;
  url_request?: Maybe<Scalars['String']['output']>;
};


export type Case_Purchase_Sign_RequestCase_Purchase_IdArgs = {
  filter?: InputMaybe<Case_Purchase_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Case_Purchase_Sign_RequestSign_Request_Status_IdArgs = {
  filter?: InputMaybe<Catalog_Sign_Request_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Case_Purchase_Sign_Request_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Case_Purchase_Sign_Request_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Case_Purchase_Sign_Request_Filter>>>;
  case_purchase_id?: InputMaybe<Case_Purchase_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  sign_request_id?: InputMaybe<String_Filter_Operators>;
  sign_request_status_id?: InputMaybe<Catalog_Sign_Request_Status_Filter>;
  url_file?: InputMaybe<String_Filter_Operators>;
  url_final_copy?: InputMaybe<String_Filter_Operators>;
  url_request?: InputMaybe<String_Filter_Operators>;
};

export type Case_Purchase_Sign_Request_Mutated = {
  __typename?: 'case_purchase_sign_request_mutated';
  data?: Maybe<Case_Purchase_Sign_Request>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Access_Request_Status = {
  __typename?: 'catalog_access_request_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Access_Request_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Access_Request_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Access_Request_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Access_Request_Status_Mutated = {
  __typename?: 'catalog_access_request_status_mutated';
  data?: Maybe<Catalog_Access_Request_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Account_Type = {
  __typename?: 'catalog_account_type';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Account_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Account_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Account_Type_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Account_Type_Mutated = {
  __typename?: 'catalog_account_type_mutated';
  data?: Maybe<Catalog_Account_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Award = {
  __typename?: 'catalog_award';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Award_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Award_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Award_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Award_Mutated = {
  __typename?: 'catalog_award_mutated';
  data?: Maybe<Catalog_Award>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_Alert = {
  __typename?: 'catalog_case_alert';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type Catalog_Case_Alert_Mutated = {
  __typename?: 'catalog_case_alert_mutated';
  data?: Maybe<Catalog_Case_Alert>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_File_Type = {
  __typename?: 'catalog_case_file_type';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Case_File_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Case_File_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Case_File_Type_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Case_File_Type_Mutated = {
  __typename?: 'catalog_case_file_type_mutated';
  data?: Maybe<Catalog_Case_File_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_Mp_Preference = {
  __typename?: 'catalog_case_mp_preference';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Case_Mp_Preference_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Case_Mp_Preference_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Case_Mp_Preference_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Case_Mp_Preference_Mutated = {
  __typename?: 'catalog_case_mp_preference_mutated';
  data?: Maybe<Catalog_Case_Mp_Preference>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_Mp_Visibility = {
  __typename?: 'catalog_case_mp_visibility';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Case_Mp_Visibility_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Case_Mp_Visibility_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Case_Mp_Visibility_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Case_Mp_Visibility_Mutated = {
  __typename?: 'catalog_case_mp_visibility_mutated';
  data?: Maybe<Catalog_Case_Mp_Visibility>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_Purchase_Decline_Reason = {
  __typename?: 'catalog_case_purchase_decline_reason';
  id: Scalars['ID']['output'];
  is_case_relistable?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type Catalog_Case_Purchase_Decline_Reason_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Case_Purchase_Decline_Reason_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Case_Purchase_Decline_Reason_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  is_case_relistable?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Case_Purchase_Decline_Reason_Mutated = {
  __typename?: 'catalog_case_purchase_decline_reason_mutated';
  data?: Maybe<Catalog_Case_Purchase_Decline_Reason>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Case_Purchase_Status = {
  __typename?: 'catalog_case_purchase_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Case_Purchase_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Case_Purchase_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Case_Purchase_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Case_Purchase_Status_Mutated = {
  __typename?: 'catalog_case_purchase_status_mutated';
  data?: Maybe<Catalog_Case_Purchase_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Communication_Preference = {
  __typename?: 'catalog_communication_preference';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Catalog_Communication_Preference_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Communication_Preference_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Communication_Preference_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Communication_Preference_Mutated = {
  __typename?: 'catalog_communication_preference_mutated';
  data?: Maybe<Catalog_Communication_Preference>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Company_Size = {
  __typename?: 'catalog_company_size';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type Catalog_Company_Size_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Company_Size_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Company_Size_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  order?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Company_Size_Mutated = {
  __typename?: 'catalog_company_size_mutated';
  data?: Maybe<Catalog_Company_Size>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Contact_Type = {
  __typename?: 'catalog_contact_type';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Contact_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Contact_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Contact_Type_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Contact_Type_Mutated = {
  __typename?: 'catalog_contact_type_mutated';
  data?: Maybe<Catalog_Contact_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Credit_Price_Type = {
  __typename?: 'catalog_credit_price_type';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Catalog_Credit_Price_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Credit_Price_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Credit_Price_Type_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Credit_Price_Type_Mutated = {
  __typename?: 'catalog_credit_price_type_mutated';
  data?: Maybe<Catalog_Credit_Price_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Credit_Status = {
  __typename?: 'catalog_credit_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Credit_Status_Mutated = {
  __typename?: 'catalog_credit_status_mutated';
  data?: Maybe<Catalog_Credit_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Education_Grade = {
  __typename?: 'catalog_education_grade';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Education_Grade_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Education_Grade_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Education_Grade_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Education_Grade_Mutated = {
  __typename?: 'catalog_education_grade_mutated';
  data?: Maybe<Catalog_Education_Grade>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Exempt_Email_Domain = {
  __typename?: 'catalog_exempt_email_domain';
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Catalog_Exempt_Email_Domain_Mutated = {
  __typename?: 'catalog_exempt_email_domain_mutated';
  data?: Maybe<Catalog_Exempt_Email_Domain>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Fee = {
  __typename?: 'catalog_fee';
  amount: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price_type?: Maybe<Catalog_Credit_Price_Type>;
  stripe_price_id: Scalars['String']['output'];
};


export type Catalog_FeePrice_TypeArgs = {
  filter?: InputMaybe<Catalog_Credit_Price_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Catalog_Fee_Agreement_Status = {
  __typename?: 'catalog_fee_agreement_status';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Catalog_Fee_Agreement_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Fee_Agreement_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Fee_Agreement_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Fee_Agreement_Status_Mutated = {
  __typename?: 'catalog_fee_agreement_status_mutated';
  data?: Maybe<Catalog_Fee_Agreement_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Fee_Mutated = {
  __typename?: 'catalog_fee_mutated';
  data?: Maybe<Catalog_Fee>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Financing_Source = {
  __typename?: 'catalog_financing_source';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Financing_Source_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Financing_Source_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Financing_Source_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Financing_Source_Mutated = {
  __typename?: 'catalog_financing_source_mutated';
  data?: Maybe<Catalog_Financing_Source>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Firm_Relationship = {
  __typename?: 'catalog_firm_relationship';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type Catalog_Firm_Relationship_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Firm_Relationship_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Firm_Relationship_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  order?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Firm_Relationship_Mutated = {
  __typename?: 'catalog_firm_relationship_mutated';
  data?: Maybe<Catalog_Firm_Relationship>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Invite_Status = {
  __typename?: 'catalog_invite_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Invite_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Invite_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Invite_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Invite_Status_Mutated = {
  __typename?: 'catalog_invite_status_mutated';
  data?: Maybe<Catalog_Invite_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Language = {
  __typename?: 'catalog_language';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Language_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Language_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Language_Filter>>>;
  code?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Language_Mutated = {
  __typename?: 'catalog_language_mutated';
  data?: Maybe<Catalog_Language>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_License_Status = {
  __typename?: 'catalog_license_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_License_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_License_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_License_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_License_Status_Mutated = {
  __typename?: 'catalog_license_status_mutated';
  data?: Maybe<Catalog_License_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Malpractice_Insurance = {
  __typename?: 'catalog_malpractice_insurance';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
};

export type Catalog_Malpractice_Insurance_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Malpractice_Insurance_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Malpractice_Insurance_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  order?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Malpractice_Insurance_Mutated = {
  __typename?: 'catalog_malpractice_insurance_mutated';
  data?: Maybe<Catalog_Malpractice_Insurance>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Membership_Type = {
  __typename?: 'catalog_membership_type';
  id: Scalars['ID']['output'];
  is_default?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Membership_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Membership_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Membership_Type_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  is_default?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Membership_Type_Mutated = {
  __typename?: 'catalog_membership_type_mutated';
  data?: Maybe<Catalog_Membership_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Oauth_Integration = {
  __typename?: 'catalog_oauth_integration';
  connect_url_envar: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Catalog_Oauth_Integration_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Oauth_Integration_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Oauth_Integration_Filter>>>;
  connect_url_envar?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Oauth_Integration_Mutated = {
  __typename?: 'catalog_oauth_integration_mutated';
  data?: Maybe<Catalog_Oauth_Integration>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Payment_Method = {
  __typename?: 'catalog_payment_method';
  id: Scalars['ID']['output'];
  is_default?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Payment_Method_Mutated = {
  __typename?: 'catalog_payment_method_mutated';
  data?: Maybe<Catalog_Payment_Method>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Payment_Status = {
  __typename?: 'catalog_payment_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Payment_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Payment_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Payment_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Payment_Status_Mutated = {
  __typename?: 'catalog_payment_status_mutated';
  data?: Maybe<Catalog_Payment_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Practice_Area = {
  __typename?: 'catalog_practice_area';
  id: Scalars['ID']['output'];
  is_default?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Array<Maybe<Organization_Waterfall_List_Pa>>>;
};


export type Catalog_Practice_AreaPractice_AreaArgs = {
  filter?: InputMaybe<Organization_Waterfall_List_Pa_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Catalog_Practice_Area_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Practice_Area_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Practice_Area_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  is_default?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  practice_area?: InputMaybe<Organization_Waterfall_List_Pa_Filter>;
};

export type Catalog_Practice_Area_Mutated = {
  __typename?: 'catalog_practice_area_mutated';
  data?: Maybe<Catalog_Practice_Area>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Process_Stage = {
  __typename?: 'catalog_process_stage';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sort_order?: Maybe<Scalars['Int']['output']>;
};

export type Catalog_Process_Stage_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Process_Stage_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Process_Stage_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  sort_order?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Process_Stage_Mutated = {
  __typename?: 'catalog_process_stage_mutated';
  data?: Maybe<Catalog_Process_Stage>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Role_Invite = {
  __typename?: 'catalog_role_invite';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Directus_Roles>;
};


export type Catalog_Role_InviteRole_IdArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Catalog_Role_Invite_Mutated = {
  __typename?: 'catalog_role_invite_mutated';
  data?: Maybe<Catalog_Role_Invite>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Sign_Request_Status = {
  __typename?: 'catalog_sign_request_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Sign_Request_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Sign_Request_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Sign_Request_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Sign_Request_Status_Mutated = {
  __typename?: 'catalog_sign_request_status_mutated';
  data?: Maybe<Catalog_Sign_Request_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Time_Unit = {
  __typename?: 'catalog_time_unit';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Time_Unit_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Time_Unit_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Time_Unit_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Time_Unit_Mutated = {
  __typename?: 'catalog_time_unit_mutated';
  data?: Maybe<Catalog_Time_Unit>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Us_State = {
  __typename?: 'catalog_us_state';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Us_State_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Us_State_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Us_State_Filter>>>;
  code?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Us_State_Mutated = {
  __typename?: 'catalog_us_state_mutated';
  data?: Maybe<Catalog_Us_State>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Waterfall_Duration = {
  __typename?: 'catalog_waterfall_duration';
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Waterfall_Duration_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Waterfall_Duration_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Waterfall_Duration_Filter>>>;
  duration?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Waterfall_Duration_Mutated = {
  __typename?: 'catalog_waterfall_duration_mutated';
  data?: Maybe<Catalog_Waterfall_Duration>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Waterfall_Status = {
  __typename?: 'catalog_waterfall_status';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Catalog_Waterfall_Status_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Waterfall_Status_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Waterfall_Status_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type Catalog_Waterfall_Status_Mutated = {
  __typename?: 'catalog_waterfall_status_mutated';
  data?: Maybe<Catalog_Waterfall_Status>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Catalog_Years_Practice = {
  __typename?: 'catalog_years_practice';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type Catalog_Years_Practice_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Catalog_Years_Practice_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Catalog_Years_Practice_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  order?: InputMaybe<Number_Filter_Operators>;
};

export type Catalog_Years_Practice_Mutated = {
  __typename?: 'catalog_years_practice_mutated';
  data?: Maybe<Catalog_Years_Practice>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Config_Lcms_Leaddocket = {
  __typename?: 'config_lcms_leaddocket';
  api_key: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instance_url: Scalars['String']['output'];
  intake_form_url: Scalars['String']['output'];
  organization_id?: Maybe<Organization>;
};


export type Config_Lcms_LeaddocketOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Config_Lcms_Leaddocket_Mutated = {
  __typename?: 'config_lcms_leaddocket_mutated';
  data?: Maybe<Config_Lcms_Leaddocket>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Count_Function_Filter_Operators = {
  count?: InputMaybe<Number_Filter_Operators>;
};

export type Count_Functions = {
  __typename?: 'count_functions';
  count?: Maybe<Scalars['Int']['output']>;
};

export type Create_Case_File_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  case_file_type?: InputMaybe<Scalars['String']['input']>;
  case_id?: InputMaybe<Create_Case_Input>;
  file_id?: InputMaybe<Create_Directus_Files_Input>;
};

export type Create_Case_Input = {
  attorney_id?: InputMaybe<Scalars['String']['input']>;
  case_alias?: InputMaybe<Scalars['String']['input']>;
  case_number?: InputMaybe<Scalars['String']['input']>;
  created_by_user_id?: InputMaybe<Scalars['String']['input']>;
  data_room_filename?: InputMaybe<Scalars['String']['input']>;
  data_room_filesize?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_formatted?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<Array<InputMaybe<Create_Case_File_Input>>>;
  financing_source?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  incident_date?: InputMaybe<Scalars['Date']['input']>;
  internal_case_number?: InputMaybe<Scalars['String']['input']>;
  jurisdiction?: InputMaybe<Scalars['String']['input']>;
  leads?: InputMaybe<Array<InputMaybe<Create_Case_Lead_Input>>>;
  mp_visibility?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerships?: InputMaybe<Array<InputMaybe<Create_Case_Ownership_Input>>>;
  preferred_language?: InputMaybe<Scalars['String']['input']>;
  process_stage?: InputMaybe<Scalars['String']['input']>;
  proposal_due_date?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Create_Case_Lead_Contact_Input = {
  case_lead_id?: InputMaybe<Create_Case_Lead_Input>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type Create_Case_Lead_Input = {
  case_id?: InputMaybe<Create_Case_Input>;
  contacts?: InputMaybe<Array<InputMaybe<Create_Case_Lead_Contact_Input>>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
};

export type Create_Case_Ownership_Input = {
  case_id?: InputMaybe<Create_Case_Input>;
  created_ts?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  user_id?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Directus_Dashboards_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['Date']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  panels?: InputMaybe<Array<InputMaybe<Create_Directus_Panels_Input>>>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  embed?: InputMaybe<Scalars['String']['input']>;
  filename_disk?: InputMaybe<Scalars['String']['input']>;
  filename_download: Scalars['String']['input'];
  filesize?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  focal_point_x?: InputMaybe<Scalars['Int']['input']>;
  focal_point_y?: InputMaybe<Scalars['Int']['input']>;
  folder?: InputMaybe<Create_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  modified_by?: InputMaybe<Create_Directus_Users_Input>;
  modified_on?: InputMaybe<Scalars['Date']['input']>;
  storage: Scalars['String']['input'];
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uploaded_by?: InputMaybe<Create_Directus_Users_Input>;
  uploaded_on?: InputMaybe<Scalars['Date']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Create_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Create_Directus_Folders_Input>;
};

export type Create_Directus_Panels_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  dashboard?: InputMaybe<Create_Directus_Dashboards_Input>;
  date_created?: InputMaybe<Scalars['Date']['input']>;
  height: Scalars['Int']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  position_x: Scalars['Int']['input'];
  position_y: Scalars['Int']['input'];
  show_header: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  width: Scalars['Int']['input'];
};

export type Create_Directus_Shares_Input = {
  collection: Scalars['String']['input'];
  date_created?: InputMaybe<Scalars['Date']['input']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_end?: InputMaybe<Scalars['Date']['input']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_start?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  item: Scalars['String']['input'];
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** $t:shared_leave_blank_for_passwordless_access */
  password?: InputMaybe<Scalars['Hash']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  times_used?: InputMaybe<Scalars['Int']['input']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Directus_Users_Input = {
  appearance?: InputMaybe<Scalars['String']['input']>;
  auth_data?: InputMaybe<Scalars['JSON']['input']>;
  avatar?: InputMaybe<Create_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_notifications?: InputMaybe<Scalars['Boolean']['input']>;
  external_identifier?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_access?: InputMaybe<Scalars['Date']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_page?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  own_cases?: InputMaybe<Array<InputMaybe<Create_Case_Ownership_Input>>>;
  password?: InputMaybe<Scalars['Hash']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  tfa_secret?: InputMaybe<Scalars['Hash']['input']>;
  theme_dark?: InputMaybe<Scalars['String']['input']>;
  theme_dark_overrides?: InputMaybe<Scalars['JSON']['input']>;
  theme_light?: InputMaybe<Scalars['String']['input']>;
  theme_light_overrides?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['Hash']['input']>;
};

export type Date_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Date_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Date_Functions = {
  __typename?: 'date_functions';
  day?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']['output']>;
  hour?: Maybe<Scalars['Int']['output']>;
  minute?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['Int']['output']>;
  second?: Maybe<Scalars['Int']['output']>;
  week?: Maybe<Scalars['Int']['output']>;
  weekday?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type Delete_Many = {
  __typename?: 'delete_many';
  ids: Array<Maybe<Scalars['ID']['output']>>;
};

export type Delete_One = {
  __typename?: 'delete_one';
  id: Scalars['ID']['output'];
};

export type Directus_Dashboards = {
  __typename?: 'directus_dashboards';
  color?: Maybe<Scalars['String']['output']>;
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  panels?: Maybe<Array<Maybe<Directus_Panels>>>;
  panels_func?: Maybe<Count_Functions>;
  user_created?: Maybe<Directus_Users>;
};


export type Directus_DashboardsPanelsArgs = {
  filter?: InputMaybe<Directus_Panels_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_DashboardsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Dashboards_Aggregated = {
  __typename?: 'directus_dashboards_aggregated';
  count?: Maybe<Directus_Dashboards_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Dashboards_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Directus_Dashboards_Aggregated_Count = {
  __typename?: 'directus_dashboards_aggregated_count';
  color?: Maybe<Scalars['Int']['output']>;
  date_created?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['Int']['output']>;
  panels?: Maybe<Scalars['Int']['output']>;
  user_created?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Dashboards_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Dashboards_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Dashboards_Filter>>>;
  color?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  note?: InputMaybe<String_Filter_Operators>;
  panels?: InputMaybe<Directus_Panels_Filter>;
  panels_func?: InputMaybe<Count_Function_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Directus_Dashboards_Mutated = {
  __typename?: 'directus_dashboards_mutated';
  data?: Maybe<Directus_Dashboards>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Files = {
  __typename?: 'directus_files';
  allowed_users?: Maybe<Array<Maybe<User_Allowed_File>>>;
  allowed_users_func?: Maybe<Count_Functions>;
  charset?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  embed?: Maybe<Scalars['String']['output']>;
  filename_disk?: Maybe<Scalars['String']['output']>;
  filename_download: Scalars['String']['output'];
  filesize?: Maybe<Scalars['GraphQLBigInt']['output']>;
  focal_point_x?: Maybe<Scalars['Int']['output']>;
  focal_point_y?: Maybe<Scalars['Int']['output']>;
  folder?: Maybe<Directus_Folders>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  metadata_func?: Maybe<Count_Functions>;
  modified_by?: Maybe<Directus_Users>;
  modified_on?: Maybe<Scalars['Date']['output']>;
  modified_on_func?: Maybe<Datetime_Functions>;
  storage: Scalars['String']['output'];
  tags?: Maybe<Scalars['JSON']['output']>;
  tags_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uploaded_by?: Maybe<Directus_Users>;
  uploaded_on?: Maybe<Scalars['Date']['output']>;
  uploaded_on_func?: Maybe<Datetime_Functions>;
  width?: Maybe<Scalars['Int']['output']>;
};


export type Directus_FilesAllowed_UsersArgs = {
  filter?: InputMaybe<User_Allowed_File_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FilesFolderArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FilesModified_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_FilesUploaded_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Files_Aggregated = {
  __typename?: 'directus_files_aggregated';
  avg?: Maybe<Directus_Files_Aggregated_Fields>;
  avgDistinct?: Maybe<Directus_Files_Aggregated_Fields>;
  count?: Maybe<Directus_Files_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Files_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Directus_Files_Aggregated_Fields>;
  min?: Maybe<Directus_Files_Aggregated_Fields>;
  sum?: Maybe<Directus_Files_Aggregated_Fields>;
  sumDistinct?: Maybe<Directus_Files_Aggregated_Fields>;
};

export type Directus_Files_Aggregated_Count = {
  __typename?: 'directus_files_aggregated_count';
  allowed_users?: Maybe<Scalars['Int']['output']>;
  charset?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  embed?: Maybe<Scalars['Int']['output']>;
  filename_disk?: Maybe<Scalars['Int']['output']>;
  filename_download?: Maybe<Scalars['Int']['output']>;
  filesize?: Maybe<Scalars['Int']['output']>;
  focal_point_x?: Maybe<Scalars['Int']['output']>;
  focal_point_y?: Maybe<Scalars['Int']['output']>;
  folder?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['Int']['output']>;
  modified_by?: Maybe<Scalars['Int']['output']>;
  modified_on?: Maybe<Scalars['Int']['output']>;
  storage?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  uploaded_by?: Maybe<Scalars['Int']['output']>;
  uploaded_on?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Files_Aggregated_Fields = {
  __typename?: 'directus_files_aggregated_fields';
  duration?: Maybe<Scalars['Float']['output']>;
  filesize?: Maybe<Scalars['Float']['output']>;
  focal_point_x?: Maybe<Scalars['Float']['output']>;
  focal_point_y?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Directus_Files_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  allowed_users?: InputMaybe<User_Allowed_File_Filter>;
  allowed_users_func?: InputMaybe<Count_Function_Filter_Operators>;
  charset?: InputMaybe<String_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  duration?: InputMaybe<Number_Filter_Operators>;
  embed?: InputMaybe<String_Filter_Operators>;
  filename_disk?: InputMaybe<String_Filter_Operators>;
  filename_download?: InputMaybe<String_Filter_Operators>;
  filesize?: InputMaybe<Big_Int_Filter_Operators>;
  focal_point_x?: InputMaybe<Number_Filter_Operators>;
  focal_point_y?: InputMaybe<Number_Filter_Operators>;
  folder?: InputMaybe<Directus_Folders_Filter>;
  height?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  metadata?: InputMaybe<String_Filter_Operators>;
  metadata_func?: InputMaybe<Count_Function_Filter_Operators>;
  modified_by?: InputMaybe<Directus_Users_Filter>;
  modified_on?: InputMaybe<Date_Filter_Operators>;
  modified_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  storage?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  uploaded_by?: InputMaybe<Directus_Users_Filter>;
  uploaded_on?: InputMaybe<Date_Filter_Operators>;
  uploaded_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Files_Mutated = {
  __typename?: 'directus_files_mutated';
  data?: Maybe<Directus_Files>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Flows = {
  __typename?: 'directus_flows';
  color?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  options_func?: Maybe<Count_Functions>;
  trigger?: Maybe<Scalars['String']['output']>;
};

export type Directus_Flows_Aggregated = {
  __typename?: 'directus_flows_aggregated';
  count?: Maybe<Directus_Flows_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Flows_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Directus_Flows_Aggregated_Count = {
  __typename?: 'directus_flows_aggregated_count';
  color?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Scalars['Int']['output']>;
  trigger?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Flows_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Flows_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Flows_Filter>>>;
  color?: InputMaybe<String_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  options?: InputMaybe<String_Filter_Operators>;
  options_func?: InputMaybe<Count_Function_Filter_Operators>;
  trigger?: InputMaybe<String_Filter_Operators>;
};

export type Directus_Flows_Mutated = {
  __typename?: 'directus_flows_mutated';
  data?: Maybe<Directus_Flows>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Folders = {
  __typename?: 'directus_folders';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Directus_Folders>;
};


export type Directus_FoldersParentArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Folders_Aggregated = {
  __typename?: 'directus_folders_aggregated';
  count?: Maybe<Directus_Folders_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Folders_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Directus_Folders_Aggregated_Count = {
  __typename?: 'directus_folders_aggregated_count';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Folders_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parent?: InputMaybe<Directus_Folders_Filter>;
};

export type Directus_Folders_Mutated = {
  __typename?: 'directus_folders_mutated';
  data?: Maybe<Directus_Folders>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Panels = {
  __typename?: 'directus_panels';
  color?: Maybe<Scalars['String']['output']>;
  dashboard?: Maybe<Directus_Dashboards>;
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  height: Scalars['Int']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  options_func?: Maybe<Count_Functions>;
  position_x: Scalars['Int']['output'];
  position_y: Scalars['Int']['output'];
  show_header: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  user_created?: Maybe<Directus_Users>;
  width: Scalars['Int']['output'];
};


export type Directus_PanelsDashboardArgs = {
  filter?: InputMaybe<Directus_Dashboards_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_PanelsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Panels_Aggregated = {
  __typename?: 'directus_panels_aggregated';
  avg?: Maybe<Directus_Panels_Aggregated_Fields>;
  avgDistinct?: Maybe<Directus_Panels_Aggregated_Fields>;
  count?: Maybe<Directus_Panels_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Panels_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Directus_Panels_Aggregated_Fields>;
  min?: Maybe<Directus_Panels_Aggregated_Fields>;
  sum?: Maybe<Directus_Panels_Aggregated_Fields>;
  sumDistinct?: Maybe<Directus_Panels_Aggregated_Fields>;
};

export type Directus_Panels_Aggregated_Count = {
  __typename?: 'directus_panels_aggregated_count';
  color?: Maybe<Scalars['Int']['output']>;
  dashboard?: Maybe<Scalars['Int']['output']>;
  date_created?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Scalars['Int']['output']>;
  position_x?: Maybe<Scalars['Int']['output']>;
  position_y?: Maybe<Scalars['Int']['output']>;
  show_header?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  user_created?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Panels_Aggregated_Fields = {
  __typename?: 'directus_panels_aggregated_fields';
  height?: Maybe<Scalars['Float']['output']>;
  position_x?: Maybe<Scalars['Float']['output']>;
  position_y?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Directus_Panels_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Panels_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Panels_Filter>>>;
  color?: InputMaybe<String_Filter_Operators>;
  dashboard?: InputMaybe<Directus_Dashboards_Filter>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  height?: InputMaybe<Number_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  note?: InputMaybe<String_Filter_Operators>;
  options?: InputMaybe<String_Filter_Operators>;
  options_func?: InputMaybe<Count_Function_Filter_Operators>;
  position_x?: InputMaybe<Number_Filter_Operators>;
  position_y?: InputMaybe<Number_Filter_Operators>;
  show_header?: InputMaybe<Boolean_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Panels_Mutated = {
  __typename?: 'directus_panels_mutated';
  data?: Maybe<Directus_Panels>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Roles = {
  __typename?: 'directus_roles';
  admin_access: Scalars['Boolean']['output'];
  app_access?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enforce_tfa: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ip_access?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  users?: Maybe<Array<Maybe<Directus_Users>>>;
  users_func?: Maybe<Count_Functions>;
};


export type Directus_RolesUsersArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Roles_Aggregated = {
  __typename?: 'directus_roles_aggregated';
  count?: Maybe<Directus_Roles_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Roles_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Directus_Roles_Aggregated_Count = {
  __typename?: 'directus_roles_aggregated_count';
  admin_access?: Maybe<Scalars['Int']['output']>;
  app_access?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  enforce_tfa?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ip_access?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Roles_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  admin_access?: InputMaybe<Boolean_Filter_Operators>;
  app_access?: InputMaybe<Boolean_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  enforce_tfa?: InputMaybe<Boolean_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  ip_access?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  users?: InputMaybe<Directus_Users_Filter>;
  users_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Directus_Roles_Mutated = {
  __typename?: 'directus_roles_mutated';
  data?: Maybe<Directus_Roles>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Shares = {
  __typename?: 'directus_shares';
  collection: Scalars['String']['output'];
  date_created?: Maybe<Scalars['Date']['output']>;
  date_created_func?: Maybe<Datetime_Functions>;
  /** $t:shared_leave_blank_for_unlimited */
  date_end?: Maybe<Scalars['Date']['output']>;
  date_end_func?: Maybe<Datetime_Functions>;
  /** $t:shared_leave_blank_for_unlimited */
  date_start?: Maybe<Scalars['Date']['output']>;
  date_start_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  item: Scalars['String']['output'];
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** $t:shared_leave_blank_for_passwordless_access */
  password?: Maybe<Scalars['Hash']['output']>;
  role?: Maybe<Directus_Roles>;
  times_used?: Maybe<Scalars['Int']['output']>;
  user_created?: Maybe<Directus_Users>;
};


export type Directus_SharesRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_SharesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Shares_Aggregated = {
  __typename?: 'directus_shares_aggregated';
  avg?: Maybe<Directus_Shares_Aggregated_Fields>;
  avgDistinct?: Maybe<Directus_Shares_Aggregated_Fields>;
  count?: Maybe<Directus_Shares_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Shares_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
  max?: Maybe<Directus_Shares_Aggregated_Fields>;
  min?: Maybe<Directus_Shares_Aggregated_Fields>;
  sum?: Maybe<Directus_Shares_Aggregated_Fields>;
  sumDistinct?: Maybe<Directus_Shares_Aggregated_Fields>;
};

export type Directus_Shares_Aggregated_Count = {
  __typename?: 'directus_shares_aggregated_count';
  collection?: Maybe<Scalars['Int']['output']>;
  date_created?: Maybe<Scalars['Int']['output']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_end?: Maybe<Scalars['Int']['output']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_start?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  item?: Maybe<Scalars['Int']['output']>;
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  /** $t:shared_leave_blank_for_passwordless_access */
  password?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['Int']['output']>;
  times_used?: Maybe<Scalars['Int']['output']>;
  user_created?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Shares_Aggregated_Fields = {
  __typename?: 'directus_shares_aggregated_fields';
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: Maybe<Scalars['Float']['output']>;
  times_used?: Maybe<Scalars['Float']['output']>;
};

export type Directus_Shares_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Shares_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Shares_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_end?: InputMaybe<Date_Filter_Operators>;
  date_end_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_start?: InputMaybe<Date_Filter_Operators>;
  date_start_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
  max_uses?: InputMaybe<Number_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  password?: InputMaybe<Hash_Filter_Operators>;
  role?: InputMaybe<Directus_Roles_Filter>;
  times_used?: InputMaybe<Number_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Directus_Shares_Mutated = {
  __typename?: 'directus_shares_mutated';
  data?: Maybe<Directus_Shares>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Directus_Users = {
  __typename?: 'directus_users';
  account_type?: Maybe<Array<Maybe<User_Account_Type>>>;
  account_type_func?: Maybe<Count_Functions>;
  allowed_files?: Maybe<Array<Maybe<User_Allowed_File>>>;
  allowed_files_func?: Maybe<Count_Functions>;
  appearance?: Maybe<Scalars['String']['output']>;
  attorneys?: Maybe<Array<Maybe<Attorney>>>;
  attorneys_func?: Maybe<Count_Functions>;
  auth_data?: Maybe<Scalars['JSON']['output']>;
  auth_data_func?: Maybe<Count_Functions>;
  avatar?: Maybe<Directus_Files>;
  created_organization?: Maybe<Array<Maybe<Organization>>>;
  created_organization_func?: Maybe<Count_Functions>;
  current_organization?: Maybe<Array<Maybe<Organization_User>>>;
  current_organization_func?: Maybe<Count_Functions>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_notifications?: Maybe<Scalars['Boolean']['output']>;
  external_identifier?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  last_access?: Maybe<Scalars['Date']['output']>;
  last_access_func?: Maybe<Datetime_Functions>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_page?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  own_cases?: Maybe<Array<Maybe<Case_Ownership>>>;
  own_cases_func?: Maybe<Count_Functions>;
  password?: Maybe<Scalars['Hash']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Directus_Roles>;
  status?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['JSON']['output']>;
  tags_func?: Maybe<Count_Functions>;
  tfa_secret?: Maybe<Scalars['Hash']['output']>;
  theme_dark?: Maybe<Scalars['String']['output']>;
  theme_dark_overrides?: Maybe<Scalars['JSON']['output']>;
  theme_dark_overrides_func?: Maybe<Count_Functions>;
  theme_light?: Maybe<Scalars['String']['output']>;
  theme_light_overrides?: Maybe<Scalars['JSON']['output']>;
  theme_light_overrides_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['Hash']['output']>;
};


export type Directus_UsersAccount_TypeArgs = {
  filter?: InputMaybe<User_Account_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersAllowed_FilesArgs = {
  filter?: InputMaybe<User_Allowed_File_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersAttorneysArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersAvatarArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersCreated_OrganizationArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersCurrent_OrganizationArgs = {
  filter?: InputMaybe<Organization_User_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersOwn_CasesArgs = {
  filter?: InputMaybe<Case_Ownership_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Directus_UsersRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Directus_Users_Aggregated = {
  __typename?: 'directus_users_aggregated';
  count?: Maybe<Directus_Users_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']['output']>;
  countDistinct?: Maybe<Directus_Users_Aggregated_Count>;
  group?: Maybe<Scalars['JSON']['output']>;
};

export type Directus_Users_Aggregated_Count = {
  __typename?: 'directus_users_aggregated_count';
  account_type?: Maybe<Scalars['Int']['output']>;
  allowed_files?: Maybe<Scalars['Int']['output']>;
  appearance?: Maybe<Scalars['Int']['output']>;
  attorneys?: Maybe<Scalars['Int']['output']>;
  auth_data?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['Int']['output']>;
  created_organization?: Maybe<Scalars['Int']['output']>;
  current_organization?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['Int']['output']>;
  email_notifications?: Maybe<Scalars['Int']['output']>;
  external_identifier?: Maybe<Scalars['Int']['output']>;
  first_name?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['Int']['output']>;
  last_access?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['Int']['output']>;
  last_page?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['Int']['output']>;
  own_cases?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['Int']['output']>;
  provider?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Scalars['Int']['output']>;
  tfa_secret?: Maybe<Scalars['Int']['output']>;
  theme_dark?: Maybe<Scalars['Int']['output']>;
  theme_dark_overrides?: Maybe<Scalars['Int']['output']>;
  theme_light?: Maybe<Scalars['Int']['output']>;
  theme_light_overrides?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['Int']['output']>;
};

export type Directus_Users_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  account_type?: InputMaybe<User_Account_Type_Filter>;
  account_type_func?: InputMaybe<Count_Function_Filter_Operators>;
  allowed_files?: InputMaybe<User_Allowed_File_Filter>;
  allowed_files_func?: InputMaybe<Count_Function_Filter_Operators>;
  appearance?: InputMaybe<String_Filter_Operators>;
  attorneys?: InputMaybe<Attorney_Filter>;
  attorneys_func?: InputMaybe<Count_Function_Filter_Operators>;
  auth_data?: InputMaybe<String_Filter_Operators>;
  auth_data_func?: InputMaybe<Count_Function_Filter_Operators>;
  avatar?: InputMaybe<Directus_Files_Filter>;
  created_organization?: InputMaybe<Organization_Filter>;
  created_organization_func?: InputMaybe<Count_Function_Filter_Operators>;
  current_organization?: InputMaybe<Organization_User_Filter>;
  current_organization_func?: InputMaybe<Count_Function_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  email?: InputMaybe<String_Filter_Operators>;
  email_notifications?: InputMaybe<Boolean_Filter_Operators>;
  external_identifier?: InputMaybe<String_Filter_Operators>;
  first_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  language?: InputMaybe<String_Filter_Operators>;
  last_access?: InputMaybe<Date_Filter_Operators>;
  last_access_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  last_name?: InputMaybe<String_Filter_Operators>;
  last_page?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  own_cases?: InputMaybe<Case_Ownership_Filter>;
  own_cases_func?: InputMaybe<Count_Function_Filter_Operators>;
  password?: InputMaybe<Hash_Filter_Operators>;
  provider?: InputMaybe<String_Filter_Operators>;
  role?: InputMaybe<Directus_Roles_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  tfa_secret?: InputMaybe<Hash_Filter_Operators>;
  theme_dark?: InputMaybe<String_Filter_Operators>;
  theme_dark_overrides?: InputMaybe<String_Filter_Operators>;
  theme_dark_overrides_func?: InputMaybe<Count_Function_Filter_Operators>;
  theme_light?: InputMaybe<String_Filter_Operators>;
  theme_light_overrides?: InputMaybe<String_Filter_Operators>;
  theme_light_overrides_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  token?: InputMaybe<Hash_Filter_Operators>;
};

export type Directus_Users_Mutated = {
  __typename?: 'directus_users_mutated';
  data?: Maybe<Directus_Users>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Fct_Affiliation_Members = {
  __typename?: 'fct_affiliation_members';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  affiliation?: Maybe<Scalars['String']['output']>;
  affiliation_id?: Maybe<Scalars['String']['output']>;
  attorney_id?: Maybe<Scalars['String']['output']>;
  bar_license_number?: Maybe<Scalars['String']['output']>;
  email_address?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  jurisdiction?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  member_id: Scalars['ID']['output'];
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  phone_number?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type Fct_Affiliation_Members_Mutated = {
  __typename?: 'fct_affiliation_members_mutated';
  data?: Maybe<Fct_Affiliation_Members>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export enum Graphql_Sdl_Scope {
  Items = 'items',
  System = 'system'
}

export type Hash_Filter_Operators = {
  _empty?: InputMaybe<Scalars['Boolean']['input']>;
  _nempty?: InputMaybe<Scalars['Boolean']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Lcms_Case_Mapping = {
  __typename?: 'lcms_case_mapping';
  case_id: Scalars['ID']['output'];
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  lcms_case_id?: Maybe<Scalars['String']['output']>;
  lcms_id?: Maybe<Case_Management_Software>;
  lcms_lead_id?: Maybe<Scalars['String']['output']>;
  lcms_opportunity_id?: Maybe<Scalars['String']['output']>;
  updated_ts?: Maybe<Scalars['String']['output']>;
  updated_ts_func?: Maybe<Time_Functions>;
};


export type Lcms_Case_MappingLcms_IdArgs = {
  filter?: InputMaybe<Case_Management_Software_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Lcms_Case_Mapping_Mutated = {
  __typename?: 'lcms_case_mapping_mutated';
  data?: Maybe<Lcms_Case_Mapping>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Master_Case = {
  __typename?: 'master_case';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  attorney_id?: Maybe<Scalars['String']['output']>;
  attorney_name?: Maybe<Scalars['String']['output']>;
  calendar_id?: Maybe<Scalars['String']['output']>;
  case_id: Scalars['ID']['output'];
  case_type?: Maybe<Scalars['String']['output']>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  description?: Maybe<Scalars['String']['output']>;
  desired_fee_perc?: Maybe<Scalars['Float']['output']>;
  financing_source?: Maybe<Scalars['String']['output']>;
  internal_case_number?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  jurisdiction?: Maybe<Scalars['String']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  name?: Maybe<Scalars['String']['output']>;
  num_negotiations?: Maybe<Scalars['Float']['output']>;
  num_watchers?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['String']['output']>;
  organization_name?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  preferred_language?: Maybe<Scalars['String']['output']>;
  process_stage?: Maybe<Scalars['String']['output']>;
  reporting_status?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_ts?: Maybe<Scalars['Date']['output']>;
  status_ts_func?: Maybe<Datetime_Functions>;
};

export type Master_Case_Mutated = {
  __typename?: 'master_case_mutated';
  data?: Maybe<Master_Case>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Master_Referral = {
  __typename?: 'master_referral';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  calendar_id?: Maybe<Scalars['String']['output']>;
  case_created_ts?: Maybe<Scalars['Date']['output']>;
  case_created_ts_func?: Maybe<Datetime_Functions>;
  case_id?: Maybe<Scalars['String']['output']>;
  case_name?: Maybe<Scalars['String']['output']>;
  case_status?: Maybe<Scalars['String']['output']>;
  case_type?: Maybe<Scalars['String']['output']>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  days_until_match_fee_collection?: Maybe<Scalars['Float']['output']>;
  fee_percentage?: Maybe<Scalars['Float']['output']>;
  jurisdiction?: Maybe<Scalars['String']['output']>;
  match_date?: Maybe<Scalars['Date']['output']>;
  match_date_func?: Maybe<Datetime_Functions>;
  match_fee_amount_paid?: Maybe<Scalars['Float']['output']>;
  match_fee_expected_amount?: Maybe<Scalars['Float']['output']>;
  match_fee_expected_collection_date?: Maybe<Scalars['Date']['output']>;
  match_fee_expected_collection_date_func?: Maybe<Date_Functions>;
  match_fee_paid_on?: Maybe<Scalars['Date']['output']>;
  match_fee_paid_on_func?: Maybe<Datetime_Functions>;
  match_fee_payment_method?: Maybe<Scalars['String']['output']>;
  match_fee_payment_status?: Maybe<Scalars['String']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  practice_area?: Maybe<Scalars['String']['output']>;
  prospect_attorney_id?: Maybe<Scalars['String']['output']>;
  prospect_attorney_name?: Maybe<Scalars['String']['output']>;
  prospect_membership_type?: Maybe<Scalars['String']['output']>;
  prospect_organization_id?: Maybe<Scalars['String']['output']>;
  prospect_organization_name?: Maybe<Scalars['String']['output']>;
  referral_id: Scalars['ID']['output'];
  referring_attorney_id?: Maybe<Scalars['String']['output']>;
  referring_attorney_name?: Maybe<Scalars['String']['output']>;
  referring_membership_type?: Maybe<Scalars['String']['output']>;
  referring_organization_id?: Maybe<Scalars['String']['output']>;
  referring_organization_name?: Maybe<Scalars['String']['output']>;
};

export type Master_Referral_Mutated = {
  __typename?: 'master_referral_mutated';
  data?: Maybe<Master_Referral>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Network_User_Invite = {
  __typename?: 'network_user_invite';
  completed_ts?: Maybe<Scalars['Date']['output']>;
  completed_ts_func?: Maybe<Datetime_Functions>;
  completed_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last_name: Scalars['String']['output'];
  send_ts?: Maybe<Scalars['Date']['output']>;
  send_ts_func?: Maybe<Datetime_Functions>;
  sender_user_id?: Maybe<Directus_Users>;
};


export type Network_User_InviteCompleted_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Network_User_InviteSender_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Network_User_Invite_Mutated = {
  __typename?: 'network_user_invite_mutated';
  data?: Maybe<Network_User_Invite>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Number_Filter_Operators = {
  _between?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _eq?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _gt?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _gte?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _lt?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _lte?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _nbetween?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _neq?: InputMaybe<Scalars['GraphQLStringOrFloat']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['GraphQLStringOrFloat']['input']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Organization = {
  __typename?: 'organization';
  allow_attorney_wf_list?: Maybe<Scalars['Boolean']['output']>;
  created_by_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  credits?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lcms_waterfall_default_duration?: Maybe<Catalog_Waterfall_Duration>;
  lcms_waterfall_mapping_enabled?: Maybe<Scalars['Boolean']['output']>;
  practice_attorney_mapping?: Maybe<Array<Maybe<Practice_Attorney_Mapping>>>;
  practice_attorney_mapping_func?: Maybe<Count_Functions>;
  profiles?: Maybe<Array<Maybe<Organization_Profile>>>;
  profiles_func?: Maybe<Count_Functions>;
  users?: Maybe<Array<Maybe<Organization_User>>>;
  users_func?: Maybe<Count_Functions>;
};


export type OrganizationCreated_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type OrganizationLcms_Waterfall_Default_DurationArgs = {
  filter?: InputMaybe<Catalog_Waterfall_Duration_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type OrganizationPractice_Attorney_MappingArgs = {
  filter?: InputMaybe<Practice_Attorney_Mapping_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type OrganizationProfilesArgs = {
  filter?: InputMaybe<Organization_Profile_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type OrganizationUsersArgs = {
  filter?: InputMaybe<Organization_User_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Block = {
  __typename?: 'organization_block';
  blocked_org_id?: Maybe<Organization>;
  blocking_org_id?: Maybe<Organization>;
  created_by_user_id?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
};


export type Organization_BlockBlocked_Org_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_BlockBlocking_Org_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_BlockCreated_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Block_Mutated = {
  __typename?: 'organization_block_mutated';
  data?: Maybe<Organization_Block>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Connected_Lcms = {
  __typename?: 'organization_connected_lcms';
  case_management_software_id?: Maybe<Case_Management_Software>;
  config_id?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Directus_Users>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  organization_id?: Maybe<Organization>;
};


export type Organization_Connected_LcmsCase_Management_Software_IdArgs = {
  filter?: InputMaybe<Case_Management_Software_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Connected_LcmsCreated_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Connected_LcmsOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Connected_Lcms_Mutated = {
  __typename?: 'organization_connected_lcms_mutated';
  data?: Maybe<Organization_Connected_Lcms>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_Filter>>>;
  allow_attorney_wf_list?: InputMaybe<Boolean_Filter_Operators>;
  created_by_user_id?: InputMaybe<Directus_Users_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  credits?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  lcms_waterfall_default_duration?: InputMaybe<Catalog_Waterfall_Duration_Filter>;
  lcms_waterfall_mapping_enabled?: InputMaybe<Boolean_Filter_Operators>;
  practice_attorney_mapping?: InputMaybe<Practice_Attorney_Mapping_Filter>;
  practice_attorney_mapping_func?: InputMaybe<Count_Function_Filter_Operators>;
  profiles?: InputMaybe<Organization_Profile_Filter>;
  profiles_func?: InputMaybe<Count_Function_Filter_Operators>;
  users?: InputMaybe<Organization_User_Filter>;
  users_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Organization_Mutated = {
  __typename?: 'organization_mutated';
  data?: Maybe<Organization>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Profile = {
  __typename?: 'organization_profile';
  address?: Maybe<Scalars['String']['output']>;
  case_management_software?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company_size?: Maybe<Scalars['String']['output']>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  description?: Maybe<Scalars['String']['output']>;
  /** Domain part from the E-mail of an Organization's initial user */
  email_domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Organization>;
  state?: Maybe<Scalars['String']['output']>;
  website_url?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


export type Organization_ProfileOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Profile_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_Profile_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_Profile_Filter>>>;
  address?: InputMaybe<String_Filter_Operators>;
  case_management_software?: InputMaybe<String_Filter_Operators>;
  city?: InputMaybe<String_Filter_Operators>;
  company_size?: InputMaybe<String_Filter_Operators>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  email_domain?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  organization_id?: InputMaybe<Organization_Filter>;
  state?: InputMaybe<String_Filter_Operators>;
  website_url?: InputMaybe<String_Filter_Operators>;
  zipcode?: InputMaybe<String_Filter_Operators>;
};

export type Organization_Profile_Mutated = {
  __typename?: 'organization_profile_mutated';
  data?: Maybe<Organization_Profile>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_User = {
  __typename?: 'organization_user';
  can_create_wf_lists?: Maybe<Scalars['Boolean']['output']>;
  delegated_organization_user_id?: Maybe<Organization_User>;
  id: Scalars['ID']['output'];
  organization_id?: Maybe<Organization>;
  position_bio?: Maybe<Scalars['String']['output']>;
  position_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Directus_Users>;
};


export type Organization_UserDelegated_Organization_User_IdArgs = {
  filter?: InputMaybe<Organization_User_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_UserOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_UserUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_User_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_User_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_User_Filter>>>;
  can_create_wf_lists?: InputMaybe<Boolean_Filter_Operators>;
  delegated_organization_user_id?: InputMaybe<Organization_User_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  organization_id?: InputMaybe<Organization_Filter>;
  position_bio?: InputMaybe<String_Filter_Operators>;
  position_name?: InputMaybe<String_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
};

export type Organization_User_Mutated = {
  __typename?: 'organization_user_mutated';
  data?: Maybe<Organization_User>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Waterfall_List = {
  __typename?: 'organization_waterfall_list';
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  is_visible: Scalars['Boolean']['output'];
  items?: Maybe<Array<Maybe<Organization_Waterfall_List_Item>>>;
  items_func?: Maybe<Count_Functions>;
  jurisdiction_id?: Maybe<Catalog_Us_State>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Organization>;
  practice_areas?: Maybe<Array<Maybe<Organization_Waterfall_List_Pa>>>;
  practice_areas_func?: Maybe<Count_Functions>;
};


export type Organization_Waterfall_ListItemsArgs = {
  filter?: InputMaybe<Organization_Waterfall_List_Item_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Waterfall_ListJurisdiction_IdArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Waterfall_ListOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Waterfall_ListPractice_AreasArgs = {
  filter?: InputMaybe<Organization_Waterfall_List_Pa_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Waterfall_List_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Filter>>>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  is_visible?: InputMaybe<Boolean_Filter_Operators>;
  items?: InputMaybe<Organization_Waterfall_List_Item_Filter>;
  items_func?: InputMaybe<Count_Function_Filter_Operators>;
  jurisdiction_id?: InputMaybe<Catalog_Us_State_Filter>;
  name?: InputMaybe<String_Filter_Operators>;
  organization_id?: InputMaybe<Organization_Filter>;
  practice_areas?: InputMaybe<Organization_Waterfall_List_Pa_Filter>;
  practice_areas_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Organization_Waterfall_List_Item = {
  __typename?: 'organization_waterfall_list_item';
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  list_order?: Maybe<Scalars['Int']['output']>;
  waterfall_list_id?: Maybe<Organization_Waterfall_List>;
};


export type Organization_Waterfall_List_ItemAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Waterfall_List_ItemWaterfall_List_IdArgs = {
  filter?: InputMaybe<Organization_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Waterfall_List_Item_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Item_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Item_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  list_order?: InputMaybe<Number_Filter_Operators>;
  waterfall_list_id?: InputMaybe<Organization_Waterfall_List_Filter>;
};

export type Organization_Waterfall_List_Item_Mutated = {
  __typename?: 'organization_waterfall_list_item_mutated';
  data?: Maybe<Organization_Waterfall_List_Item>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Waterfall_List_Mutated = {
  __typename?: 'organization_waterfall_list_mutated';
  data?: Maybe<Organization_Waterfall_List>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Organization_Waterfall_List_Pa = {
  __typename?: 'organization_waterfall_list_pa';
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  practice_area_id?: Maybe<Catalog_Practice_Area>;
  waterfall_list_id?: Maybe<Organization_Waterfall_List>;
};


export type Organization_Waterfall_List_PaPractice_Area_IdArgs = {
  filter?: InputMaybe<Catalog_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Organization_Waterfall_List_PaWaterfall_List_IdArgs = {
  filter?: InputMaybe<Organization_Waterfall_List_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Organization_Waterfall_List_Pa_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Pa_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Organization_Waterfall_List_Pa_Filter>>>;
  created_ts?: InputMaybe<Date_Filter_Operators>;
  created_ts_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  practice_area_id?: InputMaybe<Catalog_Practice_Area_Filter>;
  waterfall_list_id?: InputMaybe<Organization_Waterfall_List_Filter>;
};

export type Organization_Waterfall_List_Pa_Mutated = {
  __typename?: 'organization_waterfall_list_pa_mutated';
  data?: Maybe<Organization_Waterfall_List_Pa>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Practice_Attorney_Mapping = {
  __typename?: 'practice_attorney_mapping';
  attorney_id?: Maybe<Attorney>;
  id: Scalars['ID']['output'];
  jurisdiction_id?: Maybe<Catalog_Us_State>;
  organization_id?: Maybe<Organization>;
  practice_area_id?: Maybe<Catalog_Practice_Area>;
};


export type Practice_Attorney_MappingAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Practice_Attorney_MappingJurisdiction_IdArgs = {
  filter?: InputMaybe<Catalog_Us_State_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Practice_Attorney_MappingOrganization_IdArgs = {
  filter?: InputMaybe<Organization_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Practice_Attorney_MappingPractice_Area_IdArgs = {
  filter?: InputMaybe<Catalog_Practice_Area_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Practice_Attorney_Mapping_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Practice_Attorney_Mapping_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Practice_Attorney_Mapping_Filter>>>;
  attorney_id?: InputMaybe<Attorney_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  jurisdiction_id?: InputMaybe<Catalog_Us_State_Filter>;
  organization_id?: InputMaybe<Organization_Filter>;
  practice_area_id?: InputMaybe<Catalog_Practice_Area_Filter>;
};

export type Practice_Attorney_Mapping_Mutated = {
  __typename?: 'practice_attorney_mapping_mutated';
  data?: Maybe<Practice_Attorney_Mapping>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type School = {
  __typename?: 'school';
  id: Scalars['ID']['output'];
  is_law_school?: Maybe<Scalars['Boolean']['output']>;
  is_undergrad_school?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type School_Filter = {
  _and?: InputMaybe<Array<InputMaybe<School_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<School_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  is_law_school?: InputMaybe<Boolean_Filter_Operators>;
  is_undergrad_school?: InputMaybe<Boolean_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
};

export type School_Mutated = {
  __typename?: 'school_mutated';
  data?: Maybe<School>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Server_Info = {
  __typename?: 'server_info';
  project?: Maybe<Server_Info_Project>;
  queryLimit?: Maybe<Server_Info_Query_Limit>;
  rateLimit?: Maybe<Scalars['Boolean']['output']>;
  rateLimitGlobal?: Maybe<Scalars['Boolean']['output']>;
  websocket?: Maybe<Scalars['Boolean']['output']>;
};

export type Server_Info_Project = {
  __typename?: 'server_info_project';
  custom_css?: Maybe<Scalars['String']['output']>;
  default_language?: Maybe<Scalars['String']['output']>;
  project_color?: Maybe<Scalars['String']['output']>;
  project_descriptor?: Maybe<Scalars['String']['output']>;
  project_logo?: Maybe<Scalars['String']['output']>;
  project_name?: Maybe<Scalars['String']['output']>;
  public_background?: Maybe<Scalars['String']['output']>;
  public_foreground?: Maybe<Scalars['String']['output']>;
  public_note?: Maybe<Scalars['String']['output']>;
  public_registration?: Maybe<Scalars['Boolean']['output']>;
  public_registration_verify_email?: Maybe<Scalars['Boolean']['output']>;
};

export type Server_Info_Query_Limit = {
  __typename?: 'server_info_query_limit';
  default?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']['input']>;
  _empty?: InputMaybe<Scalars['Boolean']['input']>;
  _ends_with?: InputMaybe<Scalars['String']['input']>;
  _eq?: InputMaybe<Scalars['String']['input']>;
  _icontains?: InputMaybe<Scalars['String']['input']>;
  _iends_with?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _istarts_with?: InputMaybe<Scalars['String']['input']>;
  _ncontains?: InputMaybe<Scalars['String']['input']>;
  _nempty?: InputMaybe<Scalars['Boolean']['input']>;
  _nends_with?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _niends_with?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _nistarts_with?: InputMaybe<Scalars['String']['input']>;
  _nnull?: InputMaybe<Scalars['Boolean']['input']>;
  _nstarts_with?: InputMaybe<Scalars['String']['input']>;
  _null?: InputMaybe<Scalars['Boolean']['input']>;
  _starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Sum_Analytics_Attorney_Metrics = {
  __typename?: 'sum_analytics_attorney_metrics';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  attorney_id: Scalars['ID']['output'];
  avg_referring_fees?: Maybe<Scalars['Float']['output']>;
  invites_received_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_percentage?: Maybe<Scalars['Float']['output']>;
  matches_as_referee_count?: Maybe<Scalars['Float']['output']>;
  matches_as_referee_percentage?: Maybe<Scalars['Float']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  posted_cases_count?: Maybe<Scalars['Float']['output']>;
  proposals_sent_count?: Maybe<Scalars['Float']['output']>;
  signed_as_referee_count?: Maybe<Scalars['Float']['output']>;
  signed_as_referee_percentage?: Maybe<Scalars['Float']['output']>;
  signed_cases_count?: Maybe<Scalars['Float']['output']>;
  signed_cases_percentage?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Attorney_Metrics_Mutated = {
  __typename?: 'sum_analytics_attorney_metrics_mutated';
  data?: Maybe<Sum_Analytics_Attorney_Metrics>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Sum_Analytics_Org_Metrics = {
  __typename?: 'sum_analytics_org_metrics';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  avg_referring_fees?: Maybe<Scalars['Float']['output']>;
  invites_received_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_percentage?: Maybe<Scalars['Float']['output']>;
  matches_as_referee_count?: Maybe<Scalars['Float']['output']>;
  matches_as_referee_percentage?: Maybe<Scalars['Float']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  organization_id: Scalars['ID']['output'];
  posted_cases_count?: Maybe<Scalars['Float']['output']>;
  proposals_sent_count?: Maybe<Scalars['Float']['output']>;
  signed_as_referee_count?: Maybe<Scalars['Float']['output']>;
  signed_as_referee_percentage?: Maybe<Scalars['Float']['output']>;
  signed_cases_count?: Maybe<Scalars['Float']['output']>;
  signed_cases_percentage?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Org_Metrics_Mutated = {
  __typename?: 'sum_analytics_org_metrics_mutated';
  data?: Maybe<Sum_Analytics_Org_Metrics>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Sum_Analytics_Org_Practice_Areas_Buyer = {
  __typename?: 'sum_analytics_org_practice_areas_buyer';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  avg_referring_fees?: Maybe<Scalars['Float']['output']>;
  matched_cases_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_percentage?: Maybe<Scalars['Float']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  metric_id: Scalars['ID']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  signed_cases_count?: Maybe<Scalars['Float']['output']>;
  signed_cases_percentage?: Maybe<Scalars['Float']['output']>;
  signed_matched_ratio?: Maybe<Scalars['Float']['output']>;
  total_cases_count?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Org_Practice_Areas_Buyer_Mutated = {
  __typename?: 'sum_analytics_org_practice_areas_buyer_mutated';
  data?: Maybe<Sum_Analytics_Org_Practice_Areas_Buyer>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Sum_Analytics_Org_Practice_Areas_Seller = {
  __typename?: 'sum_analytics_org_practice_areas_seller';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  avg_referring_fees?: Maybe<Scalars['Float']['output']>;
  matched_cases_count?: Maybe<Scalars['Float']['output']>;
  matched_cases_percentage?: Maybe<Scalars['Float']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  metric_id: Scalars['ID']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  signed_cases_count?: Maybe<Scalars['Float']['output']>;
  signed_cases_percentage?: Maybe<Scalars['Float']['output']>;
  signed_matched_ratio?: Maybe<Scalars['Float']['output']>;
  total_cases_count?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Org_Practice_Areas_Seller_Mutated = {
  __typename?: 'sum_analytics_org_practice_areas_seller_mutated';
  data?: Maybe<Sum_Analytics_Org_Practice_Areas_Seller>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Sum_Analytics_Top_Attorneys = {
  __typename?: 'sum_analytics_top_attorneys';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  attorney_id?: Maybe<Scalars['String']['output']>;
  attorney_name?: Maybe<Scalars['String']['output']>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  metric_id: Scalars['ID']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  referee_count?: Maybe<Scalars['Float']['output']>;
  referring_count?: Maybe<Scalars['Float']['output']>;
  total_count?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Top_Attorneys_Mutated = {
  __typename?: 'sum_analytics_top_attorneys_mutated';
  data?: Maybe<Sum_Analytics_Top_Attorneys>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Sum_Analytics_Top_Practice_Areas = {
  __typename?: 'sum_analytics_top_practice_areas';
  _fivetran_deleted?: Maybe<Scalars['Boolean']['output']>;
  _fivetran_synced?: Maybe<Scalars['Date']['output']>;
  _fivetran_synced_func?: Maybe<Datetime_Functions>;
  meta_load_date?: Maybe<Scalars['Date']['output']>;
  meta_load_date_func?: Maybe<Datetime_Functions>;
  metric_id: Scalars['ID']['output'];
  organization_id?: Maybe<Scalars['String']['output']>;
  practice_area?: Maybe<Scalars['String']['output']>;
  referee_count?: Maybe<Scalars['Float']['output']>;
  referring_count?: Maybe<Scalars['Float']['output']>;
  total_count?: Maybe<Scalars['Float']['output']>;
};

export type Sum_Analytics_Top_Practice_Areas_Mutated = {
  __typename?: 'sum_analytics_top_practice_areas_mutated';
  data?: Maybe<Sum_Analytics_Top_Practice_Areas>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Time_Functions = {
  __typename?: 'time_functions';
  hour?: Maybe<Scalars['Int']['output']>;
  minute?: Maybe<Scalars['Int']['output']>;
  second?: Maybe<Scalars['Int']['output']>;
};

export type Update_Directus_Dashboards_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['Date']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  panels?: InputMaybe<Array<InputMaybe<Update_Directus_Panels_Input>>>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  embed?: InputMaybe<Scalars['String']['input']>;
  filename_disk?: InputMaybe<Scalars['String']['input']>;
  filename_download?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['GraphQLBigInt']['input']>;
  focal_point_x?: InputMaybe<Scalars['Int']['input']>;
  focal_point_y?: InputMaybe<Scalars['Int']['input']>;
  folder?: InputMaybe<Update_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  modified_by?: InputMaybe<Update_Directus_Users_Input>;
  modified_on?: InputMaybe<Scalars['Date']['input']>;
  storage?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uploaded_by?: InputMaybe<Update_Directus_Users_Input>;
  uploaded_on?: InputMaybe<Scalars['Date']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Update_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Update_Directus_Folders_Input>;
};

export type Update_Directus_Panels_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  dashboard?: InputMaybe<Update_Directus_Dashboards_Input>;
  date_created?: InputMaybe<Scalars['Date']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  position_x?: InputMaybe<Scalars['Int']['input']>;
  position_y?: InputMaybe<Scalars['Int']['input']>;
  show_header?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Update_Directus_Shares_Input = {
  collection?: InputMaybe<Scalars['String']['input']>;
  date_created?: InputMaybe<Scalars['Date']['input']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_end?: InputMaybe<Scalars['Date']['input']>;
  /** $t:shared_leave_blank_for_unlimited */
  date_start?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  item?: InputMaybe<Scalars['String']['input']>;
  /** $t:shared_leave_blank_for_unlimited */
  max_uses?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** $t:shared_leave_blank_for_passwordless_access */
  password?: InputMaybe<Scalars['Hash']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  times_used?: InputMaybe<Scalars['Int']['input']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Directus_Users_Input = {
  avatar?: InputMaybe<Update_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['Hash']['input']>;
  tfa_secret?: InputMaybe<Scalars['Hash']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User_Account_Type = {
  __typename?: 'user_account_type';
  account_type_id?: Maybe<Catalog_Account_Type>;
  id: Scalars['ID']['output'];
  user_id?: Maybe<Directus_Users>;
};


export type User_Account_TypeAccount_Type_IdArgs = {
  filter?: InputMaybe<Catalog_Account_Type_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_Account_TypeUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Account_Type_Filter = {
  _and?: InputMaybe<Array<InputMaybe<User_Account_Type_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<User_Account_Type_Filter>>>;
  account_type_id?: InputMaybe<Catalog_Account_Type_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
};

export type User_Account_Type_Mutated = {
  __typename?: 'user_account_type_mutated';
  data?: Maybe<User_Account_Type>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type User_Allowed_File = {
  __typename?: 'user_allowed_file';
  case_id?: Maybe<Case>;
  file_id?: Maybe<Directus_Files>;
  id: Scalars['ID']['output'];
  user_id?: Maybe<Directus_Users>;
};


export type User_Allowed_FileCase_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_Allowed_FileFile_IdArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_Allowed_FileUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Allowed_File_Filter = {
  _and?: InputMaybe<Array<InputMaybe<User_Allowed_File_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<User_Allowed_File_Filter>>>;
  case_id?: InputMaybe<Case_Filter>;
  file_id?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  user_id?: InputMaybe<Directus_Users_Filter>;
};

export type User_Allowed_File_Mutated = {
  __typename?: 'user_allowed_file_mutated';
  data?: Maybe<User_Allowed_File>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type User_Email_Validation = {
  __typename?: 'user_email_validation';
  email: Scalars['String']['output'];
  target_organization_id: Scalars['String']['output'];
  target_role_id?: Maybe<Directus_Roles>;
  user_id?: Maybe<Directus_Users>;
  validation_ts?: Maybe<Scalars['Date']['output']>;
  validation_ts_func?: Maybe<Datetime_Functions>;
};


export type User_Email_ValidationTarget_Role_IdArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_Email_ValidationUser_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_Email_Validation_Mutated = {
  __typename?: 'user_email_validation_mutated';
  data?: Maybe<User_Email_Validation>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type User_File_Access_Request = {
  __typename?: 'user_file_access_request';
  attorney_id?: Maybe<Attorney>;
  created_ts?: Maybe<Scalars['Date']['output']>;
  created_ts_func?: Maybe<Datetime_Functions>;
  id: Scalars['ID']['output'];
  is_granted?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  requested_by_user_id?: Maybe<Directus_Users>;
  requested_case_id?: Maybe<Case>;
  status?: Maybe<Catalog_Access_Request_Status>;
};


export type User_File_Access_RequestAttorney_IdArgs = {
  filter?: InputMaybe<Attorney_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_File_Access_RequestRequested_By_User_IdArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_File_Access_RequestRequested_Case_IdArgs = {
  filter?: InputMaybe<Case_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type User_File_Access_RequestStatusArgs = {
  filter?: InputMaybe<Catalog_Access_Request_Status_Filter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type User_File_Access_Request_Mutated = {
  __typename?: 'user_file_access_request_mutated';
  data?: Maybe<User_File_Access_Request>;
  event?: Maybe<EventEnum>;
  key: Scalars['ID']['output'];
};

export type Users_Me_Tfa_Generate_Data = {
  __typename?: 'users_me_tfa_generate_data';
  otpauth_url?: Maybe<Scalars['String']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
};


export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  auth_login(email: $email, password: $password) {
    access_token
    refresh_token
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut($refreshToken: String) {
  auth_logout(refresh_token: $refreshToken)
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const AuthRefreshDocument = gql`
    mutation AuthRefresh($refreshToken: String, $mode: auth_mode) {
  auth_refresh(refresh_token: $refreshToken, mode: $mode) {
    access_token
    refresh_token
    expires
  }
}
    `;
export type AuthRefreshMutationFn = Apollo.MutationFunction<AuthRefreshMutation, AuthRefreshMutationVariables>;

/**
 * __useAuthRefreshMutation__
 *
 * To run a mutation, you first call `useAuthRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshMutation, { data, loading, error }] = useAuthRefreshMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useAuthRefreshMutation(baseOptions?: Apollo.MutationHookOptions<AuthRefreshMutation, AuthRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthRefreshMutation, AuthRefreshMutationVariables>(AuthRefreshDocument, options);
      }
export type AuthRefreshMutationHookResult = ReturnType<typeof useAuthRefreshMutation>;
export type AuthRefreshMutationResult = Apollo.MutationResult<AuthRefreshMutation>;
export type AuthRefreshMutationOptions = Apollo.BaseMutationOptions<AuthRefreshMutation, AuthRefreshMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: create_directus_users_input!) {
  create_users_item(data: $data) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdatePwDocument = gql`
    mutation updatePW($data: update_directus_users_input) {
  update_users_me(data: $data) {
    id
    email
  }
}
    `;
export type UpdatePwMutationFn = Apollo.MutationFunction<UpdatePwMutation, UpdatePwMutationVariables>;

/**
 * __useUpdatePwMutation__
 *
 * To run a mutation, you first call `useUpdatePwMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePwMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePwMutation, { data, loading, error }] = useUpdatePwMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePwMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePwMutation, UpdatePwMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePwMutation, UpdatePwMutationVariables>(UpdatePwDocument, options);
      }
export type UpdatePwMutationHookResult = ReturnType<typeof useUpdatePwMutation>;
export type UpdatePwMutationResult = Apollo.MutationResult<UpdatePwMutation>;
export type UpdatePwMutationOptions = Apollo.BaseMutationOptions<UpdatePwMutation, UpdatePwMutationVariables>;
export const ResetPasswordRequestDocument = gql`
    mutation resetPasswordRequest($email: String!, $resetUrl: String) {
  auth_password_request(email: $email, reset_url: $resetUrl)
}
    `;
export type ResetPasswordRequestMutationFn = Apollo.MutationFunction<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      resetUrl: // value for 'resetUrl'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>(ResetPasswordRequestDocument, options);
      }
export type ResetPasswordRequestMutationHookResult = ReturnType<typeof useResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationResult = Apollo.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = Apollo.BaseMutationOptions<ResetPasswordRequestMutation, ResetPasswordRequestMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  auth_password_reset(token: $token, password: $password)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const DeleteCaseFileDocument = gql`
    mutation DeleteCaseFile($deleteFilesItemId: ID!) {
  delete_files_item(id: $deleteFilesItemId) {
    id
  }
}
    `;
export type DeleteCaseFileMutationFn = Apollo.MutationFunction<DeleteCaseFileMutation, DeleteCaseFileMutationVariables>;

/**
 * __useDeleteCaseFileMutation__
 *
 * To run a mutation, you first call `useDeleteCaseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCaseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseFileMutation, { data, loading, error }] = useDeleteCaseFileMutation({
 *   variables: {
 *      deleteFilesItemId: // value for 'deleteFilesItemId'
 *   },
 * });
 */
export function useDeleteCaseFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCaseFileMutation, DeleteCaseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCaseFileMutation, DeleteCaseFileMutationVariables>(DeleteCaseFileDocument, options);
      }
export type DeleteCaseFileMutationHookResult = ReturnType<typeof useDeleteCaseFileMutation>;
export type DeleteCaseFileMutationResult = Apollo.MutationResult<DeleteCaseFileMutation>;
export type DeleteCaseFileMutationOptions = Apollo.BaseMutationOptions<DeleteCaseFileMutation, DeleteCaseFileMutationVariables>;
export const UpdateUsersMeDocument = gql`
    mutation UpdateUsersMe($data: update_directus_users_input) {
  update_users_me(data: $data) {
    id
    avatar {
      id
    }
  }
}
    `;
export type UpdateUsersMeMutationFn = Apollo.MutationFunction<UpdateUsersMeMutation, UpdateUsersMeMutationVariables>;

/**
 * __useUpdateUsersMeMutation__
 *
 * To run a mutation, you first call `useUpdateUsersMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersMeMutation, { data, loading, error }] = useUpdateUsersMeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUsersMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersMeMutation, UpdateUsersMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersMeMutation, UpdateUsersMeMutationVariables>(UpdateUsersMeDocument, options);
      }
export type UpdateUsersMeMutationHookResult = ReturnType<typeof useUpdateUsersMeMutation>;
export type UpdateUsersMeMutationResult = Apollo.MutationResult<UpdateUsersMeMutation>;
export type UpdateUsersMeMutationOptions = Apollo.BaseMutationOptions<UpdateUsersMeMutation, UpdateUsersMeMutationVariables>;
export const UsersMeDocument = gql`
    query UsersMe {
  users_me {
    id
    email
    first_name
    last_name
    role {
      id
      name
    }
    attorneys {
      profiles {
        first_name
        last_name
      }
      stream_chat_keys {
        chat_token
      }
    }
    avatar {
      id
      filename_disk
      filename_download
      title
    }
  }
}
    `;

/**
 * __useUsersMeQuery__
 *
 * To run a query within a React component, call `useUsersMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersMeQuery(baseOptions?: Apollo.QueryHookOptions<UsersMeQuery, UsersMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersMeQuery, UsersMeQueryVariables>(UsersMeDocument, options);
      }
export function useUsersMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersMeQuery, UsersMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersMeQuery, UsersMeQueryVariables>(UsersMeDocument, options);
        }
export type UsersMeQueryHookResult = ReturnType<typeof useUsersMeQuery>;
export type UsersMeLazyQueryHookResult = ReturnType<typeof useUsersMeLazyQuery>;
export type UsersMeQueryResult = Apollo.QueryResult<UsersMeQuery, UsersMeQueryVariables>;
export function refetchUsersMeQuery(variables?: UsersMeQueryVariables) {
      return { query: UsersMeDocument, variables: variables }
    }
export const GetDelegatedUsersByCaseManagerDocument = gql`
    query getDelegatedUsersByCaseManager {
  users_me {
    role {
      id
    }
  }
}
    `;

/**
 * __useGetDelegatedUsersByCaseManagerQuery__
 *
 * To run a query within a React component, call `useGetDelegatedUsersByCaseManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDelegatedUsersByCaseManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDelegatedUsersByCaseManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDelegatedUsersByCaseManagerQuery(baseOptions?: Apollo.QueryHookOptions<GetDelegatedUsersByCaseManagerQuery, GetDelegatedUsersByCaseManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDelegatedUsersByCaseManagerQuery, GetDelegatedUsersByCaseManagerQueryVariables>(GetDelegatedUsersByCaseManagerDocument, options);
      }
export function useGetDelegatedUsersByCaseManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDelegatedUsersByCaseManagerQuery, GetDelegatedUsersByCaseManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDelegatedUsersByCaseManagerQuery, GetDelegatedUsersByCaseManagerQueryVariables>(GetDelegatedUsersByCaseManagerDocument, options);
        }
export type GetDelegatedUsersByCaseManagerQueryHookResult = ReturnType<typeof useGetDelegatedUsersByCaseManagerQuery>;
export type GetDelegatedUsersByCaseManagerLazyQueryHookResult = ReturnType<typeof useGetDelegatedUsersByCaseManagerLazyQuery>;
export type GetDelegatedUsersByCaseManagerQueryResult = Apollo.QueryResult<GetDelegatedUsersByCaseManagerQuery, GetDelegatedUsersByCaseManagerQueryVariables>;
export function refetchGetDelegatedUsersByCaseManagerQuery(variables?: GetDelegatedUsersByCaseManagerQueryVariables) {
      return { query: GetDelegatedUsersByCaseManagerDocument, variables: variables }
    }
export const UsersMeOrganizationDocument = gql`
    query UsersMeOrganization {
  users_me {
    id
    current_organization {
      id
      organization_id {
        id
        profiles {
          id
          case_management_software
        }
      }
    }
  }
}
    `;

/**
 * __useUsersMeOrganizationQuery__
 *
 * To run a query within a React component, call `useUsersMeOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersMeOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersMeOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersMeOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<UsersMeOrganizationQuery, UsersMeOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersMeOrganizationQuery, UsersMeOrganizationQueryVariables>(UsersMeOrganizationDocument, options);
      }
export function useUsersMeOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersMeOrganizationQuery, UsersMeOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersMeOrganizationQuery, UsersMeOrganizationQueryVariables>(UsersMeOrganizationDocument, options);
        }
export type UsersMeOrganizationQueryHookResult = ReturnType<typeof useUsersMeOrganizationQuery>;
export type UsersMeOrganizationLazyQueryHookResult = ReturnType<typeof useUsersMeOrganizationLazyQuery>;
export type UsersMeOrganizationQueryResult = Apollo.QueryResult<UsersMeOrganizationQuery, UsersMeOrganizationQueryVariables>;
export function refetchUsersMeOrganizationQuery(variables?: UsersMeOrganizationQueryVariables) {
      return { query: UsersMeOrganizationDocument, variables: variables }
    }
export const GetOrganizationIdDocument = gql`
    query getOrganizationId {
  users_me {
    current_organization {
      id
      organization_id {
        id
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationIdQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationIdQuery, GetOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationIdQuery, GetOrganizationIdQueryVariables>(GetOrganizationIdDocument, options);
      }
export function useGetOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationIdQuery, GetOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationIdQuery, GetOrganizationIdQueryVariables>(GetOrganizationIdDocument, options);
        }
export type GetOrganizationIdQueryHookResult = ReturnType<typeof useGetOrganizationIdQuery>;
export type GetOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationIdLazyQuery>;
export type GetOrganizationIdQueryResult = Apollo.QueryResult<GetOrganizationIdQuery, GetOrganizationIdQueryVariables>;
export function refetchGetOrganizationIdQuery(variables?: GetOrganizationIdQueryVariables) {
      return { query: GetOrganizationIdDocument, variables: variables }
    }
export const UserOrganizacionDocument = gql`
    query UserOrganizacion {
  users_me {
    id
    role {
      id
      name
    }
    attorneys {
      is_vetted
    }
    current_organization {
      id
      organization_id {
        id
      }
    }
    attorneys {
      id
      is_vetted
      membership_type {
        id
      }
    }
    account_type {
      user_id {
        account_type {
          id
          account_type_id {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUserOrganizacionQuery__
 *
 * To run a query within a React component, call `useUserOrganizacionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizacionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizacionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganizacionQuery(baseOptions?: Apollo.QueryHookOptions<UserOrganizacionQuery, UserOrganizacionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrganizacionQuery, UserOrganizacionQueryVariables>(UserOrganizacionDocument, options);
      }
export function useUserOrganizacionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrganizacionQuery, UserOrganizacionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrganizacionQuery, UserOrganizacionQueryVariables>(UserOrganizacionDocument, options);
        }
export type UserOrganizacionQueryHookResult = ReturnType<typeof useUserOrganizacionQuery>;
export type UserOrganizacionLazyQueryHookResult = ReturnType<typeof useUserOrganizacionLazyQuery>;
export type UserOrganizacionQueryResult = Apollo.QueryResult<UserOrganizacionQuery, UserOrganizacionQueryVariables>;
export function refetchUserOrganizacionQuery(variables?: UserOrganizacionQueryVariables) {
      return { query: UserOrganizacionDocument, variables: variables }
    }
export const GetUserAllowedFilesDocument = gql`
    query GetUserAllowedFiles {
  users_me {
    id
    allowed_files {
      file_id {
        id
        filename_download
      }
    }
  }
}
    `;

/**
 * __useGetUserAllowedFilesQuery__
 *
 * To run a query within a React component, call `useGetUserAllowedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAllowedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAllowedFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAllowedFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAllowedFilesQuery, GetUserAllowedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAllowedFilesQuery, GetUserAllowedFilesQueryVariables>(GetUserAllowedFilesDocument, options);
      }
export function useGetUserAllowedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAllowedFilesQuery, GetUserAllowedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAllowedFilesQuery, GetUserAllowedFilesQueryVariables>(GetUserAllowedFilesDocument, options);
        }
export type GetUserAllowedFilesQueryHookResult = ReturnType<typeof useGetUserAllowedFilesQuery>;
export type GetUserAllowedFilesLazyQueryHookResult = ReturnType<typeof useGetUserAllowedFilesLazyQuery>;
export type GetUserAllowedFilesQueryResult = Apollo.QueryResult<GetUserAllowedFilesQuery, GetUserAllowedFilesQueryVariables>;
export function refetchGetUserAllowedFilesQuery(variables?: GetUserAllowedFilesQueryVariables) {
      return { query: GetUserAllowedFilesDocument, variables: variables }
    }
export const GetUsersAvatarsDocument = gql`
    query getUsersAvatars($filter: directus_users_filter) {
  users(filter: $filter) {
    id
    avatar {
      id
      filename_disk
    }
    attorneys {
      profiles {
        attorney_id {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetUsersAvatarsQuery__
 *
 * To run a query within a React component, call `useGetUsersAvatarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAvatarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAvatarsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersAvatarsQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersAvatarsQuery, GetUsersAvatarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersAvatarsQuery, GetUsersAvatarsQueryVariables>(GetUsersAvatarsDocument, options);
      }
export function useGetUsersAvatarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersAvatarsQuery, GetUsersAvatarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersAvatarsQuery, GetUsersAvatarsQueryVariables>(GetUsersAvatarsDocument, options);
        }
export type GetUsersAvatarsQueryHookResult = ReturnType<typeof useGetUsersAvatarsQuery>;
export type GetUsersAvatarsLazyQueryHookResult = ReturnType<typeof useGetUsersAvatarsLazyQuery>;
export type GetUsersAvatarsQueryResult = Apollo.QueryResult<GetUsersAvatarsQuery, GetUsersAvatarsQueryVariables>;
export function refetchGetUsersAvatarsQuery(variables?: GetUsersAvatarsQueryVariables) {
      return { query: GetUsersAvatarsDocument, variables: variables }
    }
export const GetOrgWaterfallConfigDocument = gql`
    query GetOrgWaterfallConfig {
  users_me {
    current_organization {
      id
      can_create_wf_lists
      organization_id {
        id
      }
    }
  }
}
    `;

/**
 * __useGetOrgWaterfallConfigQuery__
 *
 * To run a query within a React component, call `useGetOrgWaterfallConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgWaterfallConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgWaterfallConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgWaterfallConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgWaterfallConfigQuery, GetOrgWaterfallConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgWaterfallConfigQuery, GetOrgWaterfallConfigQueryVariables>(GetOrgWaterfallConfigDocument, options);
      }
export function useGetOrgWaterfallConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgWaterfallConfigQuery, GetOrgWaterfallConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgWaterfallConfigQuery, GetOrgWaterfallConfigQueryVariables>(GetOrgWaterfallConfigDocument, options);
        }
export type GetOrgWaterfallConfigQueryHookResult = ReturnType<typeof useGetOrgWaterfallConfigQuery>;
export type GetOrgWaterfallConfigLazyQueryHookResult = ReturnType<typeof useGetOrgWaterfallConfigLazyQuery>;
export type GetOrgWaterfallConfigQueryResult = Apollo.QueryResult<GetOrgWaterfallConfigQuery, GetOrgWaterfallConfigQueryVariables>;
export function refetchGetOrgWaterfallConfigQuery(variables?: GetOrgWaterfallConfigQueryVariables) {
      return { query: GetOrgWaterfallConfigDocument, variables: variables }
    }
export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SignInMutation = { __typename?: 'Mutation', auth_login?: { __typename?: 'auth_tokens', access_token?: string | null, refresh_token?: string | null } | null };

export type SignOutMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SignOutMutation = { __typename?: 'Mutation', auth_logout?: boolean | null };

export type AuthRefreshMutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Auth_Mode>;
}>;


export type AuthRefreshMutation = { __typename?: 'Mutation', auth_refresh?: { __typename?: 'auth_tokens', access_token?: string | null, refresh_token?: string | null, expires?: any | null } | null };

export type CreateUserMutationVariables = Exact<{
  data: Create_Directus_Users_Input;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', create_users_item?: { __typename?: 'directus_users', id: string } | null };

export type UpdatePwMutationVariables = Exact<{
  data?: InputMaybe<Update_Directus_Users_Input>;
}>;


export type UpdatePwMutation = { __typename?: 'Mutation', update_users_me?: { __typename?: 'directus_users', id: string, email?: string | null } | null };

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars['String']['input'];
  resetUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResetPasswordRequestMutation = { __typename?: 'Mutation', auth_password_request?: boolean | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', auth_password_reset?: boolean | null };

export type DeleteCaseFileMutationVariables = Exact<{
  deleteFilesItemId: Scalars['ID']['input'];
}>;


export type DeleteCaseFileMutation = { __typename?: 'Mutation', delete_files_item?: { __typename?: 'delete_one', id: string } | null };

export type UpdateUsersMeMutationVariables = Exact<{
  data?: InputMaybe<Update_Directus_Users_Input>;
}>;


export type UpdateUsersMeMutation = { __typename?: 'Mutation', update_users_me?: { __typename?: 'directus_users', id: string, avatar?: { __typename?: 'directus_files', id: string } | null } | null };

export type UsersMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersMeQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', id: string, email?: string | null, first_name?: string | null, last_name?: string | null, role?: { __typename?: 'directus_roles', id: string, name: string } | null, attorneys?: Array<{ __typename?: 'attorney', profiles?: Array<{ __typename?: 'attorney_profile', first_name?: string | null, last_name?: string | null } | null> | null, stream_chat_keys?: Array<{ __typename?: 'attorney_chat_key', chat_token: string } | null> | null } | null> | null, avatar?: { __typename?: 'directus_files', id: string, filename_disk?: string | null, filename_download: string, title?: string | null } | null } | null };

export type GetDelegatedUsersByCaseManagerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDelegatedUsersByCaseManagerQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', role?: { __typename?: 'directus_roles', id: string } | null } | null };

export type UsersMeOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersMeOrganizationQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', id: string, current_organization?: Array<{ __typename?: 'organization_user', id: string, organization_id?: { __typename?: 'organization', id: string, profiles?: Array<{ __typename?: 'organization_profile', id: string, case_management_software?: string | null } | null> | null } | null } | null> | null } | null };

export type GetOrganizationIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationIdQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', current_organization?: Array<{ __typename?: 'organization_user', id: string, organization_id?: { __typename?: 'organization', id: string } | null } | null> | null } | null };

export type UserOrganizacionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserOrganizacionQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', id: string, role?: { __typename?: 'directus_roles', id: string, name: string } | null, attorneys?: Array<{ __typename?: 'attorney', is_vetted?: boolean | null, id: string, membership_type?: { __typename?: 'catalog_membership_type', id: string } | null } | null> | null, current_organization?: Array<{ __typename?: 'organization_user', id: string, organization_id?: { __typename?: 'organization', id: string } | null } | null> | null, account_type?: Array<{ __typename?: 'user_account_type', user_id?: { __typename?: 'directus_users', account_type?: Array<{ __typename?: 'user_account_type', id: string, account_type_id?: { __typename?: 'catalog_account_type', id: string, name?: string | null } | null } | null> | null } | null } | null> | null } | null };

export type GetUserAllowedFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAllowedFilesQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', id: string, allowed_files?: Array<{ __typename?: 'user_allowed_file', file_id?: { __typename?: 'directus_files', id: string, filename_download: string } | null } | null> | null } | null };

export type GetUsersAvatarsQueryVariables = Exact<{
  filter?: InputMaybe<Directus_Users_Filter>;
}>;


export type GetUsersAvatarsQuery = { __typename?: 'Query', users: Array<{ __typename?: 'directus_users', id: string, avatar?: { __typename?: 'directus_files', id: string, filename_disk?: string | null } | null, attorneys?: Array<{ __typename?: 'attorney', profiles?: Array<{ __typename?: 'attorney_profile', attorney_id?: { __typename?: 'attorney', id: string } | null } | null> | null } | null> | null }> };

export type GetOrgWaterfallConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgWaterfallConfigQuery = { __typename?: 'Query', users_me?: { __typename?: 'directus_users', current_organization?: Array<{ __typename?: 'organization_user', id: string, can_create_wf_lists?: boolean | null, organization_id?: { __typename?: 'organization', id: string } | null } | null> | null } | null };
