import {
  Update_Directus_Files_Input,
  useUpdateUsersMeMutation,
  useUsersMeQuery,
} from '@/gql/systemApi'
import { useRef, useState, useEffect } from 'react'
import { getImagePath } from '@/utils/helpers'

import { IMAGE_SIZES } from '@/constants'
import { useSnackBar } from '../useSnackBar'
import { useFileUpload } from '../useFileUpload'

const CROPPED_IMAGE_WIDTH = 180
const CROPPED_IMAGE_HEIGHT = 180

export const useProfilePicture = () => {
  const [shouldDeleteProfilePhoto, setShouldDeleteProfilePhoto] =
    useState(false)
  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const profilePhotoRef = useRef<HTMLInputElement | null>(null)
  const [uploadedImageData, setUploadedImageData] =
    useState<Update_Directus_Files_Input | null>(null)

  const { data: userMe } = useUsersMeQuery()
  const [updateUsersMeMutation] = useUpdateUsersMeMutation()
  const { showAlert } = useSnackBar()

  const { upload } = useFileUpload()

  const userAvatar = userMe?.users_me?.avatar
  const { id: userAvatarID } = userAvatar || {}
  const avatarFilePath = getImagePath({
    id: userAvatarID ?? null,
    size: IMAGE_SIZES.Large,
  })

  useEffect(() => {
    if (!avatarFilePath) {
      setProfilePhoto(null)
      return
    }
    setProfilePhoto(avatarFilePath)
  }, [avatarFilePath])

  const onDiscardChanges = () => {
    setUploadedImageData(null)
    setShouldDeleteProfilePhoto(false)
    if (!userAvatar) {
      setProfilePhoto(null)
      return
    }

    setProfilePhoto(avatarFilePath)
  }

  const onUpdateUserImage = async ({
    data,
    showSuccess = true,
  }: {
    data?: Update_Directus_Files_Input
    showSuccess?: boolean
  } = {}) => {
    try {
      const uploadImage = await updateUsersMeMutation({
        variables: {
          data: {
            avatar: data
              ? {
                  id: data.id,
                  filename_download: data.filename_download,
                  storage: data.storage,
                }
              : null,
          },
        },
      })

      if (uploadImage.data && showSuccess) {
        showAlert({ severity: 'success', message: 'Data saved successfully' })
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: 'Error saving image',
      })
    }
  }

  const onChangeProfilePhoto = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (e.target.files && e.target.files[0].size > 10485760) {
        e.preventDefault()
        throw new Error('File size should be less than 10MB')
      }

      if (!e.target.files) {
        throw new Error('No files to upload')
      }

      const file = e.target.files[0]
      const imageUrl = URL.createObjectURL(file)

      const image = new Image()
      image.src = imageUrl

      const imageContainer = document.createElement('image-container')
      imageContainer.appendChild(image)

      image.onload = async () => {
        const { default: Cropper } = await import('cropperjs')
        const cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 1,
          autoCropArea: 1,
          scalable: false,
          cropBoxResizable: false,
          ready: async () => {
            const canvas = cropper.getCroppedCanvas({
              width: CROPPED_IMAGE_WIDTH,
              height: CROPPED_IMAGE_HEIGHT,
            })

            canvas.toBlob(async blob => {
              if (!blob) throw new Error('Error converting canvas to blob')
              const resizedFile = new File([blob], file.name, {
                type: 'image/webp',
                lastModified: Date.now(),
              })

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              await upload(resizedFile, () => {}).then((res: any) => {
                setUploadedImageData(
                  res.data.data as Update_Directus_Files_Input
                )
              })
            }, 'image/webp')
          },
        })
      }

      setShouldDeleteProfilePhoto(false)
      setProfilePhoto(imageUrl)
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Error uploading image: ${(error as Error).message}`,
      })
    } finally {
      if (e.target && e.target.files) {
        e.target.value = ''
      }
    }
  }

  const onRemoveProfilePhoto = () => {
    setShouldDeleteProfilePhoto(true)
    setProfilePhoto(null)
    setUploadedImageData(null)
    if (profilePhotoRef.current) {
      profilePhotoRef.current.value = ''
    }
  }

  const onProfilePictureSave = async ({
    showSuccess = true,
  }: { showSuccess?: boolean } = {}) => {
    if (shouldDeleteProfilePhoto) {
      await onUpdateUserImage({ showSuccess })
    } else if (uploadedImageData) {
      await onUpdateUserImage({ data: uploadedImageData, showSuccess })
    } else {
      onDiscardChanges()
    }
  }

  return {
    profilePhoto,
    profilePhotoRef,
    onChangeProfilePhoto,
    onRemoveProfilePhoto,
    onDiscardChanges,
    showAlert,
    shouldDeleteProfilePhoto,
    uploadedImageData,
    onUpdateUserImage,
    setUploadedImageData,
    onProfilePictureSave,
  }
}
